import React from 'react';
import {
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Input,
    Button,
    Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CreatableSelect from 'react-select/lib/Creatable';

import $ from 'jquery';
import * as restAPI from '../../../../common/utilities/ApiService';
import LoginStore from '../../../../common/modules/Auth/LoginStore';


var interestsList = [
    { value: 1, label: "Baseball" },
    { value: 2, label: "Basketball" },
    { value: 3, label: "Collegiate Sports" },
    { value: 4, label: "Craft spirits/breweries" },
    { value: 5, label: "Fishing" },
    { value: 6, label: "Football" },
    { value: 7, label: "Golf" },
    { value: 8, label: "Hunting" },
    { value: 9, label: "Professional Sports" },
    { value: 10, label: "Shooting/Skeet" },
    { value: 11, label: "Soccer" },
    { value: 12, label: "Tennis" },
    { value: 13, label: "Volunteering" },
    { value: 14, label: "Other (static other)" },
];

class Edit extends React.Component {
    constructor(req) {
        super();

        this.user_id = LoginStore.user.user_id;

        this.state = {
            isLoaded: false,
            user: {
                name: null,
                email: null,
                phone: null,
                current_org_id: null,
                org_name: null,
                sysadmin: null,
                last_login_ts: null,
                location_state: null,
                location_city: null,
                profile_interests: [],
                profile_age: null,
                profile_university: null,
                asset: null,
                job_roles: null
            },
            states: [],
            roles: [],
            interests: [],
            universities: [],
            ages: [],
            selectedInterests: [],
            selected_job_role: null,
            modal: false,
            password: null,
            password_confirm: null
        }

        this.handleHeaderInputChange = this.handleHeaderInputChange.bind(this);
        this.handleProfileRoleChange = this.handleProfileRoleChange.bind(this);
        this.handleProfileStateChange = this.handleProfileStateChange.bind(this);
        this.handleProfileAgeChange = this.handleProfileAgeChange.bind(this);
        this.handleProfileInterestsChange = this.handleProfileInterestsChange.bind(this);
        this.handleProfileUniversityChange = this.handleProfileUniversityChange.bind(this);
        this.togglePasswordDialog = this.togglePasswordDialog.bind(this);
        this.handlePasswordInputChange = this.handlePasswordInputChange.bind(this);
        this.submitPasswordChange = this.submitPasswordChange.bind(this);
    }

    handleHeaderInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        var user = this.state.user;

        user[name] = value;

        this.setState({
            user: user
        });
    }

    handleProfileStateChange(event) {
        const target = event.target;
        var user = this.state.user;

        user.location_state = target.value;

        this.setState({
            user: user
        });
    }

    handleProfileRoleChange(role) {
        var user = this.state.user;

        user.job_roles = role.value;

        this.setState({
            user: user,
            selected_job_role: role
        });
    }

    handleProfileAgeChange(event) {
        const target = event.target;
        var user = this.state.user;

        user.profile_age = target.value;

        this.setState({
            user: user
        });
    }

    handleProfileUniversityChange(event) {
        const target = event.target;
        var user = this.state.user;

        user.profile_university = target.value;

        this.setState({
            user: user
        });
    }

    handleProfileInterestsChange(selectedArray) {
        var user = this.state.user;

        var profile_interests = [];
        selectedArray.forEach((v) => {
            profile_interests.push(v.value);
        });

        user.profile_interests = profile_interests;

        this.setState({
            user: user,
            selectedInterests: selectedArray
        });
    }

    async componentDidMount() {
        await restAPI.get('/api/1/users/me').then(res => {

            if (res.user.profile_interests) {
                res.user.profile_interests.forEach((i) => {
                    this.state.selectedInterests.push({
                        key: i,
                        label: i,
                        value: i
                    });
                });
            }

            var selected_job_role = null;

            if (res.user.job_roles) {
                selected_job_role = {
                    key: res.user.job_roles,
                    label: res.user.job_roles,
                    value: res.user.job_roles
                }
            }

            this.setState({
                user: res.user,
                isLoaded: true,
                selected_job_role: selected_job_role
            });
        });

        await restAPI.get('/api/1/lookups/universities').then(res => {
            this.setState({
                universities: res.universities,
            });
        });

        let tmpStates = {
            "AL": "Alabama",
            "AK": "Alaska",
            "AS": "American Samoa",
            "AZ": "Arizona",
            "AR": "Arkansas",
            "CA": "California",
            "CO": "Colorado",
            "CT": "Connecticut",
            "DE": "Delaware",
            "DC": "District Of Columbia",
            "FM": "Federated States Of Micronesia",
            "FL": "Florida",
            "GA": "Georgia",
            "GU": "Guam",
            "HI": "Hawaii",
            "ID": "Idaho",
            "IL": "Illinois",
            "IN": "Indiana",
            "IA": "Iowa",
            "KS": "Kansas",
            "KY": "Kentucky",
            "LA": "Louisiana",
            "ME": "Maine",
            "MH": "Marshall Islands",
            "MD": "Maryland",
            "MA": "Massachusetts",
            "MI": "Michigan",
            "MN": "Minnesota",
            "MS": "Mississippi",
            "MO": "Missouri",
            "MT": "Montana",
            "NE": "Nebraska",
            "NV": "Nevada",
            "NH": "New Hampshire",
            "NJ": "New Jersey",
            "NM": "New Mexico",
            "NY": "New York",
            "NC": "North Carolina",
            "ND": "North Dakota",
            "MP": "Northern Mariana Islands",
            "OH": "Ohio",
            "OK": "Oklahoma",
            "OR": "Oregon",
            "PW": "Palau",
            "PA": "Pennsylvania",
            "PR": "Puerto Rico",
            "RI": "Rhode Island",
            "SC": "South Carolina",
            "SD": "South Dakota",
            "TN": "Tennessee",
            "TX": "Texas",
            "UT": "Utah",
            "VT": "Vermont",
            "VI": "Virgin Islands",
            "VA": "Virginia",
            "WA": "Washington",
            "WV": "West Virginia",
            "WI": "Wisconsin",
            "WY": "Wyoming"
        }

        var states = [];

        Object.entries(tmpStates).forEach(([key, value]) => {
            states.push({ value: key, label: value })
        })

        let roles = [
            { value: 4, label: 'Completions' },
            { value: 3, label: 'Management' },
            { value: 5, label: 'Production' },
            { value: 2, label: 'Reservoir' },
            { value: 1, label: 'Technology' }
        ];

        var ageList = [
            { value: 25, label: "Under 25" },
            { value: 30, label: "25 - 30" },
            { value: 35, label: "30 - 35" },
            { value: 40, label: "35 - 40" },
            { value: 45, label: "40 - 45" },
            { value: 50, label: "45 - 50" },
            { value: 55, label: "Over 55" },
        ]


        this.setState({ states: states, roles: roles, interests: interestsList, ages: ageList });

        /*
        await restAPI.get('/api/1/lookups/interests').then(res => {
            this.setState({
                interests: res.interests,
            });
        });
        */
    }

    notifySuccess = () => toast("Changes saved", {
        type: "success",
        position: 'top-center'
    })

    handleFormSubmit = e => {
        const data = {
            name: this.state.user.name,
            email: this.state.user.email,
            phone: this.state.user.phone,
            asset: this.state.user.asset,
            current_org_id: this.state.user.current_org_id,
            org_name: this.state.user.org_name,
            // sysadmin: this.state.user.sysadmin,
            // last_login_ts: this.state.user.last_login_ts,
            location_state: this.state.user.location_state,
            location_city: this.state.user.location_city,
            profile_interests: this.state.user.profile_interests,
            profile_age: this.state.user.profile_age,
            profile_university: this.state.user.profile_university,
            job_roles: this.state.user.job_roles
        }

        restAPI.post(`/api/1/users/me`, data).then(res => {
            this.notifySuccess();
        });

        e.preventDefault();
    }

    togglePasswordDialog() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    handlePasswordInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    submitPasswordChange(e) {
        e.preventDefault();

        if (!this.state.password || this.state.password.length < 12) {
            $(".validate-check-text").animate({
                opacity: 0.2,
            }, 500).animate({
                opacity: 1.0,
            }, 500);
        } else if (!this.state.password || this.state.password !== this.state.password_confirm) {
            $(".validate-check-text").animate({
                opacity: 0.2,
            }, 500).animate({
                opacity: 1.0,
            }, 500);
        } else {
            const data = {
                password: this.state.password,
                password_confirm: this.state.password_confirm,
            }

            restAPI.post(`/api/1/users/me/password`, data).then(res => {
                this.notifySuccess();

                this.setState({
                    password: null,
                    password_confirm: null,
                    modal: false
                });
            });
        }

    }

    render() {
        const renderEditUserForm = (
            <form className="form-horizontal" onSubmit={this.handleFormSubmit}>

                <h4>User Settings</h4>

                <FormGroup row>
                    <label className="col-xl-2 col-form-label">Name</label>
                    <div className="col-xl-10">
                        <Input type="text"
                            placeholder="Name"
                            name="name"
                            value={this.state.user.name ? this.state.user.name : ""}
                            onChange={this.handleHeaderInputChange} />
                    </div>
                </FormGroup>

                <FormGroup row>
                    <label className="col-xl-2 col-form-label">Email</label>
                    <div className="col-xl-10">
                        <Input type="text"
                            placeholder="Email"
                            name="email"
                            value={this.state.user.email ? this.state.user.email : ""}
                            onChange={this.handleHeaderInputChange} />
                    </div>
                </FormGroup>

                <FormGroup row>
                    <label className="col-xl-2 col-form-label">Phone</label>
                    <div className="col-xl-10">
                        <Input type="text"
                            placeholder="Phone"
                            name="phone"
                            value={this.state.user.phone ? this.state.user.phone : ""}
                            onChange={this.handleHeaderInputChange} />
                    </div>
                </FormGroup>

                <FormGroup row>
                    <label className="col-xl-2 col-form-label"></label>
                    <div className="col-xl-10">
                        <Button onClick={this.togglePasswordDialog} className="btn btn-sm btn-secondary">Change Password</Button>
                    </div>
                </FormGroup>


                <hr />
                <h4>Location Information</h4>

                <FormGroup row>
                    <label className="col-xl-2 col-form-label">State</label>
                    <div className="col-xl-10">
                        <select value={this.state.user.location_state ? this.state.user.location_state : ""}
                            onChange={this.handleProfileStateChange}
                            className="form-control"
                            placeholder="Select an option" >
                            <option key="">Select a state...</option>
                            {this.state.states.map((state) => <option key={state.value} value={state.value}>{state.label}</option>)}
                        </select>
                    </div>
                </FormGroup>

                <FormGroup row>
                    <label className="col-xl-2 col-form-label">City</label>
                    <div className="col-xl-10">
                        <Input type="text"
                            placeholder="City"
                            name="location_city"
                            value={this.state.user.location_city ? this.state.user.location_city : ""}
                            onChange={this.handleHeaderInputChange} />
                    </div>
                </FormGroup>

                <hr />
                <h4>Profile Information</h4>


                <FormGroup row>
                    <label className="col-xl-2 col-form-label">Role</label>
                    <div className="col-xl-10">
                        <CreatableSelect
                            className="form-control form-select-element"
                            placeholder="Select an option"
                            value={this.state.selected_job_role}
                            onChange={this.handleProfileRoleChange}
                            options={this.state.roles.map(role => ({
                                key: role.label,
                                label: role.label,
                                value: role.label
                            }))} />

                        <small id="passwordHelpBlock" className="form-text text-muted">
                            To add an "Other" role start typing and click "Create"
                        </small>
                    </div>
                </FormGroup>


                <FormGroup row>
                    <label className="col-xl-2 col-form-label">University</label>
                    <div className="col-xl-10">
                        <select
                            className="form-control"
                            placeholder="Select an option"
                            value={this.state.user.profile_university ? this.state.user.profile_university : ""}
                            onChange={this.handleProfileUniversityChange}>
                            <option key="">Select an option...</option>
                            {this.state.universities.map((element) => <option key={element.id} value={element.id}>{element.name}</option>)}
                        </select>
                    </div>
                </FormGroup>

                <FormGroup row>
                    <label className="col-xl-2 col-form-label">Age</label>
                    <div className="col-xl-10">
                        <select
                            className="form-control"
                            placeholder="Select an option"
                            value={this.state.user.profile_age ? this.state.user.profile_age : ""}
                            onChange={this.handleProfileAgeChange}>
                            <option key="">Select an option...</option>
                            {this.state.ages.map((element) => <option key={element.value} value={element.value}>{element.label}</option>)}
                        </select>
                    </div>
                </FormGroup>

                <FormGroup row>
                    <label className="col-xl-2 col-form-label">Interests</label>
                    <div className="col-xl-10">
                        <CreatableSelect
                            isMulti
                            className="form-control form-select-element"
                            placeholder="Select an option"
                            value={this.state.selectedInterests}
                            options={this.state.interests.map(interest => ({
                                key: interest.label,
                                label: interest.label,
                                value: interest.label
                            }))}
                            onChange={this.handleProfileInterestsChange} />

                        <small id="passwordHelpBlock" className="form-text text-muted">
                            To add interests that are not on the list, start typing and select the "Create" option.
                        </small>
                    </div>
                </FormGroup>

                <FormGroup row>
                    <label className="col-xl-2 col-form-label">Asset</label>
                    <div className="col-xl-10">
                        <Input type="text"
                            placeholder="Asset"
                            name="asset"
                            value={this.state.user.asset ? this.state.user.asset : ""}
                            onChange={this.handleHeaderInputChange} />
                    </div>
                </FormGroup>


                <Row>
                    <Col xs="12">
                        <Button type="submit" color="primary">
                            <i className="fa fa-dot-circle-o" /> Submit
                        </Button>
                    </Col>
                </Row>

            </form>
        );

        var userEditForm;
        if (this.state.isLoaded && this.state.universities.length > 0 && this.state.states.length > 0) {
            userEditForm = renderEditUserForm;
        } else {
            userEditForm = <span>Loading...</span>
        }

        return (
            <>
                <Row style={{ paddingBottom: "250px" }} >


                    <Col lg={{ size: 8, offset: 2 }} md={{ size: 12 }}>
                        <Card className="card-default">
                            <CardBody>
                                {userEditForm}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <div>
                    <Modal isOpen={this.state.modal} toggle={this.togglePasswordDialog} className="">
                        <ModalHeader toggle={this.togglePasswordDialog}>Change Password</ModalHeader>

                        <ModalBody>
                            <form className="form-horizontal" onSubmit={this.onSubmitModal}>

                                <div>
                                    <ul>
                                        <li style={(!this.state.password || this.state.password.length < 12) ? { color: "red" } : { color: "green" }} className="validate-check-text">Your password must be at least 12 characters.</li>
                                        <li style={(!this.state.password || this.state.password !== this.state.password_confirm) ? { color: "red" } : { color: "green" }} className="validate-check-text">Password and confirmation must match.</li>
                                    </ul>
                                </div>

                                <FormGroup row>
                                    <label className="col-xl-2 col-form-label">Password</label>
                                    <div className="col-xl-10">
                                        <Input type="password"
                                            placeholder="Password"
                                            name="password"
                                            value={this.state.password ? this.state.password : ""}
                                            onChange={this.handlePasswordInputChange} />
                                    </div>
                                </FormGroup>

                                <FormGroup row>
                                    <label className="col-xl-2 col-form-label">Confirm</label>
                                    <div className="col-xl-10">
                                        <Input type="password"
                                            placeholder="Confirm Password"
                                            name="password_confirm"
                                            value={this.state.password_confirm ? this.state.password_confirm : ""}
                                            onChange={this.handlePasswordInputChange} />
                                    </div>
                                </FormGroup>

                                <button className="btn btn-block btn-primary mt-3" onClick={this.submitPasswordChange}>Submit</button>
                            </form>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={this.togglePasswordDialog}>Cancel</Button>
                        </ModalFooter>
                    </Modal>
                </div>
            </>
        )


    }
}

export default Edit;