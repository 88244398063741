
import { Menu, Item } from 'react-contexify'
import React from 'react'

export const WidgetContextMenu = ({ 
    widget_id, 
    canEdit,
    openWidgetDeletionModal, 
}) => {

    return <>
    <Menu id={`rightClickMenu-${widget_id}`}>
        {widget_id && canEdit &&
            <Item onClick={openWidgetDeletionModal}>
                Delete Widget
            </Item>
        }
    </Menu>  
    </>
}