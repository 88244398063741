import React, { Component } from "react";
import { Redirect } from 'react-router-dom'

import {
    FormGroup,
    Input,
} from 'reactstrap';
import LoginStore from '../../modules/Auth/LoginStore';

import * as restAPI from '../../utilities/ApiService';

class ForgotPassword extends Component {
    
    constructor(props, context) {
        super(props, context);

        this.state = {
            redirectToDashboard: false,
            redirectToLogin: false,
            email: null,
        }


        if (LoginStore.isLoggedIn()) {
            this.state = {
                redirectToDashboard: true
            }
        }
        
        this.resetPassword = this.resetPassword.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    
    componentDidMount() {
    }

    resetPassword(e) {
        e.preventDefault();
        console.log(e);
        restAPI.post('/api/1/auth/forgotPassword', {
            email: this.state.email
        }).then(res => { 
            console.log(res);
            this.setState({
                redirectToLogin: true
            })
        }).catch(err => {
            console.log(err);
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        
        this.setState({
            [name]: value
        });   
    }

    render() {
        if (this.state.redirectToDashboard) {
            return <Redirect to='/login' />
        } else if (this.state.redirectToLogin) {
            return (
                <div className="block-center mt-4 wd-fixed-user-form">
                    <div className="card card-flat">
                        <div className="card-header text-center bg-dark">
                            <a href="/">
                                <img className="block-center rounded login-logo" src="img/logo.png" alt="Logo" />
                            </a>
                        </div>

                        <div className="card-body">
                            <p>A password reset link has been sent to your inbox.</p>
                        </div>
                    </div>
                </div>
            );
        } else {

            return (
                <div className="block-center mt-4 wd-fixed-user-form">
                    <div className="card card-flat">
                        <div className="card-header text-center bg-dark">
                            <a href="/">
                                <img className="block-center rounded login-logo" src="img/logo.png" alt="Logo" />
                            </a>
                        </div>

                        <div className="card-body">
                        
                            <form id="user-login" name="loginForm" noValidate className="mb-3 form-validate">
                                

                                <FormGroup className="form-group">
                                    <label>Email Address</label>
                                    <div className="input-group with-focus">
                                        <Input type="text" 
                                            name="email"
                                            value={this.state.email ? this.state.email : "" }
                                            onChange={this.handleInputChange} />

                                        <div className="input-group-append">
                                            <span className="input-group-text fa fa-user text-muted bg-transparent border-left-0"></span>
                                        </div>
                                    </div>
                                </FormGroup>
                            
                                <div className="clearfix">
                                    <div className="float-left mt-0">
                                        {/* <Link to="recover" className="text-muted">Forgot your password?</Link> */}
                                    </div>

                                </div>

                                <button className="btn btn-block btn-primary mt-3" onClick={this.resetPassword}>Reset Password</button>
                            </form>
                        </div>
                    </div>
                </div>
            
            );
        }
    }
}

export default ForgotPassword;
