import React from 'react';
import { FormGroup, Input } from 'reactstrap';
import Select from 'react-select'

const Label = ({ text = '[Label text here]', notAllowed = false }) => <label style={{ paddingLeft: '0', cursor: notAllowed ? 'not-allowed' : 'default' }}>{text}</label>

export const FormTextInput = ({ label, name, value, placeholder, onChange, type = 'text' }) => {
    return <FormGroup row style={{ margin: 0 }}>
        <Label text={label} />
        <Input
            type={type}
            value={value}
            name={name || ''}
            placeholder={placeholder}
            onChange={onChange
                ? onChange
                : () => console.log(`onChange for ${label || 'Labeless'} ' FormInput'`)}
        />
    </FormGroup>
}

export const FormNumberInput = ({ label, value, placeholder, onChange, name }) => {
    return <FormTextInput label={label} value={value} name={name || ''}
        placeholder={placeholder} onChange={onChange} type='number' />
}

export const FormTextAreaInput = ({ label, value, placeholder, onChange }) => {
    return <FormTextInput label={label} value={value} placeholder={placeholder} onChange={onChange} type='textarea' />
}

export const FormCheckboxInput = ({ label, value = false, onChange, disabled = false }) => {
    const inputStyle = {}
    if (disabled) inputStyle.background = 'lightgrey'

    return <FormGroup row style={{ margin: 0 }}>
        <Label text={label} notAllowed={disabled} />
        <Input
            type='checkbox'
            checked={value}
            style={inputStyle}
            disabled={disabled}
            onChange={onChange
                ? onChange
                : () => console.log(`onChange for ${label || 'Labeless'} ' FormCheckboxInput'`)}
        />
    </FormGroup>
}

export const FormSelectInput = ({ label, inline, options, value, placeholder, onChange, selectStyles }) => {
    return <FormGroup row>
        <Label text={label} style={{ display: inline ? 'inline' : 'block' }} />
        <Select
            options={options}
            value={value}
            placeholder={placeholder}
            onChange={(selectedItem) => onChange(selectedItem)}
            styles={selectStyles}
        />
    </FormGroup>
}