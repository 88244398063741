import * as d3 from "d3"
import * as restAPI from '../../legacy/utilities/axios-service'
import React, { useState, useEffect, useContext } from 'react'
import { doubleDomain } from './D3Elements/XAxis'
import { CardWithTopRightButton } from './Card'
import { LineGraph } from './D3Elements/LineGraph'
import { GraphConfigModal } from './GraphConfigModal'
import LoadingSpinner from '../Elements/LoadingSpinner'
import { DeletionModal } from './DeletionModal'
import { DashboardingFormContext } from '../../Contexts/DashboardingFormContext'
import { GraphLegendContents } from './D3Elements/GraphLegendContents'
import { IconButton } from "../Core/Shared"
import { LineGraphContextMenu } from "./D3Elements/LineGraphContextMenu"
//hidingLiveData 
import { LiveBadge } from "./LiveBadge"

const DEFAULT_GRAPH_HEIGHT = 330
const WIDTH_OF_Y_AXES = 320

export const GraphCard = ({
    dashboard_id,
    graph_id,
    canEdit,
    show,
    openConfigurationForm,
    showModal,
    setShowModal,
    updateLinkedGroup,
    updateGraph,
    tile,
    all_jobs,
    x_axis_units_of_measure,
    graphTileWidth,
    removeTile }) => {

    const { formState, selectedGraphId, linkedGroupSharedValues, setValueForLinkedGroup } = useContext(DashboardingFormContext)

    const [isLoading, setIsLoading] = useState(true);
    const [seriesData, setSeriesData] = useState([]);

    const [lastStreamed, setLastStreamed] = useState(null);
    const [firstStreamed, setFirstStreamed] = useState(null);
    const [lastFullStreamed, setLastFullStreamed] = useState(null);

    const [isDataStreamPaused, setIsDataStreamPaused] = useState(false)
    const [isGraphDeletionModalOpen, setIsGraphDeletionModalOpen] = useState(false)
    const [isResettingGraph, setIsResettingGraph] = useState(false)
    const [legendInformation, setLegendInformation] = useState(null)
    const [selectedTimestamp, setSelectedTimestamp] = useState(null)
    const [isMouseOverFrozen, setIsMouseOverFrozen] = useState(false)
    const [yAxesZoomValues, setYAxesZoomValues] = useState({})

    const { graph } = tile
    const calculateIfLiveStreamingIsOn = () => (formState.show_live_data && graph.show_live_data && !isDataStreamPaused)
    useEffect(() => setIsDataStreamingOn(calculateIfLiveStreamingIsOn())
        , [formState.show_live_data, graph.show_live_data, !isDataStreamPaused])
    const [isDataStreamingOn, setIsDataStreamingOn] = useState(calculateIfLiveStreamingIsOn())

    /*********************/
    // Graph Config State
    /*********************/
    //Desired x Display Domain (i.e. Data Range) for contained graph
    const [xDisplayDomain, setXDisplayDomain] = useState([])

    const awaitingSharedLinkedGroupSetup = !!(graph?.linked_group && !linkedGroupSharedValues?.[graph.linked_group - 1].length)
    const thisGraphsLinkedGroupValue = (graph?.linked_group && linkedGroupSharedValues?.[graph.linked_group - 1]) || []

    useEffect(() => {
        if (!awaitingSharedLinkedGroupSetup && xDisplayDomain.length) {
            loadGraphData()
            setThisGraphsLinkedGroupValue(xDisplayDomain)
        }
    }, [awaitingSharedLinkedGroupSetup, xDisplayDomain, graph.axes, graph.time_amount, graph.unit_of_time])

    //Calculate the intital x Domain
    useEffect(() => {
        if (!awaitingSharedLinkedGroupSetup) {
            setDefaultXDomain()
        }
    }, [awaitingSharedLinkedGroupSetup, graph.axes, graph.time_amount, graph.unit_of_time])

    const currentDomainMatchesLinkedGroup =
        thisGraphsLinkedGroupValue.length !== 2 ||
        xDisplayDomain.length !== 2 ||
        ((xDisplayDomain[0] === thisGraphsLinkedGroupValue[0]) &&
            (xDisplayDomain[1] === thisGraphsLinkedGroupValue[1]));

    useEffect(() => {
        if (!currentDomainMatchesLinkedGroup) {
            setXDisplayDomain(thisGraphsLinkedGroupValue)
            setIsDataStreamPaused(true)
        }
    }, [thisGraphsLinkedGroupValue])


    //Handle Resetting the Graph when new seriesData arrives by marking loading as finished
    //Also pushes the new domain up to the linked group
    useEffect(() => {
        if (isResettingGraph) {
            setIsResettingGraph(false)
            !currentDomainMatchesLinkedGroup && setThisGraphsLinkedGroupValue(d3.extent(seriesData.map(m => m.dt).flat(), d => d))
        }
    }, [seriesData])

    useEffect(() => {
        const recalculatedLastValidDataTime = getLastValidDatapoint(seriesData)
        if (recalculatedLastValidDataTime !== lastFullStreamed) {
            setLastFullStreamed(getLastValidDatapoint(seriesData))
        }
    }, [seriesData])

    //Update the legend to show the correct info for the currently moused-over point
    useEffect(() => {
        if (selectedTimestamp) {
            formatLegendInformation(selectedTimestamp);
        } else {
            formatLegendInformation(lastFullStreamed);
        }
    }, [selectedTimestamp, lastFullStreamed]);


    const setThisGraphsLinkedGroupValue = (graph?.linked_group && setValueForLinkedGroup)
        ? (newValue) => setValueForLinkedGroup(graph?.linked_group, newValue)
        : () => null //Unable to set shared value for linked group

    const getLastValidDatapoint = (seriesData) => {
        if (seriesData === null || seriesData === undefined || seriesData.length < 1) {
            return null;
        }
        // Find the first measure's datapoints array
        const firstMeasure = seriesData[0].graphData;
        if (firstMeasure === null || firstMeasure === undefined || firstMeasure.length < 1) {
            return null;
        }

        // Step backwards through the first measure's datapoints
        for (let i = firstMeasure.length - 1; i >= 0; i--) {
            // Check if the current timestamp has a non-null value
            for (let j = 0; j < seriesData.length; j++) {
                const datapoint = seriesData[j].graphData[i];
                if (datapoint !== undefined && datapoint?.y !== null) {
                    return datapoint.x
                }
            }
        }
        //if no data, return 
        return firstMeasure[firstMeasure.length - 1].x
    }

    const setDefaultXDomain = async () => {
        const measures = graph.axes.map(a => a.parameters.map(p => `${p.target_dataset_field}/${p.target_job_id}`)).flat()

        const lastStreamed = await Promise.all(measures.map(m => restAPI.get(`/api/1/dashboarding/widget/latest/${m}`)))
            .then((influxData) => Math.max(...influxData.map(d => d.influxData.tmstamp)))
        setLastStreamed(lastStreamed);

        const firstStreamed = await Promise.all(measures.map(m => restAPI.get(`/api/1/dashboarding/widget/first/${m}`)))
            .then((influxData) => Math.min(...influxData.map(d => d.influxData.tmstamp)))
        setFirstStreamed(firstStreamed)

        updateGraph({ ...graph, firstStreamed, lastStreamed }) //currently used 

        // set data fetch range
        const interval = graph.time_amount * graph.unit_of_time?.duration * 1000
        const defaultEndOfWindow = lastStreamed
        const defaultStartOfWindow = isNaN(interval) ? firstStreamed : defaultEndOfWindow - interval

        const newXDisplayDomain = thisGraphsLinkedGroupValue.length > 0
            ? thisGraphsLinkedGroupValue
            : [defaultStartOfWindow, defaultEndOfWindow]

        setXDisplayDomain(newXDisplayDomain);
    }

    //Reload seriesData for the graph.
    const loadGraphData = async () => {
        if (xDisplayDomain.length === 0) {
            //Short Circuit if this is called before the domain is calculated.
            return;
        }
        if (!isLoading) setIsLoading(true)

        setIsMouseOverFrozen(false)

        const params = { st: xDisplayDomain[0], et: xDisplayDomain[1], graphResolutionId: graph.graph_resolution_id }
        const parameterData = await Promise.all(graph.axes.map(async a => {
            if (a.parameters.length > 0) {
                return await Promise.all(a.parameters.map(async (p) => {
                    const measureData = await restAPI.get(`/api/1/dashboarding/${p.target_job_id}/${p.target_dataset_field}/graph_data`, params);
                    return { ...measureData, yAxisOrdinal: a.ordinal }
                }));
            }
        }))
        const formattedData = parameterData.flat().filter(e => e)
        setSeriesData(formattedData)
        setLastFullStreamed(getLastValidDatapoint(formattedData))
        setIsLoading(false)
    }

    const formatLegendInformation = (timestamp) => {
        // group presentations by tabId
        const groupedData = seriesData.reduce((r, sd) => {
            r[sd.dataset_name] = r[sd.dataset_name] || [];
            const { measure, graphData, yAxisOrdinal } = sd;
            const color = graph.axes.find(a => a.ordinal === yAxisOrdinal)?.color;
            const value = graphData.find(gd => gd.x === timestamp)?.y;
            r[sd.dataset_name].push({ measure, value, color });
            return r;
        }, Object.create(null));

        // sort dataset parameters
        for (let key in groupedData) {
            groupedData[key].sort((a, b) => a.measure.toLowerCase().localeCompare(b.measure.toLowerCase()))
        }
        setLegendInformation(groupedData);
    };

    const openConfigModal = () => {
        //event propagation prevents input change in configuration modal
        setIsDataStreamPaused(true)
        openConfigurationForm()
    }
    const onSubmitGraphConfig = (newGraphConfig) => {
        setIsDataStreamPaused(false)
        updateGraph(newGraphConfig)
    }
    const confirmGraphDeletion = () => {
        setIsGraphDeletionModalOpen(false)
        removeTile(tile)
    }
    const toggleIsDataStreamPaused = () => {
        setIsDataStreamPaused(!isDataStreamPaused)
    }

    const resetGraph = async () => {
        const lastSavedGraphInfo = await restAPI.get(`/api/1/dashboarding/graph/${graph_id}`)

        setIsResettingGraph(true)
        setYAxesZoomValues({})
        setThisGraphsLinkedGroupValue(lastSavedGraphInfo.xScaleDomain)
        updateGraph(lastSavedGraphInfo.graph)
    }

    const cardStyle = { width: graphTileWidth, height: '100%', position: 'relative', border: '1px solid lightgrey' }
    if (selectedGraphId === graph_id) {
        cardStyle.boxShadow = '0 0 0 3px #36609a'
        cardStyle.zIndex = '999'
    }


    const getRawXScale = () => {
        const defaultExtent = d3.extent(seriesData.map(sd => sd.dt).flat(), d => d)
        const xScale = d3.scaleTime()
            .range([0, graphWidth])
            .domain(defaultExtent)
        return xScale
    }
    //#region graph-level Time Travel Caret Row helpers
    const graphWidth = graphTileWidth - WIDTH_OF_Y_AXES// width of y axes
    const getCurrentXScaleInfo = () => {
        const xScale = getRawXScale()

        // get difference from current xscale domain
        const [start, end] = xScale.domain().map(e => +e)
        const difference = end - start

        return { start, end, difference }
    }
    const calculateNewDomainFromTimeTravelCaretClick = (direction) => {
        const { start, end, difference } = getCurrentXScaleInfo()

        //set the new 'beginning' and 'end' values, based on the direction of the move
        const newDomain = direction < 0
            ? [start - difference, start] //moving backwards in time
            : [end, end + difference] //moving forewards in time


        //if we were moving forward have stepped beyond the current 
        //time, we must adjust the window to not include 'future' times
        if (direction === 1 && newDomain[1] > Date.now()) {
            const amountOfTimeByWhichToCorrect = newDomain[1] - Date.now()
            newDomain[0] -= amountOfTimeByWhichToCorrect
            newDomain[1] -= amountOfTimeByWhichToCorrect
        }

        return newDomain
    }

    //Scroll the view window left or right by an amount equal to the current scale.
    const moveXScale = (direction) => {
        const newDomain = calculateNewDomainFromTimeTravelCaretClick(direction)

        setXDisplayDomain(newDomain)
    }
    const checkXScaleCheckDataBoundary = (direction) => {
        const { start, end, difference } = getCurrentXScaleInfo()
        return direction < 0
            ? (start - difference) >= firstStreamed
            : (end + difference) <= lastStreamed
    }
    const xStartIsAtOrBeforeFirstStreamed = checkXScaleCheckDataBoundary(-1)

    //const xEndIsAtOrAfterLastStreamed = checkXScaleCheckDataBoundary(1) //might have use for this again later

    const handleStepBackInTime = () => {
        //if stepped back in time, the graph would no longer be showing
        //current data and should have its data streaming disabled
        setIsDataStreamingOn(false)
        moveXScale(-1)
    }

    const handleStepForwardInTime = () => {
        //if stepped forward in time, the graph *might* be showing
        //current data and *might* should have its data re-enabled
        const currentTime = Date.now()
        const newDomain = calculateNewDomainFromTimeTravelCaretClick(1)
        const graphIsShowingCurrentTime = newDomain[0] <= currentTime && newDomain[1] >= currentTime

        moveXScale(1)
        if (graphIsShowingCurrentTime) {
            setIsDataStreamingOn(calculateIfLiveStreamingIsOn())
        }
    }
    //#endregion graph-level Time Travel Caret Row helpers

    //#region Context Menu helpers
    const xAxisZoomOut = () => {
        const xScale = getRawXScale()
        // double current xscale domain and get new data
        const newXScaleDomain = doubleDomain(xScale.domain(), +firstStreamed, +lastStreamed)
        setXDisplayDomain(newXScaleDomain)
    }
    const autoScale = () => {
        // reset zoom values so draw y axis uses min/max
        setYAxesZoomValues({})
        loadGraphData()
    }
    //#endregion Context Menu helpers

    return (isLoading || awaitingSharedLinkedGroupSetup)
        ? <LoadingSpinner />
        : <CardWithTopRightButton
            canEdit={canEdit} cardStyle={cardStyle} onContextMenu={show} onTopRightClick={openConfigModal} graph_id={graph_id}
            buttonIcon={'fa-edit'} updateLinkedGroup={updateLinkedGroup} graph={tile.graph}
        >
            {/* Edit Graph Modal */}
            <GraphConfigModal
                showModal={showModal} setShowModal={setShowModal}
                graph={tile.graph} graph_id={graph_id} all_jobs={all_jobs}
                x_axis_units_of_measure={x_axis_units_of_measure}
                onSubmitGraphConfig={onSubmitGraphConfig}
                groupViewBoxUpdate={setThisGraphsLinkedGroupValue}
            />
            {/* Delete Graph Modal */}
            <DeletionModal
                onSubmit={confirmGraphDeletion}
                isOpen={isGraphDeletionModalOpen}
                setIsOpen={setIsGraphDeletionModalOpen}
                entityDisplayIdentifier={tile?.graph?.graph_name || `Graph Id: ${tile?.graph?.id}`}
                entityDisplayType='graph'
            />
            {/* Line Graph */}
            {tile.graph.axes?.length > 0 &&
                <>
                    {/* hidingLivedata*/}
                    <LiveBadge
                        isLive={isDataStreamingOn}
                        containerStyle={isDataStreamingOn ? { top: '-2.5px' } : {}}
                    />
                    <TimeTravelCaretRow
                        onStepBack={handleStepBackInTime}
                        showStepBackCaret={xStartIsAtOrBeforeFirstStreamed}
                        onStepForward={handleStepForwardInTime}
                        showStepForwardCaret={!isDataStreamingOn}
                    >
                        {graph.graph_name && graph.graph_name.trim() !== '' ? graph.graph_name : `Graph: ${graph_id}`}
                    </TimeTravelCaretRow>

                    <LineGraph
                        graph_id={graph_id}
                        graph={graph}
                        seriesData={seriesData} setSeriesData={setSeriesData}
                        lastFullStreamed={lastFullStreamed}
                        isDataStreamingOn={isDataStreamingOn}
                        graphTileWidth={graphTileWidth}
                        graphHeight={DEFAULT_GRAPH_HEIGHT}
                        isMouseOverFrozen={isMouseOverFrozen} setIsMouseOverFrozen={setIsMouseOverFrozen}
                        yAxesZoomValues={yAxesZoomValues} setYAxesZoomValues={setYAxesZoomValues}
                        xDisplayDomain={xDisplayDomain} setXDisplayDomain={setXDisplayDomain}
                        selectedTimestamp={selectedTimestamp} setSelectedTimestamp={setSelectedTimestamp}
                        onStartBrush={() => setIsDataStreamPaused(true)}
                    />
                    <GraphLegendContents
                        doubleWide={tile?.size === 2}
                        legendInformation={legendInformation}
                        selectedTimestamp={selectedTimestamp || lastFullStreamed}
                    />
                    <LineGraphContextMenu
                        graph={graph}
                        graph_id={graph_id}
                        openConfigurationForm={openConfigurationForm}
                        updateGraph={updateGraph}
                        canEdit={canEdit}
                        isDataStreamPaused={isDataStreamPaused}
                        openGraphDeletionModal={() => setIsGraphDeletionModalOpen(true)}
                        toggleIsDataStreamPaused={toggleIsDataStreamPaused}
                        resetGraph={resetGraph}
                        xAxisZoomOut={xAxisZoomOut}
                        autoScale={autoScale}
                    />
                </>
            }
        </CardWithTopRightButton>
}

//#region graph-level Time Travel Caret Row
const DEFAULT_CARET_STYLE = { fontSize: '1em' }
const HIDDEN_CARET_STYLE = { ...DEFAULT_CARET_STYLE, visibility: 'hidden' }

const TimeTravelCaretRow = ({ children, onStepBack, onStepForward, displayTitle,
    showStepBackCaret, showStepForwardCaret }) => <div className='caretContainer'>
        <IconButton
            icon='fa-caret-left'
            style={showStepBackCaret ? DEFAULT_CARET_STYLE : HIDDEN_CARET_STYLE}
            onClick={(e) => {
                e.stopPropagation()
                onStepBack()
            }}
        />
        {children}
        <IconButton
            icon='fa-caret-right'
            style={showStepForwardCaret ? DEFAULT_CARET_STYLE : HIDDEN_CARET_STYLE}
            onClick={(e) => {
                e.stopPropagation()
                onStepForward()
            }}
        />
    </div>
