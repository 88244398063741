import React, { Component } from "react";

import Plot from 'react-plotly.js';

export default class GFunctionPlot extends Component {

    x1 = 1;
    y1 = 1;

    constructor(props) {
        super(props);
        
        this.state = {
            lineRev: 0
        };
        
        this.x1 = props.x1;
        this.y1 = props.y1;
    }

    handleDrag(e) {
        console.log(e);
    }

    render() {
        return (

            <Plot
                data={[
                    {
                        x: this.props.data["g_time"],
                        y: this.props.data["p"],
                        type: 'scatter',
                        mode: 'lines+points',
                        marker: { color: 'red' },
                        name: 'Pressure',
                        line: {
                           shape: 'spline'
                        }
                    },
                    {
                        x: [0, 0],
                        y: [0, Math.max(...this.props.data["p"]) * 2],
                        type: 'scatter',
                        mode: 'points',
                        marker: { color: 'black' }
                    },

                    {
                        x: this.props.data["g_time"],
                        y: this.props.data["dPdt"],
                        type: 'scatter',
                        mode: 'lines+points',
                        marker: { color: 'orange' },
                        name: 'dP',
                        yaxis: 'y2',
                        line: {
                            shape: 'spline'
                        }
                    },
                    {
                        x: this.props.data["g_time"],
                        y: this.props.data["dPdlogt"],
                        type: 'scatter',
                        mode: 'lines+points',
                        marker: { color: 'yellow' },
                        name: 'Semi Log',
                        yaxis: 'y3',
                        line: {
                            shape: 'spline'
                        }
                    },

                ]}
               
                layout={{
                    title: '',
                    paper_bgcolor: '#1b1c1e',
                    plot_bgcolor: '#212125',
                    xaxis: {
                        domain: [0, 0.95],
                        title: '',
                        rangemode: 'tozero',
                        gridcolor: '#3d3d44',
                        gridwidth: 1
                    },
                    yaxis: {
                        title: '',
                        fixedrange: true,
                        rangemode: 'tozero',
                        gridcolor: '#3d3d44',
                        gridwidth: 1,
                    },
                    yaxis2: {
                        overlaying: 'y',
                        side: 'right',
                        anchor: 'x',
                        title: '',
                        rangemode: 'tozero'
                    },
                    yaxis3: {
                        overlaying: 'y',
                        side: 'right',
                        anchor: 'free',
                        position: 0.98,
                        title: '',
                        rangemode: 'tozero',
                    },
                    autosize: true,
                    shapes: [{
                        type: 'line',
                        xref: 'x',
                        yref: 'y',
                        x0: 0,
                        x1: this.x1,
                        y0: 0,
                        y1: this.y1,
                        fillcolor: '#41FF35',
                        line: {
                            color: '#41FF35'
                        },
                        name: this.state.lineRev
                    }]
                }}
                useResizeHandler
                style={{ width: '100%', height: '500px' }}
                config={{ editable: true }}
                onUpdate={(e) => {
                    this.x1 = e.layout.shapes[0].x1;
                    this.y1 = e.layout.shapes[0].y1;
                }}
                onRelayout={(e) => {
                    if (hasOwnProperty.call(e, 'shapes[0].x0') || hasOwnProperty.call(e, 'shapes[0].y0')) {
                        var lineRev = this.state.lineRev;
                        this.setState({
                            lineRev: lineRev + 1
                        })
                    } else if (hasOwnProperty.call(e, 'shapes[0].x1') || hasOwnProperty.call(e, 'shapes[0].y1')) {
                        this.props.callbackSetXY1(e['shapes[0].x1'], e['shapes[0].y1']);
                    }
                }}
            />
        );
    }
}