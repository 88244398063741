// DarkModeContext.js
import React, { createContext, useState, useEffect } from 'react';

export const DarkModeContext = createContext();

export const DarkModeProvider = ({ children }) => {
  const initialDarkMode = localStorage.getItem('darkMode') === 'true';
  const [isDark, setIsDark] = useState(initialDarkMode);

  useEffect(() => {
    const darkStyleSheet = document.getElementById("dark-mode-stylesheet");

    if (isDark) {
      darkStyleSheet.removeAttribute("disabled");
    } else {
      darkStyleSheet.setAttribute("disabled", "disabled");
    }

    localStorage.setItem('darkMode', isDark);
  }, [isDark]);

  return (
    <DarkModeContext.Provider value={{ isDark, setIsDark }}>
      {children}
    </DarkModeContext.Provider>
  );
};
