import axios from "axios";
import LoginStore from '../modules/Auth/LoginStore';

axios.defaults.baseURL = process.env.API_BASE_URL;
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common.Accept = "application/json";

// GET method
export const get = async (endPoint) => {
    var access_token = LoginStore.accessToken;
    const token = `Bearer ${access_token}`
    
    const result = await axios.get(
        endPoint,
        { headers: { Authorization: token } }
    );
    return result.data;
};

export const post = async (endPoint, params = {}, userConfig = {}) => {
    var access_token = LoginStore.accessToken;
    const token = `Bearer ${access_token}`

    try {
        return axios.post(endPoint, params, { headers: { Authorization: token } });
    } catch (e) {
        return new Promise((_, reject) => reject("Axios Error:", e));
    }
};

// DELETE method
export const deleteRequest = async (endPoint, params = {}) => {
    var access_token = LoginStore.accessToken;
    const token = `Bearer ${access_token}`;

    try {
        return await axios.delete(endPoint, { 
            headers: { Authorization: token },
            data: params  // Axios uses 'data' property for DELETE request payload
        });
    } catch (e) {
        return new Promise((_, reject) => reject("Axios Error:", e));
    }
};

export const request = async (method, endPoint, params = {}, userConfig = {}) => {
    var access_token = LoginStore.accessToken;
    const token = `Bearer ${access_token}`;
    let config = { headers: { Authorization: token }, ...userConfig };

    switch (method.toUpperCase()) {
        case 'GET':
            return axios.get(endPoint, config);
        case 'POST':
            return axios.post(endPoint, params, config);
        case 'PUT':
            return axios.put(endPoint, params, config);
        case 'DELETE':
            return axios.delete(endPoint, { ...config, data: params }); // Axios needs data in delete
        default:
            throw new Error("Unsupported request method");
    }
};
