import React, { Component } from 'react';

class Footer extends Component {
    render() {
        return (
            <footer className="app-footer">
                <span className="ml-auto"><a href="http://reservoirdata.com">Reservoir Data Systems</a> &copy; {new Date().getFullYear()} </span>
            </footer>
        )
    }
}

export default Footer;
