import React, { Component } from "react";
import * as restAPI from '../../../../../common/utilities/ApiService';
import AlertModal from "./AlertModal";

export default class AlertTemplatesTab extends Component {

    constructor(props) {
        super(props);

        this.state = {
            alert_templates: [],

            // This is the current template being edited
            working_template: {},
            working_template_id: null,
            showTemplateModal: false,

            // This is the current alert being edited
            working_alert: {},
            working_alert_id: null,
            working_template_key: 0,
            showAlertModal: false
        };

        this.toggleTemplateModal = this.toggleTemplateModal.bind(this);
        this.toggleAlertModal = this.toggleAlertModal.bind(this);

        this.editRow = this.editRow.bind(this);
        this.saveTemplate = this.saveTemplate.bind(this);
        this.saveAlert = this.saveAlert.bind(this);
        this.showCreateTemplateModal = this.showCreateTemplateModal.bind(this);
        this.showCreateAlertModalFromTemplate = this.showCreateAlertModalFromTemplate.bind(this);
    }


    async componentDidMount() {
        // TODO: Load alert_templates from api into state via set state
    }

    editRow(template, isDelete = false) {
        let working_template_key = this.state.working_template_key + 1;
        console.log(template);

        if (isDelete) {
            restAPI.post('/api/1/wells/alert_templates/' + template.id, {
                "operation": "DELETE"
            }).then(res => {
                this.props.refreshTemplates();
            }).catch(err => {
                console.log(err);
            });
        } else {
            //edit alerts in progress

            let working_template = {
                subject: template.subject,
                set_point_value: template.set_point_value,
                refactory_period: template.refactory_period,
                users: template.users,
                email_list: template.email_list,
                phone_list: template.phone_list,
                job_id: template.job_id,
                field_name: template.field_name,
                field: template.field,
                condition: template.condition,
                user_list: template.user_list,
                id: template.id
            };
    
            this.setState({
                working_template: working_template,
                working_template_id: template.id,
                working_template_key: working_template_key,
                showTemplateModal: true,
                isTemplate: true
            })
        }
    }

    saveTemplate(workingTemplateId, templateFormValues) {
        let alert_template = templateFormValues;
        alert_template.id = workingTemplateId;

        restAPI.post('/api/1/wells/alert_templates', alert_template).then(res => {
            this.props.refreshTemplates();
            
            // Clear form values
            this.setState({
                working_template: {},
                working_template_id: null,
                showTemplateModal: false,
            })
        }).catch(err => {
            console.log(err);
        });
    }

    saveAlert(idPlaceholder, templateFormValues) {
        let alert_template = templateFormValues;

        restAPI.post('/api/1/wells/alerts', alert_template).then(res => {
            this.props.refreshAlerts();
            
            // Clear form values
            this.setState({
                working_template: {},
                working_template_id: null,
                showTemplateModal: false,
            })
        }).catch(err => {
            console.log(err);
        });
    }

    showCreateTemplateModal() {
        let working_template_key = this.state.working_template_key + 1;

        this.setState({
            working_template: {},
            working_template_id: null,
            working_template_key: working_template_key,
            showTemplateModal: true,
            isTemplate: true
        })
    }

    toggleTemplateModal() {
        this.setState(prevState => ({
            working_template_id: null,
            working_template: {},
            showTemplateModal: !prevState.showTemplateModal,
            isTemplate: true
        }));
    }

    showCreateAlertModalFromTemplate(template) {
        console.log(template);
        let working_template_key = this.state.working_template_key + 1;

        let working_template = {
            subject: template.subject,
            set_point_value: template.set_point_value,
            refactory_period: template.refactory_period,
            users: template.users,
            email_list: template.email_list,
            phone_list: template.phone_list,
            job_id: template.job_id,
            field_name: template.field_name,
            field: template.field,
            condition: template.condition,
            user_list: template.user_list,
        };

        console.log(working_template_key);

        this.setState({
            working_template: working_template,
            working_template_id: template.id,
            working_template_key: working_template_key,
            showTemplateModal: true,
            isTemplate: false
        })
    }

    toggleAlertModal() {

    }



    render() {
        return (
            <>
                <button className="float-right btn btn-sm btn-success" onClick={this.showCreateTemplateModal}> Add New Alert Template</button>

                <AlertModal
                    show={this.state.showTemplateModal}
                    availableJobs={this.props.availableJobs}
                    availableUsers={this.props.availableUsers}
                    initialAlertFormValues={this.state.working_template}
                    currentAlertId={this.state.working_template_id}
                    saveAlert={this.saveAlert}
                    saveTemplate={this.saveTemplate}
                    toggleModal={this.toggleTemplateModal} 
                    key={this.state.working_template_key}
                    isTemplate={this.state.isTemplate } />

                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>Well</th>
                            <th>Alert Subject</th>
                            <th>Users</th>
                            <th>Phone Numbers</th>
                            <th>Email List</th>
                            <th>Criteria</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.alert_templates.map(function (template) {
                            return (
                                <tr key={template.id}>
                                    <td>{template.job.name}</td>
                                    <td>{template.subject}</td>
                                    <td>
                                        {template.users.map(u => {
                                            return (
                                                <span key={u.id} style={{ marginRight: "5px" }}>{u.name}</span>
                                            )
                                        })}
                                    </td>
                                    <td>{template.phone_list}</td>
                                    <td>{template.email_list}</td>
                                    <td>
                                        {template.field_name}
                                        {template.condition_display}
                                        {template.set_point_value}
                                    </td>
                                    <td style={{ textAlign: "right" }}>
                                        <button onClick={() => this.showCreateAlertModalFromTemplate(template)} className="btn-xs btn-success" style={{ marginRight: 20 }}>Use Template</button>
                                        <button onClick={() => this.editRow(template, false)} className="btn-xs btn-primary" style={{ marginRight: 20 }} title="Edit"><i className="fa fa-pencil"></i></button>
                                        <button onClick={() => this.editRow(template, true)} className="btn-xs btn-danger" title="Delete"><i className="fa fa-times"></i></button>

                                    </td>
                                </tr>
                            )
                        }, this)}
                    </tbody>

                </table>
            </>
        )
    }

}

