import { DarkModeContext } from '../../theme/DarkModeContext';
import React, {useContext} from 'react'
import { Switch } from '@blueprintjs/core'

export function DarkModeToggle() {
  const { isDark, setIsDark } = useContext(DarkModeContext);

  React.useEffect(() => {
    const darkStyleSheet = document.getElementById("dark-mode-stylesheet");
    
    if (isDark) {
        darkStyleSheet.removeAttribute("disabled");
    } else {
        darkStyleSheet.setAttribute("disabled", "disabled");
    }

    localStorage.setItem('darkMode', isDark);
  }, [isDark]);

  return (
    <>
    <div style={{color: 'white', display: 'inline', padding:'19px', paddingLeft:'20px'}}>NEW! Dark Mode</div>
    <Switch
        innerLabelChecked="🌙 on"
        innerLabel="off 🔆"
        checked={isDark}
        onChange={({ target }) => setIsDark(target.checked)}
        style={{padding: "19px", paddingLeft: "24px", display: "inline"}}
        aria-label="Dark mode toggle"/>
    </>
  );
}
