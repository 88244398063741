
import React, { useEffect, useState, useContext } from 'react'
import { MixedAccordion } from './Accordion'
import { YAxisDropZone } from './YAxisDropZone'
import { YAxisInputs } from './YAxisInputs'
import { IconButton } from '../Core/Shared'
import { UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap'
import { SketchPicker } from 'react-color'
import { presetAxisColors } from '../Core/PresetAxisColors'
import { DarkModeContext } from '../../theme/DarkModeContext'

export const YAxisGraphConfig = ({ graph, updateLocalStateGraph }) => {

    const isDark = useContext(DarkModeContext);
    const [accordions, setAccordions] = useState([])

    useEffect(() => {
        setAccordions(
            axisList.map((axis) => {

                const axisConfig = graph.axes.find((axisConfig) => (axisConfig.ordinal + 1) === axis)

                const axisConfigToUse = axisConfig ?? getEmptyAxisConfig(axis)
                return {
                    id: axis,
                    body: getAxisBody(axis, axisConfigToUse),
                    header: getAxisHeader(axis, axisConfigToUse),
                }
            }))

    }, [graph])

    const newAxis = {
        ordinal: null,
        min_value: 0,
        max_value: null,
        parameters: [],
        color: null
    }
    const getEmptyAxisConfig = (axis) => {
        return { ...newAxis, ordinal: axis - 1, temp_id: axis, color: presetAxisColors[axis - 1] };
    }

    const axisList = [1, 2, 3, 4, 5, 6, 7, 8];

    const getAxisHeader = (axisNum, axisConfigToUse) => {

        const popoverTarget = `PopoverLegacy-${axisNum}`

        return <div style={{ display: 'inline-Block', marginBottom: '0px', width: '100%' }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: '5px' }}>
                <div>
                    <div>{getHeaderIcon(axisNum).map(el => el)}</div>
                    Axis {axisNum}
                </div>

                <div onClick={e => e.stopPropagation()} id={popoverTarget} style={{ padding: '10px' }}>
                    <IconButton
                        icon={'fa-eyedropper'} style={{ fontSize: '1.0em', paddingRight: '0.5em', color: axisConfigToUse?.color }}
                    />
                </div>
                <UncontrolledPopover
                    placement="bottom"
                    target={popoverTarget}
                    trigger="legacy"
                    onClick={e => e.stopPropagation()}
                >
                    <PopoverHeader>
                        Pick an axis color
                    </PopoverHeader>
                    <PopoverBody>
                        <SketchPicker
                            color={axisConfigToUse?.color} onChange={e => setColor(e.hex, axisConfigToUse)}
                            presetColors={presetAxisColors}
                        />
                    </PopoverBody>
                </UncontrolledPopover>
            </div>
        </div>
    }

    const setColor = (hexColor, axis) => { //todo use for to update color on axis

        updateAxes({ ...axis, color: hexColor })
    }

    const getHeaderIcon = (axis) => {
        let circleStyles = {
            color: isDark ? 'rgb(168, 161, 149)' : 'black',
            paddingLeft: '1px',
            paddingRight: '1px'
        }
        let graphStyles = {
            color: isDark ? 'rgb(168, 161, 149)' : 'black',
            fontSize: '1.5em',
            paddingLeft: '4px',
            paddingRight: '4px'
        }
        let iconArr = [<i className="fa fa-bar-chart" aria-hidden="true" style={graphStyles} />]

        for (let i = 1; i <= 8; i++) {
            if (i <= 4) {
                i === axis
                    ? iconArr.unshift(<i className="fa fa-circle" aria-hidden="true" style={circleStyles} />)
                    : iconArr.unshift(<i className="fa fa-circle-thin" aria-hidden="true" style={circleStyles} />)
            } else if (i >= 5) {
                i === axis
                    ? iconArr.push(<i className="fa fa-circle" aria-hidden="true" style={circleStyles} />)
                    : iconArr.push(<i className="fa fa-circle-thin" aria-hidden="true" style={circleStyles} />)
            }
        }

        return iconArr
    }

    const updateAxes = (newAxisState) => {
        const tempAxes = [...graph.axes]
        const target = tempAxes.findIndex(axis => {
            return ((!newAxisState.id && axis.ordinal === newAxisState.ordinal) || (axis.id && axis.id === newAxisState.id))
        })
        if (target !== -1) {
            tempAxes[target] = newAxisState
        }
        else {
            tempAxes.push(newAxisState)
        }
        updateLocalStateGraph('axes', tempAxes)
    }

    const getAxisBody = (axis, axisConfig) => {

        const axisConfigToUse = axisConfig ?? getEmptyAxisConfig(axis)

        return <YAxisDropZone
            axisNumber={axis} axis={axisConfigToUse} updateAxes={updateAxes} graph={graph} style={{ padding: '15px' }}
        >
            <YAxisInputs updateAxes={updateAxes} axis={axisConfig} />
            {axisConfigToUse.parameters.length === 0 && <div
                style={{
                    backgroundColor: 'lightgray', height: '50px', borderRadius: '0.25rem',
                    display: 'flex', alignItems: 'center', justifyContent: 'center'
                }}
            >
                <div style={{ fontSize: '1.5em', color: isDark ? "black" : "white"}}>Drop measures here to add them to axis</div>
            </div>}
        </YAxisDropZone>
    }

    return <MixedAccordion accordions={accordions} accordionClassName='yAxisGraphConfigAccordionContainer'></MixedAccordion>
}