import React, { useState, useEffect } from 'react'
import ReactTable from 'react-table'
import { SuccessButton, DangerButton } from '../Core/Shared'
import * as restAPI from '../../../../common/utilities/ApiService'
import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"


export const AddSharedItemModal = ({ modalContext, setIsModalOpen, addTile }) => {

    const itemType = modalContext.split(' ').pop().toLowerCase();

    const [sharedItems, setSharedItems] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [dashboardId, setDashboardId] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        (async () => {
            const sharedItemsArr = await getSharedItems();
            setSharedItems(sharedItemsArr.flat());
            setIsLoading(false);
        })()
    }, []);

    const getSharedItems = async () => {
        // get all shared dashboards
        const { current_org_id } = await restAPI.get('/api/1/users/me/orgs')
        const sharedDashboards = await restAPI.get(`/api/1/dashboarding/shared/${current_org_id}`)
        // return graphs / widgets with dashboard id
        return await Promise.all(sharedDashboards.map(async sd => await restAPI.get(`/api/1/dashboarding/shared/${sd.id}/${itemType}`)))


    };

    const removeGraphIds = (graph) => {
        let newGraph = { ...graph };
        delete newGraph.id;
        delete newGraph.tile_id;
        newGraph.axes.forEach(a => {
            delete a.id;
            delete a.graph_id;
            a.parameters.forEach(p => {
                delete p.id;
                delete p.axis_id;
            });
        });
        return newGraph;
    }

    const removeWidgetArrIds = (widgetArr) => {
        let newWidgetArr = [...widgetArr];
        newWidgetArr.forEach(w => {
            delete w.id;
            delete w.tile_id;
        });
        return newWidgetArr;
    }

    const onConfirm = async () => {
        let newTile;
        const dashboard = await restAPI.get(`/api/1/dashboarding/${dashboardId}`);
        const copiedItem = dashboard.rows.find(row => row.id === selectedItem.row_id).tiles.find(tile => tile.id === selectedItem.tile_id);
        if (selectedItem) {
            // eslint-disable-next-line default-case
            switch (modalContext) {
                case 'Full-Size Graph':
                    newTile = addTile(1, 2);
                    newTile.graph = removeGraphIds(copiedItem.graph)
                    break;
                case 'Half-Size Graph':
                    newTile = addTile(1, 1);
                    newTile.graph = removeGraphIds(copiedItem.graph)
                    break;
                case 'Widget':
                    newTile = addTile(2, 1);
                    newTile.widgets = removeWidgetArrIds(copiedItem.widgets);
            }
        }
        setIsModalOpen(false);
    };

    return !isLoading &&
        <Modal
            style={{ maxWidth: '1600px', width: '90vw', marginTop: '100px' }}
            isOpen={true}
            className='modal-dialog'
            contentClassName='custom-modal-style'
        >
            <ModalHeader
                close={<button className="close" onClick={() => setIsModalOpen(false)}>×</button>}
            >Add Shared {modalContext} Tile
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col xs sm md lg xl={{ size: 8, offset: 2 }}>
                        <div className='card card-default'>
                            <ReactTable
                                getTdProps={(state, rowInfo, col, instance) => {
                                    return {
                                        style: {
                                            cursor: 'pointer',
                                            background: selectedRow === rowInfo?.index && '#e1ebfa'
                                        },
                                        onClick: () => {
                                            if (rowInfo && rowInfo.row) {
                                                if (selectedRow === rowInfo.index) {
                                                    // deselect row
                                                    setSelectedRow(null);
                                                    setSelectedItem(null);
                                                    setDashboardId(null);
                                                } else {
                                                    // select row
                                                    setSelectedRow(rowInfo.index);
                                                    setSelectedItem(rowInfo.row._original);
                                                    // set dashboard id only if different
                                                    if (rowInfo.row._original.dashboard_id !== dashboardId) {
                                                        setDashboardId(rowInfo.row._original.dashboard_id);
                                                    }
                                                }
                                            }
                                        },
                                    }
                                }}
                                filterable
                                data={sharedItems}
                                defaultPageSize={10}
                                className='-striped -highlight'
                                columns={[
                                    {
                                        Header: 'Dashboard Title',
                                        accessor: 'title',
                                        className: 'text-center',
                                    },
                                    {
                                        Header: 'Owner',
                                        accessor: 'owner',
                                        width: 200,
                                        className: 'text-center',
                                    },
                                    {
                                        Header: `${modalContext}`,
                                        accessor: 'id',
                                        className: 'text-center',
                                        style: { 'whiteSpace': 'unset' },
                                        Cell: e => itemType === 'graph'
                                            ? e.original.graph_name || `Graph ${e.original.graph_id}`
                                            : e.original.widgets || `Widget ${e.original.widget_id}`
                                    }
                                ]}
                            />
                        </div>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <DangerButton
                    text='Cancel'
                    onClick={() => setIsModalOpen(false)}
                />
                <SuccessButton
                    text='Confirm'
                    disabled={!dashboardId}
                    onClick={() => onConfirm()}
                />
            </ModalFooter>
        </Modal >
}