import _ from 'lodash'
import React from 'react'
import { Row, Col } from "reactstrap"
import { roundToThousandthsPlace } from './Helpers';

const graphLegendContentsAreEqual = (prevRender, nextRender) => prevRender.selectedTimestamp === nextRender.selectedTimestamp
    && prevRender.doubleWide === nextRender.doubleWide
    && _.isEqual(prevRender.legendInformation, nextRender.legendInformation)

const RawGraphLegendContents = ({ legendInformation, selectedTimestamp, doubleWide }) => {
    const colSize = doubleWide ? 2 : 4

    return <Row>
        <Col>{new Date(selectedTimestamp).toLocaleString()}</Col>
        {Object.keys(legendInformation).map((li, idx) => {
            return <Row key={`infoRow-idx${idx}`}>
                <Col className='legendHeader'>{li}</Col>
                <Row className='legendContainer'>
                    {legendInformation[li].map((p, idx) =>
                        <Col className='legendContent' xs={colSize} sm={colSize} md={colSize} lg={colSize} xl={colSize} key={`infoCol-idx${idx}`}>
                            <span className='legendCircle' style={{ backgroundColor: p.color }}></span>
                            <div className='legendMeasure'>{p.measure}: {roundToThousandthsPlace(p.value, 'N/A')}</div>
                        </Col>
                    )}
                </Row>
            </Row>
        })}
    </Row>
};

export const GraphLegendContents = React.memo(RawGraphLegendContents, graphLegendContentsAreEqual)