import React from 'react';
import Header from './Header';
import Footer from './Footer';
import { Container } from 'reactstrap';

class Layout extends React.Component {

    render() {
        return (
            <div className='app'>
                <Header />
                
                <div className='app-body'>
                    
                    <main className='main'>
                        <Container fluid>
                            {this.props.children}
                        </Container>
                    </main>
                </div>
                
                <Footer />
            </div>
        );
    }
}

export default Layout;