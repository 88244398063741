import React from 'react'
import { GraphTile } from './GraphTile'
import { WidgetTile } from './WidgetTile'

export const DashboardTile = ({ dashboard_id, canEdit, rowOrdinal, tile, jobSelectOptions, all_jobs,
    x_axis_units_of_measure, updateGraphTile, updateWidgetTile, rowWidth, removeTile }) => {
    return tile.type_id === 1
        ? <GraphTile
            tile={tile}
            canEdit={canEdit}
            all_jobs={all_jobs}
            rowWidth={rowWidth}
            removeTile={removeTile}
            rowOrdinal={rowOrdinal}
            dashboard_id={dashboard_id}
            updateGraphTile={updateGraphTile}
            x_axis_units_of_measure={x_axis_units_of_measure}
        />
        : tile.type_id === 2
            ? <WidgetTile
                tile={tile}
                canEdit={canEdit}
                jobSelectOptions={jobSelectOptions}
                updateWidgetTile={updateWidgetTile}
                removeTile={removeTile}
                rowOrdinal={rowOrdinal}
            />
            : <span>Tile Type Unrecognized</span>
}
