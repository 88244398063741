import React, { Component } from "react";
import { Link, Redirect } from 'react-router-dom'

import {
    FormGroup,
    Input,
} from 'reactstrap';

import queryString from 'query-string'

import LoginStore from '../../modules/Auth/LoginStore';

import * as restAPI from '../../utilities/ApiService';

class ResetPassword extends Component {
    
    constructor(props, context) {
        super(props, context);

        this.state = {
            redirectToDashboard: false,
            redirectToLogin: false,
            password: null,
            confirmPassword: null,
            
            str1: null,
            str2: null
        }


        if (LoginStore.isLoggedIn()) {
            this.state = {
                redirectToDashboard: true
            }
        }
        
        this.updatePassword = this.updatePassword.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    
    componentDidMount() {
        const values = queryString.parse(this.props.location.search)
        console.log(values.str1);
        console.log(values.str2);


        this.setState({
            str1: values.str1,
            str2: values.str2
        });

    }

    updatePassword(e) {
        e.preventDefault();
        
        restAPI.post('/api/1/auth/updatePassword', {
            str1: this.state.str1,
            str2: this.state.str2,
            password: this.state.password,
            confirmPassword: this.state.confirmPassword
        }).then(res => { 
            console.log(res);
            this.setState({
                redirectToLogin: true
            })
        }).catch(err => {
            console.log(err);
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        
        this.setState({
            [name]: value
        });   
    }

    render() {
        if (this.state.redirectToDashboard) {
            return <Redirect to='/login' />
        } else if (this.state.redirectToLogin) {
            return (
                <div className="block-center mt-4 wd-fixed-user-form">
                    <div className="card card-flat">
                        <div className="card-header text-center bg-dark">
                            <a href="/">
                                <img className="block-center rounded login-logo" src="img/logo.png" alt="Logo" />
                            </a>
                        </div>

                        <div className="card-body">
                            <p>Password updated successfully, click <Link to="/login">here</Link> to log in.</p>
                        </div>
                    </div>
                </div>
            );
        } else {
            const renderButton = (
                <button className="btn btn-block btn-primary mt-3" onClick={this.updatePassword}>Update Password</button>
            );

            const renderDisabledButton = (
                <button className="btn btn-block btn-primary mt-3" disabled>Update Password</button>
            );

            var buttonElement = <span></span>;

            if (this.state.password && this.state.password === this.state.confirmPassword && this.state.password.length >= 12) {
                buttonElement = renderButton;
            } else {
                buttonElement = renderDisabledButton;
            }

            return (
                <div className="block-center mt-4 wd-fixed-user-form">
                    <div className="card card-flat">
                        <div className="card-header text-center bg-dark">
                            <a href="/">
                                <img className="block-center rounded login-logo" src="img/logo.png" alt="Logo" />
                            </a>
                        </div>

                        <div className="card-body">
                        
                            <form id="user-login" name="loginForm" noValidate className="mb-3 form-validate">
                                <div>
                                    
                                    <ul>
                                        <li style={ (!this.state.password || this.state.password.length < 12) ? { color: "red" } : { color: "green" } }>Your password must be at least 12 characters.</li>
                                        <li style={ (!this.state.password || this.state.password !== this.state.confirmPassword) ? { color: "red" } : { color: "green" } }>Password and confirmation must match.</li>
                                    </ul>
                                </div>

                                <FormGroup className="form-group">
                                    <label>Password</label>
                                    <div className="input-group with-focus">
                                        <Input type="password" 
                                            name="password"
                                            value={this.state.password ? this.state.password : "" }
                                            onChange={this.handleInputChange} />

                                        <div className="input-group-append">
                                            <span className="input-group-text fa fa-lock text-muted bg-transparent border-left-0"></span>
                                        </div>
                                    </div>
                                </FormGroup>

                                <FormGroup className="form-group">
                                    <label>Confirm Password</label>
                                    <div className="input-group with-focus">
                                        <Input type="password" 
                                            name="confirmPassword"
                                            value={this.state.confirmPassword ? this.state.confirmPassword : "" }
                                            onChange={this.handleInputChange} />

                                        <div className="input-group-append">
                                            <span className="input-group-text fa fa-lock text-muted bg-transparent border-left-0"></span>
                                        </div>
                                    </div>
                                </FormGroup>
                            
                                <div className="clearfix">
                                    <div className="float-left mt-0">
                                        {/* <Link to="recover" className="text-muted">Forgot your password?</Link> */}
                                    </div>

                                </div>

                                {buttonElement}
                            </form>
                        </div>
                    </div>
                </div>
            
            );
        }
    }
}

export default ResetPassword;
