import React from 'react'
import { ModalBase } from './ModalBasic'

export const DeletionModal = ({ isOpen, setIsOpen, onSubmit, entityDisplayIdentifier, entityDisplayType }) => {
    const bodyContent = <div style={{ textAlign: 'center' }}>
        <p> Deleting will completely remove this {entityDisplayType} and configuration from this dashboard. Once confirmed this cannot be undone.</p>
        <p>
            Would you like to proceed with deleting this entity:
            <span style={{ fontStyle: 'italic', fontWeight: 'bold' }}> {entityDisplayIdentifier}</span>
            ?
        </p>
    </div>

    return <ModalBase
        isOpen={isOpen}
        onSubmit={onSubmit}
        setIsOpen={setIsOpen}
        bodyContent={bodyContent}
        submitButtonText='Delete'
        cancelButtonText='Cancel'
        titleText='Deletion Confirmation'
    />
}