import React, { Component } from "react";
import * as restAPI from '../../../../../common/utilities/ApiService';
import ContactGroupModal from "./ContactGroupModal";
import AlertModal from "./AlertModal";
import LoginStore from "../../../../../common/modules/Auth/LoginStore";


export default class ContactGroupTab extends Component {

    constructor(props) {
        super(props);

        this.state = {
            // This is the current contact group being edited
            working_contact: {},
            working_contact_id: null,
            working_contact_key: 0,
            showContactModal: false,

            working_alert: {},
            working_alert_id: null,
            showAlertModal: false
        };

        this.toggleContactModal = this.toggleContactModal.bind(this);
        this.toggleAlertModal = this.toggleAlertModal.bind(this);

        this.editContact = this.editContact.bind(this);
        this.saveContact = this.saveContact.bind(this);
        this.saveAlert = this.saveAlert.bind(this);
        this.deleteContact = this.deleteContact.bind(this);
        this.showCreateContactModal = this.showCreateContactModal.bind(this);
        this.showCreateAlertModalFromContact = this.showCreateAlertModalFromContact.bind(this);
    }

    async componentDidMount() {

    }

    showCreateContactModal() {
        let working_contact_key = this.state.working_contact_key + 1;
        this.setState({
            working_contact_key: working_contact_key,
            working_contact: {},
            working_contact_id: null,
            showContactModal: true
        })
    }

    showCreateAlertModalFromContact(contact) {
        let working_alert_key = this.state.working_contact_key + 1;

        let working_alert = {
            users: contact.users,
            user_list: contact.u_list,
        }

        console.log(working_alert);
        this.setState({
            working_alert_key: working_alert_key,
            working_alert: working_alert,
            working_alert_id: null,
            showAlertModal: true,
        })

    }

    editContact(contact) {
        let working_contact_key = this.state.working_contact_key + 1;
        console.log(contact)
        let working_contact = {
            users: contact.users,
            user_list: contact.u_list,
            id: contact.id
        };

        this.setState({
            working_contact: working_contact,
            working_contact_id: contact.id,
            working_contact_key: working_contact_key,
            showContactModal: true
        })

    }

    deleteContact(contact) {
        restAPI.post('/api/1/wells/alertsGroup/' + contact.id, {
            "operation": "DELETE"
        }).then(res => {
            this.props.refreshContactGroups();
        }).catch(err => {
            console.log(err)
        });

    }

    saveContact(workingContactId, contactFormValues) {
        let t_contact = contactFormValues;
        t_contact.user_id = LoginStore.user.id;
        t_contact.id = workingContactId;
        restAPI.post('/api/1/wells/alertsGroup', t_contact).then(res => {
            // Force an update to the list of contacts since we've saved one
            this.props.refreshContactGroups();

            // Clear form values
            this.setState({
                working_contact: {},
                working_contact_id: null,
                showContactModal: false
            })
        });
    }

    saveAlert(idPlaceholder, contactFormValues) {
        let t_alert = contactFormValues;

        restAPI.post('/api/1/wells/alerts', t_alert).then(res => {
            this.props.refreshAlerts();

            this.setState({
                working_alert: {},
                working_alert_id: null,
                showAlertModal: false
            })
        }).catch(err => {
            console.log(err);
        });
    }

    toggleContactModal() {
        this.setState(prevState => ({
            working_contact_id: null,
            working_contact: {},
            showContactModal: !prevState.showContactModal
        }));
    }
    toggleAlertModal() {
        this.setState(prevState => ({
            working_alert_id: null,
            working_alert: {},
            showAlertModal: !prevState.showAlertModal
        }));
    }

    render() {
        return (
            <>

                <button className="float-right btn btn-sm btn-success" onClick={this.showCreateContactModal}>Add New Contact Group</button>

                <ContactGroupModal
                    show={this.state.showContactModal}
                    availableUsers={this.props.availableUsers}
                    initialContactFormValues={this.state.working_contact}
                    currentContactId={this.state.working_contact_id}
                    saveContact={this.saveContact}
                    toggleModal={this.toggleContactModal}
                    key={this.state.working_contact_key} />

                <AlertModal
                    show={this.state.showAlertModal}
                    availableUsers={this.props.availableUsers}
                    availableJobs={this.props.availableJobs}
                    initialAlertFormValues={this.state.working_alert}
                    currentAlertId={this.state.working_alert_id}
                    saveAlert={this.saveAlert}
                    toggleModal={this.toggleAlertModal}
                    key={this.state.working_alert_key} />

                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Users</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.contactGroups.map(function (contact) {
                            return (
                                <tr key={contact.id}>
                                    <td>{contact.id}</td>
                                    <td>
                                        {contact.users.map(u => {
                                            return (
                                                <span key={u.id} style={{ marginRight: "10px" }}>{u.name}</span>
                                            )
                                        })}
                                    </td>
                                    <td style={{ textAlign: "right" }}>
                                        <button onClick={() => this.showCreateAlertModalFromContact(contact)} className="btn-xs btn-success" style={{ marginRight: 20 }} title="Create Alert from Contact Group"><i className="fa fa-group"></i></button>
                                        <button onClick={() => this.editContact(contact)} className="btn-xs btn-primary" style={{ marginRight: 20 }} title="Edit"><i className="fa fa-pencil"></i></button>
                                        <button onClick={() => this.deleteContact(contact)} className="btn-xs btn-danger" title="Delete"><i className="fa fa-times"></i></button>
                                    </td>
                                </tr>
                            )
                        }, this)}
                    </tbody>
                </table>
            </>
        )
    }
}

