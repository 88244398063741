import React, { useState } from 'react'
import { DashboardTile } from './DashboardTile'
import { IconButton } from '../Core/Shared'
import {
    Row,
    Col,
    Card
} from 'reactstrap'
import {
    Menu,
    Item,
    Separator,
    useContextMenu
} from 'react-contexify'
import { AddSharedItemModal } from './AddSharedItemModal'
import * as restAPI from '../../legacy/utilities/axios-service';
import { toast } from 'react-toastify'


export const DashboardRow = ({ dashboard_id, canEdit, all_jobs, rowData, updateRow,
    x_axis_units_of_measure, jobSelectOptions, updateFieldValue, formState }) => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContext, setModalContext] = useState('');

    const ADD_NEW_TILE_MENU_ID = `addNewTileMenu-${rowData.id}-${rowData.ordinal}`
    const { show } = useContextMenu({ id: ADD_NEW_TILE_MENU_ID })
    const addTile = (type_id, size) => {
        const newTile = {
            ordinal: rowData.tiles.length,
            size,
            row_id: rowData.id || undefined,
            type_id,
        }
        if (type_id === 2) {
            newTile.widgets = []
        }
        const tiles = [...rowData.tiles].concat(newTile)
        updateRow({ ...rowData, tiles })
        return newTile;
    }
    const addFullSizeGraphTile = () => addTile(1, 2)
    const addHalfSizeGraphTile = () => addTile(1, 1)
    const addWidgetTile = () => addTile(2, 1)
    const totalTileWidthSize = rowData.tiles.reduce((p, c) => p + c.size, 0);

    const updateGraphTile = (newGraphTileState) => {
        const target = rowData.tiles.findIndex(t => (!newGraphTileState.id && t.ordinal === newGraphTileState.ordinal) || (t.id && t.id === newGraphTileState.id))
        rowData.tiles[target] = newGraphTileState
        const newRowState = {
            ...rowData,
            tiles: rowData.tiles
        }
        updateRow(newRowState)
    }

    const updateWidgetTile = (newWidgetTileState) => {
        const target = rowData.tiles.findIndex(t => (!newWidgetTileState.id && t.ordinal === newWidgetTileState.ordinal) || (t.id && t.id === newWidgetTileState.id))
        rowData.tiles[target] = newWidgetTileState
        const newRowState = {
            ...rowData,
            tiles: rowData.tiles
        }
        updateRow(newRowState)
    }

    const onSharedClick = async (context) => {
        setModalContext(context);
        setIsModalOpen(true);
    };

    const removeTile = async (tile) => {
        // remove selected tile
        const tile_id = tile?.id || `r${rowData.ordinal}t${tile.ordinal}`
        const savedTile = tile.id !== undefined
        const newRowData = { ...rowData }
        //const tileToDelete = newRowData.tiles.find(t => t.id === tile_id);
        //console.log('Delete Tile: ', tileToDelete)
        //console.log('Tiles: ', rowData.tiles[0])
        const tileIndex = newRowData.tiles.indexOf(tile);
        newRowData.tiles.splice(tileIndex, 1);
        // if no more tiles
        if (newRowData.tiles.length === 0) {
            // remove row and update ordinals
            const newFormStateRows = [...formState.rows];
            const rowToDelete = newFormStateRows.find(r => r.id === newRowData.id);
            const rowIndex = newFormStateRows.indexOf(rowToDelete);
            newFormStateRows.splice(rowIndex, 1);
            newFormStateRows.forEach((r, i) => r.ordinal = i)
            // delete row
            if (savedTile) {
                await restAPI.post(`/api/1/dashboarding/graph/delete/${newRowData.id}`, { type: 'Row' })
                    .then(() => {
                        toast('Row Deleted Successfully', { type: 'success' })
                    })
                    .catch(err => {
                        console.log(err)
                        toast('An Error Occurred', { type: 'error' })
                    });
            }
            else {
                toast('Row Deleted Successfully', { type: 'success' })
            }
            // update form state
            updateFieldValue('rows', newFormStateRows);

        } else if (newRowData.tiles.length === 1) {
            // update remaining tile ordinal
            newRowData.tiles[0].ordinal = 0;
            // delete tile
            
            if (savedTile) {
                await restAPI.post(`/api/1/dashboarding/graph/delete/${tile_id}`, { type: 'Tile' })
                    .then(() => {
                        toast('Tile Deleted Successfully', { type: 'success' })
                    })
                    .catch(err => {
                        console.log(err)
                        toast('An Error Occurred', { type: 'error' })
                    });
            }
            else {
                toast('Tile Deleted Successfully', { type: 'success' })
            }
            // update row data
            updateRow(newRowData)
        }
    };

    const rowWidth = window.innerWidth * .97;

    return <Row style={{ height: '100%', width: rowWidth, marginBottom: '0.5em', textAlign: 'center', alignContent: 'center', background: 'white' }}><Col xl={12}>
        <Card style={{ minHeight: '400px', height: '100%', textAlign: 'center' }}>
            <Row style={{ height: '100%' }}>
                {rowData?.tiles && rowData.tiles.map(t => {
                    return <Col xl={t.size * 6} style={{ boxShadow: '0 0 0 1px lightgray', borderRadius: '6px', }} key={`tile-${rowData.ordinal}${t.ordinal}-${t.id ?? 'new'}`} >
                        <DashboardTile
                            canEdit={canEdit}
                            x_axis_units_of_measure={x_axis_units_of_measure}
                            rowOrdinal={rowData.ordinal} tile={t} updateGraphTile={updateGraphTile}
                            updateWidgetTile={updateWidgetTile}
                            jobSelectOptions={jobSelectOptions} all_jobs={all_jobs} dashboard_id={dashboard_id}
                            rowWidth={rowWidth} removeTile={removeTile}
                        />
                    </Col>

                })}
                {canEdit && totalTileWidthSize < 2 &&
                    <Col xl={totalTileWidthSize === 0 ? 12 : 6} style={{ boxShadow: '0 0 0 1px lightgray', borderRadius: '6px' }}>
                        <Card style={{ height: '100%', }}>
                            <IconButton
                                icon='fa-plus-circle'
                                style={{ fontSize: '5em', margin: 'auto', color: '#164787' }}
                                onClick={show}
                            />
                        </Card>
                    </Col>
                }
            </Row>
        </Card>
        <Menu id={ADD_NEW_TILE_MENU_ID}>
            {totalTileWidthSize === 0 &&
                <>
                    <Item onClick={addFullSizeGraphTile}>
                        Add Full-Size Graph Tile
                    </Item>
                    <Item onClick={() => onSharedClick('Full-Size Graph')}>
                        Add Shared Full-Size Graph Tile
                    </Item>
                </>
            }
            <Item onClick={addHalfSizeGraphTile}>
                Add Half-Size Graph Tile
            </Item>
            <Item onClick={() => onSharedClick('Half-Size Graph')}>
                Add Shared Half-Size Graph Tile
            </Item>
            <Separator />
            <Item onClick={addWidgetTile}>
                Add Widget Tile
            </Item>
            <Item onClick={() => onSharedClick('Widget')}>
                Add Shared Widget Tile
            </Item>
        </Menu>
        {isModalOpen &&
            < AddSharedItemModal modalContext={modalContext} setIsModalOpen={setIsModalOpen}
                updateGraphTile={updateGraphTile} updateWidgetTile={updateWidgetTile} addTile={addTile} />
        }
    </Col></Row>
}
