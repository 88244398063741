import * as d3 from "d3"
import { drawYAxes } from "./YAxes"
import { drawXAxis } from './XAxis'
import React, { useRef, useEffect, useState, useContext } from 'react'
import { DashboardingFormContext } from "../../../Contexts/DashboardingFormContext"
import { DarkModeContext } from "../../../theme/DarkModeContext"
import { getMarginedExtent, ON_HOVER_CIRCLE_RADIUS } from "./Helpers"
// import * as restAPI from '../../../legacy/utilities/axios-service'

const yAxisArrLeft = [3, 2, 1, 0]
const yAxisArrRight = [4, 5, 6, 7]

const ON_HOVER_BUFFER = 2 * ON_HOVER_CIRCLE_RADIUS

// const resolutionOptions = [
//     { id: 1, label: 'Medium', value: 1 },
//     { id: 2, label: 'High', value: 2 },
//     { id: 3, label: 'Extreme', value: 3 },
// ]

export const LineGraph  = ({
    graph, graph_id,
    seriesData, setSeriesData,
    lastFullStreamed,

    graphHeight,
    graphTileWidth,

    isDataStreamingOn,

    isMouseOverFrozen, setIsMouseOverFrozen,

    yAxesZoomValues, setYAxesZoomValues,
    xDisplayDomain, setXDisplayDomain,
    selectedTimestamp, setSelectedTimestamp,

    onStartBrush
}) => {


    // line graph references
    const configuration = graph
    const graphWidth = graphTileWidth - 320// width of y axes
    const graphWidthMinusBuffer = graphWidth - ON_HOVER_BUFFER
    const svg = d3.select(`#svg-${graph_id}`)

    // capture mouse down event
    svg
        .on("mousedown", function () {
            setMouseDown(true)
        });

    // capture mouse up event
    d3.select(window)
        .on("mouseup", function () {
            setMouseDown(false)
        });
    svg.on("mouseup", function () {
        setMouseDown(false)
    });

    const {isDark} = useContext(DarkModeContext);
    //Is this ok?
    svg.append("path")
        .attr("id", `mouse-line-${graph_id}`)
        .attr("pointer-events", 'none')
        .style("stroke", isDark ? "rgb(168, 161, 149)" : "black")
        .style("stroke-width", "1px")
        .style("opacity", "0");
    const mouseLine = d3.select(`#mouse-line-${graph_id}`)

    const graphRef = useRef(`D3Graph-${graph_id}`), xAxisRef = useRef(`xAxis-${graph_id}-bottom`)
    const yAxisRef1 = useRef(`yAxis-${graph_id}-1`), yAxisRef2 = useRef(`yAxis-${graph_id}-2`)
    const yAxisRef3 = useRef(`yAxis-${graph_id}-3`), yAxisRef4 = useRef(`yAxis-${graph_id}-4`)
    const yAxisRef5 = useRef(`yAxis-${graph_id}-5`), yAxisRef6 = useRef(`yAxis-${graph_id}-6`)
    const yAxisRef7 = useRef(`yAxis-${graph_id}-7`), yAxisRef8 = useRef(`yAxis-${graph_id}-8`)
    const yAxisRefArr = [yAxisRef1, yAxisRef2, yAxisRef3, yAxisRef4, yAxisRef5, yAxisRef6, yAxisRef7, yAxisRef8]

    const [yAxes, setYAxes] = useState([])
    const [xAxisElement, setXAxisElement] = useState({})
    const [isLoading, setIsLoading] = useState(true)
    const [graphInterval, setGraphInterval] = useState(0)
    const [drawLineProps, setDrawLineProps] = useState({ displayLine: false, dataPoint: null, timestamp: null })
    const [currentMouseClient, setCurrentMouseClient] = useState(null);
    const [isMouseDown, setMouseDown] = useState(false);

    const { sharedMouseOver, setSharedMouseOver } = useContext(DashboardingFormContext)

    //When the series data changes, redraw the graph 
    //(as long as I have the right number of measures in my series data)
    useEffect(() => {
        seriesData.length === populatedAxesParams && drawGraph(configuration);
    }, [isDark, seriesData, configuration.show_live_data]);

    useEffect(() => {
        drawLine(drawLineProps);
    }, [drawLineProps]);

    useEffect(() => {
        const timeWindow = configuration.time_amount * configuration.unit_of_time?.duration * 1000;
        setGraphInterval(timeWindow);
    }, [configuration])

    useEffect(() => {
        if (!isDataStreamingOn) {
            return
        }
        else if (isDataStreamingOn) {
            const ws = new WebSocket(process.env.SOCKET_BASE_URL);
            ws.onopen = () => {
                console.log(`graph ${graph_id} connected`)
                ws.send(JSON.stringify('connected'))
            }
            ws.onerror = (e) => { console.log(e) }
            ws.onmessage = (message) => {
                const m = JSON.parse(message.data)
                switch (typeof m) {
                    case 'string':
                        if (m === 'send parameters') {
                            sendParameters()
                        } else if (m === 'pong') {

                        }
                        break;
                    case 'object':
                        if (isDataStreamingOn && m.type === 'graph') {
                            // make sure you only render data on websocket graph message
                            updateSeriesDataWithIncomingLiveData(m)
                        }
                        break;
                    default:
                        console.log('unrecognized data return for live data')
                }
            }

            const sendParameters = () => {
                // set default start time for all parameters to avoid data time discrepancy
                console.log("Starting a connection : ", lastFullStreamed - 1000)
                const parameters = seriesData.map(sd => ({
                    measure: sd.measure,
                    job_id: sd.job_id,
                    startTime: lastFullStreamed,
                    graphInterval,
                    graph_id,
                }))

                parameters.forEach(p => {
                    ws.send(JSON.stringify(p))
                })
            }

            return () => {
                console.log(`graph ${graph_id} disconnected`)
                ws.close();
            }
        }

    }, [configuration.axes, isDataStreamingOn, graphInterval]);

    //Update properties based on sharedMouseOver timestamp
    useEffect(() => {
        if (!isLoading && !isMouseOverFrozen) {
            if (graph_id !== sharedMouseOver.graphId) {
                const thisGraphIncludesDataAtTheSharedTimestamp = seriesData?.some(measure => measure?.graphData?.some(dataPoint => dataPoint.x === sharedMouseOver.timestamp))
                if (sharedMouseOver.timestamp === null || !thisGraphIncludesDataAtTheSharedTimestamp) {
                    clearLine()
                }
                else {
                    setDrawLineProps((currentParams) => ({
                        ...currentParams,
                        displayLine: true,
                        timestamp: sharedMouseOver.timestamp
                    })
                    )
                }
            }
        }
    }, [sharedMouseOver])

    const populatedAxesParams = configuration?.axes?.map(a => a.parameters)?.flat()?.length || 0;

    const setMouseOverComponentOpacity = (opacity) => {
        mouseLine.style("opacity", opacity);
        d3.selectAll(`.circle-${graph_id}`).style("opacity", opacity);
    };

    const drawLine = ({ displayLine, timestamp }) => {
        const opacity = displayLine ? 1 : 0
        setMouseOverComponentOpacity(opacity)

        const dataAtTimeStamp = seriesData[0].graphData?.find(s => s?.x === timestamp)
        const dataPoint = xAxisElement.xScale ? xAxisElement.xScale(dataAtTimeStamp?.x) : null
        dataPoint &&
            mouseLine
                .attr("d", () => {
                    var d = "M" + dataPoint + "," + graphHeight;
                    d += " " + dataPoint + "," + 0;
                    return d;
                })

        timestamp !== null && setSelectedTimestamp(+timestamp)

        const circles = d3.selectAll(`.circle-${graph_id}`);
        circles._groups.forEach(group => {
            group.forEach((circle) => {
                const yAxis = yAxes.flat().find(a => a.circleId === circle.id);
                if (yAxis === undefined)
                    return;
                const { yScale, graphData } = yAxis

                const selectedData = graphData.find(g => g?.x === timestamp)

                const isCircleValue = selectedData && selectedData?.y !== null && selectedData.x === timestamp
                drawCircle(isCircleValue, circle.id, xAxisElement.xScale(selectedData?.x), yScale(selectedData?.y))

            })
        })
    }

    const drawCircle = (isCircleValue, circleId, xValue, yValue) => {
        // move circle if data exists hide if no data
        isCircleValue
            ? svg.select(`#${circleId}`).attr('transform', `translate(${xValue},${yValue})`).style('opacity', 1)
            : svg.select(`#${circleId}`).style('opacity', 0)
    }
    //Handle incoming live data
    const updateSeriesDataWithIncomingLiveData = (incomingLiveData) => {
        setSeriesData((prev) => {
            const { subscription_id, graphData: incomingGraphData } = incomingLiveData;
            let previousMeasureData = prev.find(sd => sd.job_id + sd.measure + graph_id === subscription_id)

            if (previousMeasureData) {

                const measureIndex = prev.indexOf(previousMeasureData);
                const sortedIncomingGraphData = incomingGraphData.sort((a, b) => a.x - b.x)

                const newEnd = Math.round(sortedIncomingGraphData.at(-1).x / 1000) * 1000;
                const newStart = newEnd - graphInterval;

                const filteredGraphData = previousMeasureData.graphData
                    .filter(existingGDP => (existingGDP.x > newStart)
                        && (!incomingGraphData.some(iGDP => iGDP.x === existingGDP.x)));

                const updatedGraphData = filteredGraphData.concat(sortedIncomingGraphData);

                const newDt = updatedGraphData.map(gd => gd.x);
                const newValues = updatedGraphData.map(gd => gd.y);
                const newMeasureData = { ...previousMeasureData, dt: newDt, values: newValues, graphData: updatedGraphData }

                const newSeriesData = [...prev];
                newSeriesData[measureIndex] = newMeasureData;

                return newSeriesData;
            }
            return prev;
        })
    }

    const debounce = (fn, timeout) => {
        let timeoutID = -1
        return () => {
            timeoutID > -1
                ? window.clearTimeout(timeoutID)
                : timeoutID = window.setTimeout(fn, timeout)
        }
    }

    window.onresize = debounce(() => drawGraph(configuration), 500)
    const drawGraph = (configuration) => {
        if (isMouseDown) return;
        // clear svg to redraw
        const svgEl = d3.select(graphRef.current);
        svgEl.selectAll('*').remove();


        const svgNode = svgEl
            .append('svg')
            .attr('id', `svg-${graph_id}`)
            .attr('height', graphHeight)
            .attr('width', graphWidthMinusBuffer)
            .attr('style', `background: ${isDark ? 'black': 'white'} ; overflow: visible;`)

        svgNode
            .append('clipPath')
            .attr('id', `lines-clipPath-${graph_id}`)
            .append('rect')
            .attr('height', '100%')
            .attr('width', '100%')
            .attr('x', '0')
            .attr('y', '0')

        svgNode
            .append('clipPath')
            .attr('id', `circle-clipPath-${graph_id}`)
            .append('rect')
            .attr('height', '100%')
            .attr('width', '102%')
            .attr('x', '-1%')
            .attr('y', '0')

        const { xAxis, xScale } = drawXAxis(seriesData, graphWidthMinusBuffer, xAxisRef, configuration.xScaleDomain)
        setXAxisElement({ xAxis, xScale })

        const yAxes = drawYAxes(configuration.axes, seriesData, xScale, graphHeight, yAxisRefArr, graph_id, yAxesZoomValues)
        setYAxes(yAxes)

        const brushUpdateAxes = (xAxisStartEnd, yAxisEndStart) => {

            const newXScaleDomain = xAxisStartEnd.map(e => +xScale.invert(e))

            yAxes.forEach(axis => {
                const { ordinal } = axis[0];
                const parameterMinMaxes = axis.map(parameter => {
                    const { yScale } = parameter
                    return yAxisEndStart.map(e => +yScale.invert(e))
                })
                const axisMinMax = d3.extent(parameterMinMaxes.flat(), d => d)

                setYAxesZoomValues(prev => ({ ...prev, [graph_id]: { ...prev[graph_id], [ordinal]: getMarginedExtent(axisMinMax) } }))

            })
            setXDisplayDomain(newXScaleDomain);
        }

        const svg = d3.select(`#svg-${graph_id}`)
        const brush = d3.brush()
            .extent([[0, 0], [graphWidthMinusBuffer, graphHeight]])
            .on('brush', () => {
                onStartBrush()
                setDrawLineProps((currentParams) => ({ ...currentParams, displayLine: false }))
            })
            .on('end', async (e) => {
                if (e.selection) {
                    // get new data within selected area
                    const brushArea = e.selection.flat(); // x start, y start, x end, y end
                    const [xStart, yStart, xEnd, yEnd] = brushArea;
                    const xAxisStartEnd = [xStart, xEnd];
                    const yAxisEndStart = [yEnd, yStart];
                    brushUpdateAxes(xAxisStartEnd, yAxisEndStart)
                }
            });

        svg.append('g').attr('id', `brush-${graph_id}`).call(brush)

        setIsLoading(false);
        //setDrawLineProps((props) => ({...props}))
        //*NOTE* Directly calling drawLine does not render as expected      
        isMouseOverFrozen || (sharedMouseOver.graphId !== graph_id) ?
            setDrawLineProps((props) => ({ ...props })) :
            drawLineAtMouse(currentMouseClient);
    };

    const xScaleOneDay = () => {
        const { xScale } = xAxisElement;
        if (xScale.length !== 2) return <></>;
        const [start, end] = xScale.domain();
        const isSameDay = (
            start.getDate() === end.getDate()
            && start.getMonth() === end.getMonth()
            && start.getFullYear() === end.getFullYear()
        )
        return isSameDay
            ? <div style={{ fontSize: '12px', display: 'flex', justifyContent: 'center' }}>{start.toLocaleDateString()}</div>
            : <></>
    }

    const clearLine = () => {
        if (xAxisElement.xScale) {
            setDrawLineProps({ displayLine: false, dataPoint: graph_id, timestamp: null });
        }
    }
    const drawLineAtMouse = (clientX) => {
        if (mouseIsOnBufferArea(clientX)) return

        //Calculate line placement
        const left = (document.getElementById(`graph-${graph_id}`)).getBoundingClientRect()?.left
        const mouseX = clientX - (left + ON_HOVER_CIRCLE_RADIUS)
        if (!xAxisElement.xScale) return;
        const x0 = xAxisElement.xScale.invert(mouseX)
        const bisectRight = d3.bisector(d => d.x).right;

        //Calculate timestamp at mouse
        const longestGraphData = seriesData.sort((a, b) => b.graphData.length - a.graphData.length)[0].graphData
        var i = bisectRight(longestGraphData, x0);
        var timestamp = +longestGraphData[i]?.x;

        if (sharedMouseOver.graphId === graph_id) {
            setSharedMouseOver((currentParams) => ({ ...currentParams, timestamp: timestamp }))
        }
        if (!isMouseOverFrozen) {
            setDrawLineProps((currentParams) => ({
                ...currentParams,
                timestamp: timestamp,
                displayLine: true
            }))
        }
    }

    //function to be used for determining if the mouse location is 
    //inside of the left or right buffer area of the graph
    const mouseIsOnBufferArea = (mouseX) => {
        const left = (document.getElementById(`graph-${graph_id}`)).getBoundingClientRect()?.left
        const right = (document.getElementById(`graph-${graph_id}`)).getBoundingClientRect()?.right
        return ((mouseX < (left + ON_HOVER_CIRCLE_RADIUS)) || (mouseX > (right - ON_HOVER_CIRCLE_RADIUS)))
    }

    return <>
        <div className='chartContainer'>
            <div style={{ 'display': 'inline' }}>
                <div className='yAxisContainerLeft'>
                    {yAxisArrLeft.map((el, idx) => {
                        let axis = configuration.axes.find(a => a.ordinal === el)
                        return axis?.parameters.length > 0
                            ? <div ref={yAxisRefArr[el]} key={`yAxisLeft-${idx}`}></div>
                            : <div style={{ display: 'none' }} key={`yAxisLeft-${idx}`}></div>
                    })}
                </div>
                <div className='yAxisContainerLeft'>
                    {yAxisArrLeft.map((y, idx) => {
                        let axis = configuration.axes.find(a => a.ordinal === y);
                        return axis?.parameters.length > 0
                            ? <div style={{ 'width': '40px', 'color': `${axis.color}` }} key={`yAxisLeftLabel-${idx}`}>{y + 1}</div>
                            : <div style={{ display: 'none' }} key={`yAxisLabelLeft-${idx}`}></div>
                    }
                    )}
                </div>
            </div>

            <div style={{ width: graphWidth }}>
                <div ref={graphRef}
                    className='d3Graph'
                    id={`graph-${graph_id}`}
                    onClick={() => {
                        setIsMouseOverFrozen(!isMouseOverFrozen)
                        setMouseDown(false)
                    }}
                    onMouseEnter={({ clientX }) => {
                        setSharedMouseOver((previous) => ({ ...previous, graphId: graph_id }))

                        //dont actually render if we are hovering the left or right buffer area
                        if (!mouseIsOnBufferArea(clientX)) {
                            setDrawLineProps((currentParams) => ({ ...currentParams, displayLine: true }))
                        }
                    }}
                    onMouseLeave={() => {
                        if (!isMouseOverFrozen) {
                            clearLine()
                            setSelectedTimestamp(null)
                        }
                        setSharedMouseOver((previous) => ({ ...previous, graphId: null, timestamp: null }))
                        setMouseDown(false)
                    }}
                    onMouseMove={({ clientX }) => {
                        if (mouseIsOnBufferArea(clientX)) {
                            if (!isMouseOverFrozen) {
                                clearLine()
                                setSelectedTimestamp(null)
                            }
                        } else {
                            setCurrentMouseClient(clientX)
                            drawLineAtMouse(clientX)
                        }
                    }}
                />
                <div ref={xAxisRef}></div>
                {!isLoading && xScaleOneDay()}
            </div>
            <div style={{ 'display': 'inline' }}>
                <div className='yAxisContainerRight'>
                    {yAxisArrRight.map((el, idx) => {
                        let axis = configuration.axes.find(a => a.ordinal === el)
                        return axis?.parameters.length > 0
                            ? <div ref={yAxisRefArr[el]} key={`yAxisRight-${idx}`}></div>
                            : <div style={{ display: 'none' }} key={`yAxisRight-${idx}`}></div>
                    })}
                </div>
                <div className='yAxisContainerRight'>
                    {yAxisArrRight.map((y, idx) => {
                        let axis = configuration.axes.find(a => a.ordinal === y);
                        return axis?.parameters.length > 0
                            ? <div style={{ 'width': '40px', 'color': `${axis.color}` }} key={`yAxisRightLabel-${idx}`}>{y + 1}</div>
                            : <div style={{ display: 'none' }} key={`yAxisLabelRight-${idx}`}></div>
                    }
                    )}
                </div>
            </div>
        </div>
    </>
}
