import React from 'react'
import reactCSS from 'reactcss'
import { handleFocus } from './Interaction'

import { Checkboard } from './Checkboard'

const ENTER = 13

export const Swatch = ({ color, style, number, onClick = () => { }, onHover, title = color,
  children, focus, focusStyle = {} }) => {
  const transparent = color === 'transparent'
  const styles = reactCSS({
    default: {
      swatch: {
        background: color,
        height: '100%',
        width: '100%',
        cursor: 'pointer',
        position: 'relative',
        outline: 'none',
        ...style,
        ...(focus ? focusStyle : {}),
      },
    },
  })

  const handleClick = e => {
    e.stopPropagation()
    onClick(color, console.log(e))
  }
  const handleKeyDown = e => e.keyCode === ENTER && onClick(color, e)
  const handleHover = e => onHover(color, e)

  const optionalEvents = {}
  if (onHover) {
    optionalEvents.onMouseOver = handleHover
  }

  return (
    <div
      style={styles.swatch}
      onClick={handleClick}
      title={title}
      tabIndex={0}
      onKeyDown={handleKeyDown}
      {...optionalEvents}
    >
      <div style={{ color: 'white', paddingLeft: '10px', paddingTop: '4px', fontsize: '12px', fontWeight: 'bolder' }}>
        {number}
      </div>
      {transparent && (
        <Checkboard
          borderRadius={styles.swatch.borderRadius}
          boxShadow="inset 0 0 0 1px rgba(0,0,0,0.1)"
        />
      )}
    </div>
  )
}

export default handleFocus(Swatch)