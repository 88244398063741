import * as d3 from "d3";
import {
    getMarginedExtent,
    formatTickLabel,
    ON_HOVER_CIRCLE_RADIUS,
    roundToThousandthsPlace,
} from "./Helpers";

const HALF_MAX_AXES = 4;
const Y_AXIS_WIDTH = 41;

export const drawYAxes = (axes, seriesData, xScale, height, yAxisRefArr, graph_id, yAxesZoomValues) => {
    return axes.map((a, idx) => {
        let axisMeasures = seriesData.filter(sd => sd.yAxisOrdinal === a.ordinal);
        return drawYAxis(a, idx, axisMeasures, xScale, height, yAxisRefArr[a.ordinal], graph_id, yAxesZoomValues);
    }).filter(a => a.length > 0);
}


const drawYAxis = (axis, axisIdx, axisMeasures, xScale, height, yAxisRef, graph_id, yAxesZoomValues) => {
    const { ordinal, color, unit_of_measure, min_value, max_value } = axis;
    // clear y axis to redraw
    const yAxisEl = d3.select(yAxisRef.current);

    yAxisEl.selectAll('*').remove();

    const zoomLevel = yAxesZoomValues[graph_id]?.[ordinal];

    const unmarginedExtent = (min_value !== null && max_value !== null)
        ? [+min_value, +max_value]
        : d3.extent(axisMeasures.map(am => am.values).flat(), d => d);

    const extent = zoomLevel || getMarginedExtent(unmarginedExtent)

    const yScale = d3.scaleLinear().domain(extent.map(e => roundToThousandthsPlace(e, 0))).range([height, 0]);

    const d3Axis = ordinal < HALF_MAX_AXES ? d3.axisLeft(yScale) : d3.axisRight(yScale);

    d3Axis.tickSize(2).tickSizeOuter(0).tickFormat(t => formatTickLabel(t))

    // initialize y axis element
    const yAxis = yAxisEl
        .append('svg')
        .attr('height', height)
        .attr('width', Y_AXIS_WIDTH)
        .append("g")
        .style("font-size", "7px")
        .attr('transform', ordinal < 4 ? `translate(40,0)` : `translate(0, 0)`)
        .attr("stroke", color)
        .call(d3Axis)

    return axisMeasures.map((am, i) => {
        const { job_id, dataset_name, measure, graphData } = am;

        const svg = d3.select(`#svg-${graph_id}`)

        const clipPathTarget = `url(#lines-clipPath-${graph_id})`
        const line = svg.append('svg:g').attr('class', 'lines').attr('clip-path', clipPathTarget)

        const circleClipPathTarget = `url(#circle-clipPath-${graph_id})`
        const circle = svg.append('svg:g').attr('class', 'circle').attr('clip-path', circleClipPathTarget)

        const lineId = `line_${graph_id}_${axisIdx}_${i}`;
        const circleId = `circle_${graph_id}_${axisIdx}_${i}`;
        const lineGenerator = d3.line().x((d) => xScale(d.x)).y((d) => yScale(d.y)).defined(d => d.y !== null)

        // draw line
        line.append("path")
            .datum(graphData)
            .attr("id", lineId)
            .attr("class", 'line')
            .attr("fill", "none")
            .attr("stroke", color)
            .attr("stroke-width", 1.5)
            .attr("d", lineGenerator)

        //Add data tracking circle
        circle.append("circle")
            .attr("id", circleId)
            .attr("class", `circle-${graph_id}`)
            .attr("r", ON_HOVER_CIRCLE_RADIUS)
            .style("fill", color)
            .style("stroke", color)
            .style("stroke-width", "1px")
            .style("opacity", "0")
            .attr('transform', `translate(-10,0)`) // move circle off svg for legend freeze

        return {
            lineId, circleId,
            graph_id, graphData,
            lineGenerator,
            yScale, yAxis,
            color, ordinal,
            job_id, job_name: dataset_name,
            measure, unit_of_measure,
        };

    })
}