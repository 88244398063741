import React from 'react';
import { Row, Col, TabContent, TabPane, Nav, NavLink, NavItem } from 'reactstrap';
import classnames from 'classnames';
import * as restAPI from '../../../../common/utilities/ApiService';
import AlertListTab from './Components/AlertListTab';
import ContactGroupTab from './Components/ContactGroupTab';
import AlertTemplatesTab from './Components/AlertTemplatesTab';
import AlertTriggerLogTab from './Components/AlertTriggerLogTab';
import LoginStore from '../../../../common/modules/Auth/LoginStore';


export default class Alerts extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            allJobs: [],
            availableUsers: [],

            alerts: [],
            alert_templates: [],
            contactGroups: [],

            jobsLoaded: false,
            usersLoaded: false,

            triggers: [],

            activeTab: '1'
        };

        this.loadAlerts = this.loadAlerts.bind(this);
        this.loadContactGroups = this.loadContactGroups.bind(this);
        this.loadAlertTriggers = this.loadAlertTriggers.bind(this);
        this.loadAlertTemplates = this.loadAlertTemplates.bind(this);
        this.selectTab = this.selectTab.bind(this);
    }

    async componentDidMount() {
        await restAPI.get('/api/1/jobs?__v=2').then(res => {
            this.setState({
                allJobs: res,
                jobsLoaded: true
            });
        });

        await restAPI.get('/api/1/users?orgFilter=1').then(res => {
            this.setState({
                availableUsers: res.users,
                usersLoaded: true
            });
        });


        this.loadAlerts();
        this.loadContactGroups();
        this.loadAlertTriggers();
        this.loadAlertTemplates();
    }

    /**
     * Function allows for alerts to be reloaded from inside of other components
     */
    async loadAlerts() {
        await restAPI.get('/api/1/wells/alerts').then(res => {
            this.setState({ alerts: res.alerts });
        })
    }

    async loadContactGroups() {
        await restAPI.get('/api/1/wells/alertsGroup?userId=' + LoginStore.user.id).then(res => {
            this.setState({ contactGroups: res });
        })
    }

    async loadAlertTriggers() {
        await restAPI.get('/api/1/wells/triggers').then(res => {
            this.setState({ triggers: res.triggers });
        });
    }

    async loadAlertTemplates() {
        await restAPI.get('/api/1/wells/alert_templates').then(res => {
            this.setState({ alert_templates: res.alerts })
        })
    }

    selectTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    render() {
        return (
            <>
                <Row>
                    <Col md={12}>
                        <div className="card card-default">
                            <Nav tabs>
                                <NavItem>
                                    <NavLink className={classnames({ active: this.state.activeTab === '1' })}
                                        onClick={() => { this.selectTab('1'); }}>
                                        Alerts
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ active: this.state.activeTab === '2' })}
                                        onClick={() => { this.selectTab('2'); }}>
                                        Contact Groups
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ active: this.state.activeTab === '3' })}
                                        onClick={() => { this.selectTab('3'); }}>
                                        Alert Templates
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ active: this.state.activeTab === '4' })}
                                        onClick={() => { this.selectTab('4'); }}>
                                        Alert Trigger Log
                                    </NavLink>
                                </NavItem>
                            </Nav>

                            <TabContent activeTab={this.state.activeTab} style={{ padding: 0, marginTop: 0 }}>
                                <TabPane tabId="1">
                                    <AlertListTab
                                        alerts={this.state.alerts}
                                        contactGroups={this.state.contactGroups}
                                        refreshAlerts={this.loadAlerts}
                                        availableJobs={this.state.allJobs}
                                        availableUsers={this.state.availableUsers}
                                        formReady={(this.state.jobsLoaded && this.state.usersLoaded)}
                                    />
                                </TabPane>
                                <TabPane tabId="2">
                                    <ContactGroupTab
                                        alerts={this.state.alerts}
                                        contactGroups={this.state.contactGroups}
                                        refreshAlerts={this.loadAlerts}
                                        refreshContactGroups={this.loadContactGroups}
                                        availableJobs={this.state.allJobs}
                                        availableUsers={this.state.availableUsers}
                                        formReady={(this.state.jobsLoaded && this.state.usersLoaded)}
                                    />
                                </TabPane>
                                <TabPane tabId="3">
                                    <AlertTemplatesTab
                                        alert_templates={this.state.alert_templates}
                                        refreshAlerts={this.loadAlerts}
                                        refreshTemplates={this.loadAlertTemplates}
                                        availableJobs={this.state.allJobs}
                                        availableUsers={this.state.availableUsers}
                                        formReady={(this.state.jobsLoaded && this.state.usersLoaded)}
                                    />
                                </TabPane>
                                <TabPane tabId="4">
                                    <AlertTriggerLogTab
                                        triggers={this.state.triggers}
                                        refreshTriggers={this.loadAlertTriggers}
                                    />
                                </TabPane>
                            </TabContent>
                        </div>
                    </Col>
                </Row>

            </>
        );
    }
}