import React, { useState } from 'react'
import { useContextMenu } from 'react-contexify'
import {
    Row,
    Col,
} from 'reactstrap'
import { WidgetTileContextMenu } from "./D3Elements/WidgetTileContextMenu"
import { Widget } from '../Elements/Widget'
import { IconButton } from '../Core/Shared'
import { DeletionModal } from './DeletionModal'

export const WidgetTile = ({ tile, canEdit, jobSelectOptions, updateWidgetTile, removeTile, rowOrdinal }) => {
    const { widgets = [] } = tile
    const widgetCount = (widgets.length) || 0

    const DEFAULT_NEW_WIDGET_STATE = {
        ordinal: widgetCount
    }

    const getWidgetSize = (widgetOrdinal) => {
        const isTheOnlyWidgetInItsRow = widgetCount === 1 || (widgetCount === 4 && widgetOrdinal === 3)
        return isTheOnlyWidgetInItsRow ? 8 : 4
    }
    const newWidgetButtonSize = [0, 3].includes(widgetCount) ? 12 : 4

    const [isTileDeletionModalOpen, setIsTileDeletionModalOpen] = useState(false)

    const tile_id = tile?.id || `r${rowOrdinal}t${tile.ordinal}`
    // const tile_id = {
    //     id: tile?.id,
    //     ordinal: tile.ordinal,
    //     display: tile?.id || `r${rowOrdinal}t${tile.ordinal}`
    // }

    const addWidget = () => {
        const newTileState = {
            ...tile,
            widgets: [...widgets, DEFAULT_NEW_WIDGET_STATE]
        }
        updateWidgetTile(newTileState)
    }
    const removeWidget = (targetWidgetIndex) => {
        const newWidgetState = widgets.filter((widget, index) => index !== targetWidgetIndex);
        const newTileState = {
            ...tile,
            widgets: newWidgetState
        };
        console.log(widgets, newWidgetState, targetWidgetIndex)
        updateWidgetTile(newTileState);
    }

    const updateWidget = (targetWidgetIndex, newWidgetData) => {
        const updatedWidget = {
            ...widgets[targetWidgetIndex],
            ...newWidgetData
        }
        const newWidgetState = [...widgets]
        newWidgetState[targetWidgetIndex] = updatedWidget

        const newTileState = {
            ...tile,
            widgets: newWidgetState
        }

        updateWidgetTile(newTileState)
    }

    const { show } = useContextMenu({ id: `rightClickMenu-${tile_id}` })

    const confirmTileDeletion = () => {
        setIsTileDeletionModalOpen(false)
        removeTile(tile)
    }

    const widgetHeight = widgetCount >= 3 ? '50%' : '100%'

    return <>
        <Row style={{ height: '100%' }} onContextMenu={show}>
            {widgets.map((w, idx) => <Col key={`widget-${tile.ordinal}${w.ordinal}-${w.id ?? 'new'}`} xl={getWidgetSize(w.ordinal)} style={{ height: widgetHeight, padding: 0 }}>
                <Widget
                    widget={w}
                    canEdit={canEdit}
                    removeWidget={() => removeWidget(idx)}
                    jobId={w.target_job_id} datasetField={w.target_dataset_field}
                    widget_id={w.id ?? `${rowOrdinal}-${tile.ordinal}-${w.ordinal}`}
                    jobSelectOptions={jobSelectOptions} updateWidget={(newState) => updateWidget(idx, newState)}
                />
            </Col>
            )}
            {canEdit && widgetCount < 6 &&
                <Col xl={newWidgetButtonSize} style={{ height: widgetHeight, display: 'flex' }}>
                    <IconButton
                        icon='fa-plus-circle'
                        style={{ fontSize: '2.4em', margin: 'auto', color: '#164787', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                        onClick={addWidget}
                    />
                </Col>
            }

        </Row>
        <WidgetTileContextMenu
            tile_id={tile_id}
            canEdit={canEdit}
            openDeletionModal={() => setIsTileDeletionModalOpen(true)}
        />
        <DeletionModal
            onSubmit={confirmTileDeletion}
            isOpen={isTileDeletionModalOpen}
            setIsOpen={setIsTileDeletionModalOpen}
            entityDisplayIdentifier={`Tile Id: ${tile_id}`}
            entityDisplayType='widget tile'
        />
    </>
}
