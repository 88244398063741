import React, { useEffect, useState } from 'react'
import { DndTypes } from '../../../../common/utilities/dragTypes'
import { useDrag } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'
import { Badge as StrapBadge, Popover, UncontrolledPopover, PopoverBody, PopoverHeader } from 'reactstrap'
import { CustomDragLayer } from './CustomDragLayer'


//Dragable Item (i.e. JobParameter as located on a job)
export const DraggableJobParameter = ({ parameter, ...props }) => {
    const controlId = `JobBadge-${parameter.target_job_id}-${parameter.target_dataset_field}`;

    const [{ isDragging }, drag, preview] = useDrag(() => ({
        type: DndTypes.JOBPARAMETER,
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging()
        }),
        item: parameter,
    }))

    useEffect(() => {
        preview(getEmptyImage(), { captureDraggingState: true })
    }, [])
    return (
        <>
            <div ref={drag}
                style={{
                    opacity: isDragging ? 0.5 : 1,
                    cursor: 'move',
                    display: 'inline',
                    margin: '.5em'
                }}>
                <StrapBadge {...props} id={controlId} color={isDragging ? 'primary' : 'info'}
                    style={{
                        opacity: isDragging ? 0.5 : 1
                    }}>

                    {parameter.target_dataset_field}
                </StrapBadge>
            </div>
            <UncontrolledPopover
                placement="right"
                target={controlId}
                trigger="click">
                <PopoverHeader>
                    <p>Job: {parameter.job_name} </p>
                    <p>Parameter: {parameter.target_dataset_field} </p>
                </PopoverHeader>
                <PopoverBody>
                    Drag this parameter to the desired Y Axis to add it to the graph
                </PopoverBody>
            </UncontrolledPopover>
            <CustomDragLayer type={DndTypes.JOBPARAMETER} draggedItem={parameter}>
                <DraggingJobParameter parameter={parameter} />
            </CustomDragLayer>
        </>
    )


}
//Item in Motion
export const DraggingJobParameter = ({ parameter, ...props }) => {
    const controlId = `DragBadge-${parameter.target_job_id}-${parameter.target_dataset_field}`
    return (
        <div>
            <StrapBadge {...props} id={controlId} color='warning'>
                {parameter.job_name} - {parameter.target_dataset_field}
            </StrapBadge>
        </div>
    )
}

//YAxis Item (i.e. JobParameter as located on the YAxis)
export const YAxisJobParameter = ({ parameter, onRemove, ...props }) => {
    const [isPopoverShowing, setIsPopoverShowing] = useState(false);
    const controlId = `Badge-${parameter.target_job_id}-${parameter.target_dataset_field}`;

    const togglePopover = (id) => {
        controlId === id && setIsPopoverShowing(!isPopoverShowing)
    }

    const removeParameter = () => {
        onRemove();
        setIsPopoverShowing(false);
    }

    return (
        <div style={{
            padding: '5px'
        }}>
            <StrapBadge {...props} id={controlId} color='info' onClick={(e) => togglePopover(e.target.id)}>
                {parameter.job_name} - {parameter.target_dataset_field}
                <div onClick={removeParameter}
                    style={{
                        fontWeight: 'bold',
                        color: 'red',
                        display: 'inline-block',
                        cursor: 'pointer',
                        borderStyle: 'solid',
                        borderWidth: '1px',
                        paddingLeft: '3px',
                        paddingRight: '3px',
                        paddingTop: '3px',
                        marginLeft: '3px'
                    }}> X </div>
            </StrapBadge>
            <Popover
                placement="bottom"
                target={controlId}
                toggle={togglePopover}
                isOpen={isPopoverShowing}
            >
                <PopoverHeader>
                    <p>Job: {parameter.job_name} </p>
                    <p>Parameter: {parameter.target_dataset_field} </p>
                </PopoverHeader>
                <PopoverBody>
                    Insert Measure information here -Measure and UoM, etc-
                </PopoverBody>
            </Popover>
        </div>
    )
}