import './dashboarding.css';
import '../Elements/_elements.css';
import 'react-table/react-table.css';
import { Row, Col } from 'reactstrap';
import 'react-contexify/dist/ReactContexify.css';
import React, { useState, useEffect, useContext } from 'react';
import { DashboardRow } from '../Elements/DashboardRow';
import { NameMatchOverrideModal } from './NameMatchOverrideModal';
import * as restAPI from '../../../../common/utilities/ApiService';
import * as axiosRestAPI from '../../legacy/utilities/axios-service'
import { Button, IconButton, SuccessButton } from '../Core/Shared';
import { FormCheckboxInput } from '../Core/FormInputs';
import { AddSharedDashboardModal } from '../Elements/AddSharedDashboardModal';
import { DashboardingFormContext } from '../../Contexts/DashboardingFormContext';
import { H2, EditableText } from "@blueprintjs/core";
import '@blueprintjs/core/lib/css/blueprint.css';
// //hidingLiveData
// import Switch from '../Elements/Switch';

const hc = { size: 6 } //headerColumn

export default ({ canEdit, formState, updateFieldValue, saveDashboard,
    promptNameMatchOverride = false, setPromptNameMatchOverride, saveDashboardWithNameMatchOverride }) => {
    const [all_jobs, set_all_jobs] = useState([])
    const [x_axis_units_of_measure, set_x_axis_units_of_measure] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false)

    const { selectedGraphId, linkedGroupSharedValues, setValueForLinkedGroup, setSelectedGraphId } = useContext(DashboardingFormContext)

    document.addEventListener('click', (event) => {
        const composedPath = event.composedPath()

        const clickedElementType = typeof event?.target?.className;

        const isDashboardArrow = clickedElementType === 'string'
            ? event?.target?.className?.includes('dashboardArrow')
            : false;

        const graphTile = composedPath.find(el => el.className === 'graphTileContainer col')
        const graphId = graphTile
            ? graphTile.id.startsWith('r') // unsaved graph ie r0t0 str
                ? graphTile.id
                : +graphTile.id // saved graph ie 68 as num
            : null;

        !isDashboardArrow && setSelectedGraphId(graphId)
    })

    useEffect(() => {
        (async () => {
            await restAPI.get('/api/1/dashboarding/axes/units_of_measure')
                .then(res => {
                    const { x_axis_units_of_measure } = res;

                    x_axis_units_of_measure.forEach(unit => {
                        unit.label = `${unit.unit_name} (${unit.unit_abbreviation})`
                        unit.value = unit.unit_abbreviation
                    });

                    set_x_axis_units_of_measure(x_axis_units_of_measure);
                });

            await restAPI.get('/api/1/jobs?__v=2').then(res => {
                set_all_jobs(res);
            });
        })()
    }, [])


    useEffect(() => {
        if (!formState.formMeta_isLoading) {
            updateLinkedGroupSharedValues();
        }
    }, [formState.formMeta_isLoading])

    const updateLinkedGroupSharedValues = () => {
        // get all graphs with linked_group
        const linkedGroupGraphs = formState?.rows?.flatMap(r => r.tiles.flatMap(t => t.graph)).filter(dg => dg?.linked_group);
        // loop through linked group shared values
        linkedGroupSharedValues.forEach(async (lg, i) => {
            const linkedGroupId = i + 1
            // find first graph with matching id
            const firstLinkedGroupGraph = linkedGroupGraphs.find(dg => dg.linked_group === linkedGroupId);
            if (!firstLinkedGroupGraph && lg?.length) {
                // reset linked group values, if necessary
                setValueForLinkedGroup(linkedGroupId, []);
            } else if (firstLinkedGroupGraph && !lg?.length) {
                const { id, temp_id, time_amount, unit_of_time } = firstLinkedGroupGraph;
                const timeWindow = time_amount * unit_of_time?.duration * 1000;
                if (id) {
                    // notify backend if x scale domain is defined
                    const params = { isXScaleDefined: !isNaN(timeWindow), timeWindow }
                    const xScaleDomain = firstLinkedGroupGraph?.xScaleDomain?.length > 0
                        ? firstLinkedGroupGraph?.xScaleDomain
                        : await axiosRestAPI.get(`/api/1/dashboarding/linked_group/${id}`, params);
                    setValueForLinkedGroup(linkedGroupId, xScaleDomain);
                } else if (temp_id) {
                    if (firstLinkedGroupGraph?.xScaleDomain?.length > 0) {
                        setValueForLinkedGroup(linkedGroupId, firstLinkedGroupGraph?.xScaleDomain);
                    } else {
                        const unsavedParameters = firstLinkedGroupGraph.axes.flatMap(a => a.parameters)
                            .map(p => `${p.target_dataset_field}/${p.target_job_id}`);

                        const lastStreamedArr = await Promise.all(unsavedParameters.map(up => restAPI.get(`/api/1/dashboarding/widget/latest/${up}`)))
                        const lastStreamed = Math.max(...lastStreamedArr.map(ls => ls.influxData.tmstamp))

                        let xScaleDomain = [null, lastStreamed]

                        if (!isNaN(timeWindow)) {
                            xScaleDomain[0] = lastStreamed - timeWindow;
                        } else {
                            const firstStreamedArr = await Promise.all(unsavedParameters.map(up => restAPI.get(`/api/1/dashboarding/widget/first/${up}`)))
                            xScaleDomain[0] = Math.min(...firstStreamedArr.map(fs => fs.influxData.tmstamp));
                        }

                        setValueForLinkedGroup(linkedGroupId, xScaleDomain);
                    }
                }
            }
        });
    };

    const jobSelectOptions = all_jobs.map(job_group => {
        return {
            label: job_group.name,
            value: job_group.id,
            options: job_group.jobs.map((dataset) => {
                return {
                    jobId: dataset.id,
                    key: dataset.name,
                    label: dataset.display_name,
                    value: dataset.id,
                    dataset_measures: dataset.dataset_measures,
                    dataset_id: dataset.dataset_id,
                    latest_value_ts: dataset.dataset_latest_value_ts,
                    dmaplive_dataset_id: dataset.dmaplive_dataset_id
                }
            })
        }
    })

    const determineNewRowOrdinal = () => {
        const currentLastRowOrdinal = formState.rows.at(-1)?.ordinal ?? 0
        const ordinalForNewRow = currentLastRowOrdinal + 1
        return ordinalForNewRow
    }

    const addNewRow = () => {
        const newRowsCollection = [...formState.rows] || []
        const newRow = {
            dashboard_id: formState.id,
            ordinal: determineNewRowOrdinal(),
            tiles: []
        }
        newRowsCollection.push(newRow)
        updateFieldValue('rows', newRowsCollection)
    }

    const updateRow = (newRowState) => {
        const rows = [...formState.rows] || []
        const target = rows.findIndex(r => (!newRowState.id && r.ordinal === newRowState.ordinal) || (r.id && r.id === newRowState.id))
        rows[target] = newRowState
        updateFieldValue('rows', rows)
    }

    const getDataTimeWindow = (direction) => {
        const configOfSelectedGraph = formState?.rows.find(r => r?.tiles?.some(t => t?.graph?.temp_id === selectedGraphId))
            ?.tiles.find(t => t.graph.temp_id === selectedGraphId)
            .graph

        const { time_amount, unit_of_time, firstStreamed, lastStreamed, xScaleDomain } = configOfSelectedGraph

        const xScaleTimeWindow = xScaleDomain
            ? xScaleDomain[1] - xScaleDomain[0]
            : time_amount * unit_of_time?.duration * 1000

        const dataStreamTimeWindow = lastStreamed - firstStreamed

        const timeDuration = isNaN(xScaleTimeWindow) ? dataStreamTimeWindow : xScaleTimeWindow
        const timeAdjustment = direction > 0 ? timeDuration : -timeDuration

        // update linked group values that are populated
        linkedGroupSharedValues.forEach((lg, i) => {
            const newLinkedGroupValue = lg.map(value => value + timeAdjustment);
            if (newLinkedGroupValue?.length > 0) {
                setValueForLinkedGroup(i + 1, newLinkedGroupValue);
            }
        })

        const rows = [...formState.rows]
        rows.forEach(r => r.tiles.forEach(t => {
            r.key = Math.random() * 1000 // generate random number for key so react rerenders
            if (t.graph && !t.graph.linked_group) {

                // set data fetch range
                const timeWindow = t.graph.time_amount * t.graph.unit_of_time?.duration * 1000
                const endOfWindow = t.graph.lastStreamed
                const startOfWindow = isNaN(timeWindow) ? t.graph.firstStreamed : endOfWindow - timeWindow

                const newXScaleDomain = t.graph.xScaleDomain
                    ? t.graph.xScaleDomain.map(value => value + timeAdjustment)
                    : [startOfWindow + timeAdjustment, endOfWindow + timeAdjustment]

                t.graph.xScaleDomain = newXScaleDomain
            }
        }));

        updateFieldValue('rows', rows);
    }

    // const getCanGoLive = () => {
    //     return formState?.rows?.some(row =>
    //         row.tiles.some(tile =>
    //             (tile.type_id === 2 && tile.widgets?.length > 0) //is a widget tile with 1+ widgets
    //             || (tile.graph && tile.graph.show_live_data === true) //is a graph tile with streaming enabled
    //         )
    //     ) || false;
    // };
    // //hidingLiveData
    // const canGoLive = getCanGoLive();

    return <form>
        <NameMatchOverrideModal
            matchingName={formState.title}
            isOpen={promptNameMatchOverride}
            setIsOpen={setPromptNameMatchOverride}
            onSubmit={saveDashboardWithNameMatchOverride}
        />
        <Row style={{ margin: '1% 0', display: 'flex', justifyContent: 'center' }}>
            <Col style={{ display: 'flex', justifyContent: 'flex-start', gap: '15px', maxHeight: '40px' }}>
                {canEdit &&
                    <FormCheckboxInput
                        label='Shared'
                        value={formState.is_sharing_enabled}
                        onChange={() => updateFieldValue('is_sharing_enabled', !formState.is_sharing_enabled)}
                    />
                }
                {/*hidingLiveData*/}
                {/*canGoLive &&
                    <Switch
                        large
                        label='Live Data Streaming'
                        value={formState.show_live_data}
                        onChange={() => updateFieldValue('show_live_data', !formState.show_live_data)}
                    />
            */}
            </Col>
            <Col xs={hc} sm={hc} md={hc} lg={hc} xl={hc} style={{ display: 'flex', justifyContent: 'center', flexGrow: '1', maxHeight: '40px', gap: '1em', alignItems: 'center' }}>
                {selectedGraphId &&
                    < IconButton
                        icon='fa-caret-left'
                        className='dashboardArrow'
                        onClick={() => getDataTimeWindow(-1)}
                    />
                }
                <H2 style={{ marginTop: '6px' }}>
                    <EditableText
                        maxLength={75}
                        disabled={!canEdit}
                        value={formState.title}
                        placeholder='Enter Dashboard Title'
                        onChange={(text) => updateFieldValue('title', text)}
                    />
                </H2>
                {selectedGraphId &&
                    <IconButton
                        icon='fa-caret-right'
                        className='dashboardArrow'
                        onClick={() => getDataTimeWindow(1)}
                    />
                }
            </Col>

            <Col style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px', maxHeight: '40px' }}>
                {canEdit && <>
                    <Button leftIcon='fa-plus' text='Add Shared Dashboard' onClick={() => setIsModalOpen(true)} />
                    {isModalOpen && <AddSharedDashboardModal setIsModalOpen={setIsModalOpen} currentRows={formState.rows} updateFieldValue={updateFieldValue} dashboardId={formState?.id} />}
                    <SuccessButton leftIcon='fa-save' text='Save' onClick={() => saveDashboard(formState)} />
                </>}
            </Col>
        </Row>
        {formState?.rows?.map((r, idx) =>
            <DashboardRow key={`dashboardRow-${idx}${r.key}`}
                dashboard_id={formState.id} canEdit={canEdit} rowData={r} updateRow={updateRow}
                jobSelectOptions={jobSelectOptions}
                all_jobs={all_jobs} x_axis_units_of_measure={x_axis_units_of_measure}
                updateFieldValue={updateFieldValue} formState={formState}
            />
        )}
        {canEdit &&
            <Row>
                <Col xs sm md lg xl={{ size: 2, offset: 5 }} style={{ textAlign: 'center' }}>
                    <IconButton
                        icon='fa-plus-square'
                        style={{ fontSize: '5em', margin: '.2em', color: '#114355' }}
                        onClick={addNewRow}
                    />
                </Col>
            </Row>
        }
    </form>
}