import React, { createContext, useState, useRef } from 'react';
import * as restAPI from '../legacy/utilities/axios-service'


export const DataManagementContext = createContext(null)

const getSeriesData = () => {
    const [seriesData, setSeriesData] = useState([])

    const locksRef = useRef({});
    const [pendingRequests, setPendingRequests] = useState({});


    const handlePendingRequests = async (measure_id) => {
        if (pendingRequests[measure_id]) {
        const [nextRequest, ...remainingRequests] = pendingRequests[measure_id];

        setPendingRequests((pending) => { return { ...pending, [measure_id]: remainingRequests }});

        await addMeasure(...nextRequest);
        }
    };

    const addMeasure = async ({measure_id, job_id, measure_name, graph_resolution_id, timeWindow, interval}) => {
        if (locksRef.current[measure_id]) {
            setPendingRequests((prev) => {
                return {...prev,
                [measure_id]: [...(prev[measure_id] || []), [measure_id, job_id, measure_name, graph_resolution_id, timeWindow, interval]],
            }});
            return;
        }
        else {
            locksRef.current[measure_id] = true;
        }

        try {
            const measureIndex = seriesData.findIndex((measure) => measure.measure_id === measure_id);

            if (measureIndex === -1) {

                const lastStreamed = (await restAPI.get(`/api/1/dashboarding/widget/latest/${measure_id}`)).tmstamp;
                const firstStreamed = (await restAPI.get(`/api/1/dashboarding/widget/first/${measure_id}`)).tmstamp;

                // set data fetch range
                const endOfWindow = timeWindow[1] ?? lastStreamed;
                const startOfWindow = timeWindow[0] ?? isNaN(interval) ? firstStreamed : lastStreamed - interval

                const params = { st: startOfWindow, et: endOfWindow, graph_resolution_id }

                const measureData = await restAPI.get(`/api/1/dashboarding/${job_id}/${measure_name}/graph_data`, params);
            
                const newMeasure = {
                    measure_id,
                    firstStreamed,
                    lastStreamed,
                    measureData,
                    timeWindow: [ startOfWindow, endOfWindow ],
                };
                setSeriesData((prev) => {
                    return [...prev, newMeasure];
                });
            }
            else {
                //TODO Handle extending range if necessary
            }

            // Handle any pending requests for this measure
            await handlePendingRequests(measure_id);
        } 
        finally {
            locksRef.current[measure_id] = false;
        }
    }
    return {
        seriesData, setSeriesData, addMeasure
    }
}

export const DataManagementContextProvider = ({ children }) => {
    const { seriesData, setSeriesData, requestSeriesData } = getSeriesData()

    const stateAndHelpersBag = {
        //Series Data
        seriesData,
        setSeriesData, //Don't share this, run everything through request instead

        //Series Data Management Functions
        requestSeriesData,
    }

    return <DataManagementContext.Provider value={stateAndHelpersBag}>
        {children}
    </DataManagementContext.Provider>
}
