import React, { Component } from "react";
import { FormGroup, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select from 'react-select';
import FormElement from "../../Core/Elements/FormElement";
import WellMeasureSelector from "../../Elements/WellMeasureSelector";
import CreatableSelect from 'react-select/lib/Creatable';
import LoginStore from "../../../../../common/modules/Auth/LoginStore";

var conditionTypeList = [
    { value: 1, label: ">" },
    { value: 2, label: ">=" },
    { value: 3, label: "<" },
    { value: 4, label: "<=" },
    { value: 5, label: "Change > x in 1 minute" },
    { value: 6, label: "Change > x in 1 hour" },
]

const components = {
    DropdownIndicator: null
};

const createOption = (label) => ({
    label,
    value: label,
});


export default class AlertModal extends Component {
    constructor(props) {
        super(props);

        // Add current user to list of users if not editing an existing alert.
        var user_id = LoginStore.user.id;
        let selectedUsers = [];
        if (this.props.initialAlertFormValues && this.props.initialAlertFormValues.user_list) {
            
            var sUs = this.props.initialAlertFormValues.user_list.split("|").filter(u => u.length > 0);

            sUs.forEach(sU => {
                this.props.availableUsers.forEach(au => {
                    if (au.id === parseInt(sU)) {
                        selectedUsers.push({
                            key: au.id,
                            label: au.name,
                            value: au.id
                        })
                    }
                })
            });
        } else {
            selectedUsers = this.props.availableUsers.map(user => ({
                key: user.id,
                label: user.name,
                value: user.id
            })).filter(userOption => userOption.key === user_id)
        }

        let selected_condition = null;
        if (this.props.initialAlertFormValues && this.props.initialAlertFormValues.condition) {
            selected_condition = conditionTypeList.filter(ct => ct.value === this.props.initialAlertFormValues.condition)
        }

        let emailList = [];
        if (this.props.initialAlertFormValues && this.props.initialAlertFormValues.email_list) {
            emailList = this.props.initialAlertFormValues.email_list.split("|")
                .filter(e => e.length)
                .map(e => createOption(e));
        }

        let phoneList = [];
        if (this.props.initialAlertFormValues && this.props.initialAlertFormValues.phone_list) {
            phoneList = this.props.initialAlertFormValues.phone_list.split("|")
                .filter(p => p.length)
                .map(p => createOption(p));
        }


        this.state = {
            alert: this.props.initialAlertFormValues,
            workingAlertId: this.props.currentAlertId,
            availableUsers: this.props.availableUsers,
            inputEmail: "",
            emailList: emailList,
            inputPhone: "",
            phoneList: phoneList,
            selectedUsers: selectedUsers,
            selected_condition: selected_condition
        };

        this.handleWellMeasureSelectionChange = this.handleWellMeasureSelectionChange.bind(this);
        this.handleAlertUsersChange = this.handleAlertUsersChange.bind(this);
        this.handleAlertEmailsChange = this.handleAlertEmailsChange.bind(this);
        this.handleAlertPhonesChange = this.handleAlertPhonesChange.bind(this);
        this.handleConditionChange = this.handleConditionChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.saveAlert = this.saveAlert.bind(this);
    }

    handleChange = event => {
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

        let alert = this.state.alert;
        alert[event.target.name] = value;

        this.setState({
            alert: alert
        });
    };

    handleAlertUsersChange(selectedUserArray) {
        this.setState({
            selectedUsers: selectedUserArray
        });
    }

    handleWellMeasureSelectionChange(dataset_id, measure_name, job_id) {
        let alert = this.state.alert;
        alert.job_id = job_id;
        alert.field_name = measure_name;

        this.setState({
            alert: alert
        });
    }

    handleConditionChange(condition) {
        var alert = this.state.alert;
        alert.condition = condition.value;

        this.setState({
            alert: alert,
            selected_condition: condition
        });
    }

    handleAlertEmailsChange = (emailList, actionMeta) => {
        this.setState({ emailList });
    }

    handleAlertEmailsInputChange = (inputEmail) => {
        this.setState({ inputEmail });
    }

    handleAlertEmailsKeyDown = (event) => {
        const { inputEmail, emailList } = this.state;

        if (!inputEmail) return;

        switch (event.key) {
            case 'Enter':
            case 'Tab':
                if (inputEmail) {
                    this.setState({
                        inputEmail: '',
                        emailList: [...emailList, createOption(inputEmail)],
                    });
                }
                event.preventDefault();
                break
            default: break
        }
    }

    handleAlertPhonesChange = (phoneList, actionMeta) => {
        this.setState({ phoneList });
    };

    handleAlertPhonesInputChange = (inputPhone) => {
        this.setState({ inputPhone });
    };

    handleAlertPhonesKeyDown = (event) => {
        const { inputPhone, phoneList } = this.state;
        if (!inputPhone) return;
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                if (inputPhone) {
                    this.setState({
                        inputPhone: '',
                        phoneList: [...phoneList, createOption(inputPhone)],
                    });
                }
                event.preventDefault();
                break
            default: break
        }
    }

    saveAlert = event => {
        let alert = this.state.alert;

        var email_list_string = this.state.emailList.map(ed => {
            return ed.value;
        }).join("|");
        alert.email_list = email_list_string;

        var phone_list_string = this.state.phoneList.map(ed => {
            return ed.value;
        }).join("|");
        alert.phone_list = phone_list_string;

        var user_list_string = this.state.selectedUsers.map(ed => {
            return ed.value;
        }).join("|");
        alert.user_list = user_list_string;

        if (this.props.isTemplate && this.props.isTemplate) {
            this.props.saveTemplate(this.state.workingAlertId, alert);
        } else {
            this.props.saveAlert(this.state.workingAlertId, alert);
        }

    }

    render() {
        // Notes:
        //   - this.props.availableUsers contains the list of availableUsers
        //   - this.props.availableJobs contains the list of availableJobs

        return (
            <Modal isOpen={this.props.show} toggle={this.props.toggleModal} className="modal-lg">
                <ModalHeader toggle={this.props.toggleModal}>
                    {(this.props.isTemplate) ?
                        <>Create/Edit Alert Template</> :
                        <>Create/Edit Alert</>
                    }
                </ModalHeader>

                <ModalBody>
                    <WellMeasureSelector
                        show_inactive={false}
                        jobs={this.props.availableJobs}
                        handleSelectionChange={this.handleWellMeasureSelectionChange}
                        jobId={this.props.initialAlertFormValues.job_id}
                        measure={this.props.initialAlertFormValues.field_name}
                    />

                    <FormGroup row>
                        <label className="col-xl-2 col-form-label">Comparison Type</label>
                        <div className="col-xl-10">
                            <Select
                                className="form-control form-select-element"
                                placeholder="Select field"
                                value={this.state.selected_condition}
                                options={conditionTypeList.map(element => ({
                                    key: element.value,
                                    label: element.label,
                                    value: element.value
                                }))}
                                onChange={this.handleConditionChange} />
                        </div>
                    </FormGroup>

                    <FormElement label="Set Point"
                        placeholder="Set Point Value"
                        value={this.state.alert.set_point_value}
                        name="set_point_value"
                        onChange={this.handleChange} />
                    <FormElement label="Subject"
                        placeholder="Alert Subject (included in alert messages)"
                        value={this.state.alert.subject}
                        name="subject"
                        onChange={this.handleChange} />
                    <FormElement label="Resent Rate (Mins)"
                        placeholder="60"
                        value={this.state.alert.refractory_period}
                        name="refractory_period"
                        onChange={this.handleChange} />
                    <FormGroup row>
                        <label className="col-xl-2 col-form-label">Users</label>
                        <div className="col-xl-10">
                            <Select
                                isMulti
                                className="form-control form-select-element"
                                placeholder="Select users"
                                value={this.state.selectedUsers}
                                options={this.props.availableUsers.map(user => ({
                                    key: user.id,
                                    label: user.name,
                                    value: user.id
                                }))}
                                onChange={this.handleAlertUsersChange} />
                        </div>
                    </FormGroup>
                    <FormGroup row>
                        <label className="col-xl-2 col-form-label">Emails</label>
                        <div className="col-xl-10">
                            <CreatableSelect
                                components={components}
                                inputValue={this.state.inputEmail}
                                isClearable
                                isMulti
                                menuIsOpen={false}
                                onChange={this.handleAlertEmailsChange}
                                onInputChange={this.handleAlertEmailsInputChange}
                                onKeyDown={this.handleAlertEmailsKeyDown}
                                placeholder="Type an email and press enter"
                                value={this.state.emailList}
                            />
                        </div>
                    </FormGroup>
                    <FormGroup row>
                        <label className="col-xl-2 col-form-label">Phone Numbers</label>
                        <div className="col-xl-10">
                            <CreatableSelect
                                components={components}
                                inputValue={this.state.inputPhone}
                                isClearable
                                isMulti
                                menuIsOpen={false}
                                onChange={this.handleAlertPhonesChange}
                                onInputChange={this.handleAlertPhonesInputChange}
                                onKeyDown={this.handleAlertPhonesKeyDown}
                                placeholder="Type a phone number and press enter"
                                value={this.state.phoneList}
                            />
                        </div>
                    </FormGroup>
                </ModalBody>

                <ModalFooter>
                    <Button color="primary" onClick={this.saveAlert} >Save</Button>
                    <Button color="secondary" onClick={this.props.toggleModal}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }

}

