// import request from 'reqwest';
import axios from "axios";
import when from 'when';
// import { LOGIN_URL, SIGNUP_URL } from '../constants/LoginConstants';
import LoginActions from './LoginActions';

// const loginEndpoint = "/api/login";
const loginEndpoint = "/api/1/auth/login"

axios.defaults.baseURL = process.env.API_BASE_URL;
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common.Accept = "application/json";

class AuthService {

    login(username, password) {
        var data = {
            username, password
        };
        
        return this.handleAuth(when(axios.post(loginEndpoint, data)));
    }

    logout() {
        LoginActions.logoutUser();
    }
    
    /*
    signup(username, password, extra) {
        return this.handleAuth(when(request({
            url: SIGNUP_URL,
            method: 'POST',
            crossOrigin: true,
            type: 'json',
            data: {
                username, password, extra
            }
        })));
    }*/


    handleAuth(loginPromise) {
        return loginPromise
            .then(function (response) {
                var encodedUserToken = {
                    token: response.data.token,
                    user: response.data.user
                };
                
                LoginActions.loginUser(JSON.stringify(encodedUserToken));

                return true;
            });
    }
}

export default new AuthService()