
import { Menu, Item } from 'react-contexify'
import React from 'react'

export const WidgetTileContextMenu = ({ 
    tile_id, 
    canEdit,
    openDeletionModal, 
}) => {

    return <>
    <Menu id={`rightClickMenu-${tile_id}`}>
        {tile_id && canEdit &&
            <Item onClick={openDeletionModal}>
                Delete Widget Tile
            </Item>
        }
    </Menu>  
    </>
}