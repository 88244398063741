import React, { Component } from "react";
import { Link, Redirect } from 'react-router-dom'

import LoginRun from './Login.run';

import Auth from '../../modules/Auth/AuthService';
import LoginStore from '../../modules/Auth/LoginStore';

class Login extends Component {
    state = {
        redirectToDashboard: false
    }

    constructor(props, context) {
        super(props, context);

        if (LoginStore.isLoggedIn()) {
            this.state = {
                redirectToDashboard: true
            }
        }
    }
    
    componentDidMount() {
        var componentReference = this;

        LoginRun((form) => {
            var username = this.refs.username.value;
            var password = this.refs.password.value;
            
            Auth.login(username, password)
                .then(function (r) { componentReference.setState({ redirectToDashboard: true }); })
                .catch(function (err) {
                    alert("There's an error logging in");
                    console.log("Error logging in", err);
                });
        });
    }

    render() {
        if (this.state.redirectToDashboard) {
            return <Redirect to='/dashboard' />
        } else {
            return (
                <div className="block-center mt-4 wd-fixed-user-form">
                    <div className="card card-flat" style={{background: "transparent"}}>
                        <div className="card-header text-center">
                            <a href="/">
                                <img className="block-center rounded login-logo" src="img/logo.png" alt="Logo" />
                            </a>
                        </div>

                        <div className="card-body bg-white">
                        
                            <form id="user-login" name="loginForm" noValidate className="mb-3 form-validate">
                                <div className="form-group">
                                    <label htmlFor="username">Email Address</label>
                                    <div className="input-group with-focus">
                                        <input type="text" ref="username" name="username" required="" className="form-control" />

                                        <div className="input-group-append">
                                            <span className="input-group-text fa fa-user text-muted bg-transparent border-left-0"></span>
                                        </div>
                                    </div>

                                    <small id="usernameHelp" className="form-text text-muted">Username is case sensitive.</small>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="password">Password</label>
                                    <div className="input-group with-focus">
                                        <input type="password" ref="password" name="password" required="" className="form-control" />

                                        <div className="input-group-append">
                                            <span className="input-group-text fa fa-lock text-muted bg-transparent border-left-0"></span>
                                        </div>
                                    </div>
                                </div>
                            
                                <div className="clearfix">
                                    <div className="float-left mt-0">
                                        <Link to="/forgotPassword" className="text-muted">Forgot your password?</Link>
                                    </div>
                                </div>

                                <button className="btn btn-block btn-primary mt-3" type="submit">Login</button>
                                <Link to="/signup" className="btn btn-block btn-secondary mt-3">Create Account</Link>
                            </form>
                        </div>
                    </div>
                </div>
            
            );
        }
    }
}

export default Login;
