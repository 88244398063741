import Login from './Login';
import Logout from './Logout';
import Signup from './Signup';
import User from './User';
import Layout from './Layout';
import ResetPassword from './ResetPassword';
import ForgotPassword from './ForgotPassword';

export default {
    //Lock,
    Login,
    Signup,
    User,
    Layout, 
    Logout,
    ResetPassword,
    ForgotPassword
}