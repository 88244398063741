import Alerts from './Alerts';
import List from './List';
import Detail from './Detail';
import Analyze from './Analyze';

export default {
    Alerts, 
    List,
    Detail,
    Analyze
}