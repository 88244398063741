import React, { Component } from "react";
import { Redirect } from 'react-router-dom'

import {
    FormGroup,
    Input,
} from 'reactstrap';

import CreatableSelect from 'react-select/lib/Creatable';
import LoginStore from '../../modules/Auth/LoginStore';

import * as restAPI from '../../utilities/ApiService';


var interestsList = [
    { value: 1, label: "Baseball" },
    { value: 2, label: "Basketball" },
    { value: 3, label: "Collegiate Sports" },
    { value: 4, label: "Craft spirits/breweries" },
    { value: 5, label: "Fishing" },
    { value: 6, label: "Football" },
    { value: 7, label: "Golf" },
    { value: 8, label: "Hunting" },
    { value: 9, label: "Professional Sports" },
    { value: 10, label: "Shooting/Skeet" },
    { value: 11, label: "Soccer" },
    { value: 12, label: "Tennis" },
    { value: 13, label: "Volunteering" },
    { value: 14, label: "Other (static other)" },
];

class Signup extends Component {
    
    constructor(props, context) {
        super(props, context);

        this.state = {
            redirectToDashboard: false,
            redirectToHolding: false,
            redirectToNoOrg: false,
            email: null,
            name: null,
            phone: null,
            location_state: null,
            location_city: null, 
            university: null, 
            profile_interests: null,
            age: null,
            job_role: null,
            selectedInterests: [],
            interests: [],
            ages: [],
            universities: [],
            states: [],
            selected_job_role: null,
            roles: [],
        }


        if (LoginStore.isLoggedIn()) {
            this.state = {
                redirectToDashboard: true
            }
        }
        
        this.submitRegistration = this.submitRegistration.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleAgeChange = this.handleAgeChange.bind(this);
        this.handleInterestChange = this.handleInterestChange.bind(this);
        this.handleStateChange = this.handleStateChange.bind(this);
        this.handleUniversityChange = this.handleUniversityChange.bind(this);
        this.handleRoleChange = this.handleRoleChange.bind(this);
    }
    
    async componentDidMount() {

        
        await restAPI.get('/api/1/lookups/universities').then(res => {
            this.setState({
                universities: res.universities,
            });
        });

        let tmpStates = {
            "AL": "Alabama",
            "AK": "Alaska",
            "AS": "American Samoa",
            "AZ": "Arizona",
            "AR": "Arkansas",
            "CA": "California",
            "CO": "Colorado",
            "CT": "Connecticut",
            "DE": "Delaware",
            "DC": "District Of Columbia",
            "FM": "Federated States Of Micronesia",
            "FL": "Florida",
            "GA": "Georgia",
            "GU": "Guam",
            "HI": "Hawaii",
            "ID": "Idaho",
            "IL": "Illinois",
            "IN": "Indiana",
            "IA": "Iowa",
            "KS": "Kansas",
            "KY": "Kentucky",
            "LA": "Louisiana",
            "ME": "Maine",
            "MH": "Marshall Islands",
            "MD": "Maryland",
            "MA": "Massachusetts",
            "MI": "Michigan",
            "MN": "Minnesota",
            "MS": "Mississippi",
            "MO": "Missouri",
            "MT": "Montana",
            "NE": "Nebraska",
            "NV": "Nevada",
            "NH": "New Hampshire",
            "NJ": "New Jersey",
            "NM": "New Mexico",
            "NY": "New York",
            "NC": "North Carolina",
            "ND": "North Dakota",
            "MP": "Northern Mariana Islands",
            "OH": "Ohio",
            "OK": "Oklahoma",
            "OR": "Oregon",
            "PW": "Palau",
            "PA": "Pennsylvania",
            "PR": "Puerto Rico",
            "RI": "Rhode Island",
            "SC": "South Carolina",
            "SD": "South Dakota",
            "TN": "Tennessee",
            "TX": "Texas",
            "UT": "Utah",
            "VT": "Vermont",
            "VI": "Virgin Islands",
            "VA": "Virginia",
            "WA": "Washington",
            "WV": "West Virginia",
            "WI": "Wisconsin",
            "WY": "Wyoming"
        }

        var states = [];

        Object.entries(tmpStates).forEach(([key, value]) => {
            states.push({ value: key, label: value })
        })

        let roles = [
            { value: 'Completions', label: 'Completions' },
            { value: 'Management', label: 'Management' },
            { value: 'Production', label: 'Production' },
            { value: 'Reservoir', label: 'Reservoir' },
            { value: 'Technology', label: 'Technology' }
        ];

        var ageList = [
            { value: 25, label: "Under 25" },
            { value: 30, label: "25 - 30" },
            { value: 35, label: "30 - 35" },
            { value: 40, label: "35 - 40" },
            { value: 45, label: "40 - 45" },
            { value: 50, label: "45 - 50" },
            { value: 55, label: "Over 55" },
        ]

        this.setState({ states: states, roles: roles, interests: interestsList, ages: ageList });

    }

    handleStateChange(event) {
        const target = event.target;

        this.setState({
            location_state: target.value
        });
    }

    handleInterestChange(selectedArray) {
        var profile_interests = [];
        selectedArray.forEach((v) => {
            profile_interests.push(v.value);
        });

        this.setState({
            profile_interests: profile_interests,
            selectedInterests: selectedArray
        });
    }
    
    handleAgeChange(event) {
        const target = event.target;

        this.setState({
            age: target.value
        });
    }
    
    handleRoleChange(role) {
        this.setState({
            selected_job_role: role,
            job_role: role.value
        });
    }

    handleUniversityChange(event) {
        const target = event.target;

        this.setState({
            university: target.value
        });
    }


    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        
        this.setState({
            [name]: value
        });   
    }

    submitRegistration(e) {
        e.preventDefault();

        restAPI.post('/api/1/auth/signup', {
            email: this.state.email,
            name: this.state.name,
            phone: this.state.phone,
            location_state: this.state.location_state,
            location_city: this.state.location_city,
            university: this.state.university,
            interests: this.state.profile_interests,
            age: this.state.age,
            job_role: this.state.job_role
        }).then(res => { 
            if (res.data.status_type === "SUCCESS") {
                this.setState({
                    redirectToHolding: true
                });
            } else if (res.data.status_type === "NO_ORG") {
                this.setState({
                    redirectToNoOrg: true
                });
            } else if (res.data.status_type === "ACCOUNT_EXISTS") {
                // TODO: Handle
            }
        }).catch(err => {
            console.log(err);
        });
    }

    render() {
        if (this.state.redirectToDashboard) {
            return <Redirect to='/login' />
        } else if (this.state.redirectToNoOrg) {
            return (
                <div className="block-center mt-4 wd-fixed-user-form">
                    <div className="card card-flat">
                        <div className="card-header text-center bg-dark">
                            <a href="/">
                                <img className="block-center rounded login-logo" src="img/logo.png" alt="Logo" />
                            </a>
                        </div>

                        <div className="card-body">
                            <p>There is no organization associated with your email's domain. Please contact <a href="mailto:avidsupport@reservoirdata.com">avidsupport@reservoirdata.com</a> for assistance.</p>
                        </div>
                    </div>
                </div>
            );
        } else if (this.state.redirectToHolding) {
            return (
                <div className="block-center mt-4 wd-fixed-user-form">
                    <div className="card card-flat">
                        <div className="card-header text-center bg-dark">
                            <a href="/">
                                <img className="block-center rounded login-logo" src="img/logo.png" alt="Logo" />
                            </a>
                        </div>

                        <div className="card-body">
                            <p>A link to set your password has been sent to your inbox to verify your email address.</p>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="block-center mt-4 wd-fixed-user-signup-form">
                    <div className="card card-flat" style={{background: "transparent"}}>
                        <div className="card-header text-center">
                            <a href="/">
                                <img className="block-center rounded login-logo" src="img/logo.png" alt="Logo" />
                            </a>
                        </div>

                        <div className="card-body bg-white">
                            <p>Use your company email address to register for your account. You will be emailed a verification link once you complete signup.</p>
                        
                            <form id="user-login" name="loginForm" noValidate className="mb-3 form-validate">

                                <FormGroup row>
                                    <label className="col-xl-2 col-form-label">Email</label>
                                    <div className="col-xl-10">
                                        <Input type="text"
                                            placeholder="Email"
                                            name="email"
                                            value={this.state.email ? this.state.email : ""}
                                            onChange={this.handleInputChange} />
                                    </div>
                                </FormGroup>

                                <FormGroup row>
                                    <label className="col-xl-2 col-form-label">Name</label>
                                    <div className="col-xl-10">
                                        <Input type="text"
                                            placeholder="Name"
                                            name="name"
                                            value={this.state.name ? this.state.name : ""}
                                            onChange={this.handleInputChange} />
                                    </div>
                                </FormGroup>

                                <FormGroup row>
                                    <label className="col-xl-2 col-form-label">Phone</label>
                                    <div className="col-xl-10">
                                        <Input type="text"
                                            placeholder="Phone (Cell, Optional: Used for user configured alerts)"
                                            name="phone"
                                            value={this.state.phone ? this.state.phone : ""}
                                            onChange={this.handleInputChange} />
                                    </div>
                                </FormGroup>
                                <hr />

                                <FormGroup row>
                                    <label className="col-xl-2 col-form-label">State</label>
                                    <div className="col-xl-10">
                                        <select value={this.state.location_state ? this.state.location_state : ""}
                                            onChange={this.handleStateChange}
                                            className="form-control"
                                            placeholder="Select an option" >
                                            <option key="">Select a state...</option>
                                            {this.state.states.map((state) => <option key={state.value} value={state.value}>{state.label}</option>)}
                                        </select>
                                    </div>
                                </FormGroup>

                                <FormGroup row>
                                    <label className="col-xl-2 col-form-label">City</label>
                                    <div className="col-xl-10">
                                        <Input type="text"
                                            placeholder="City"
                                            name="location_city"
                                            value={this.state.location_city ? this.state.location_city : ""}
                                            onChange={this.handleInputChange} />
                                    </div>
                                </FormGroup>

                                <hr />


                                <FormGroup row>
                                    <label className="col-xl-2 col-form-label">Role</label>
                                    <div className="col-xl-10">
                                        <CreatableSelect
                                            className="form-control form-select-element"
                                            placeholder="Select an option"
                                            value={this.state.selected_job_role}
                                            onChange={this.handleRoleChange}
                                            options={this.state.roles.map(role => ({
                                                key: role.label,
                                                label: role.label,
                                                value: role.label
                                            }))} />

                                        <small id="passwordHelpBlock" className="form-text text-muted">
                                            To add an "Other" role start typing and click "Create"
                                    </small>
                                    </div>
                                </FormGroup>

                                <FormGroup row>
                                    <label className="col-xl-2 col-form-label">Interests</label>
                                    <div className="col-xl-10">
                                        <CreatableSelect
                                            isMulti
                                            className="form-control form-select-element"
                                            placeholder="Select an option"
                                            value={this.state.selectedInterests}
                                            options={this.state.interests.map(interest => ({
                                                key: interest.label,
                                                label: interest.label,
                                                value: interest.label
                                            }))}
                                            onChange={this.handleInterestChange} />

                                        <small id="passwordHelpBlock" className="form-text text-muted">
                                            To add interests that are not on the list, start typing and select the "Create" option.
                                    </small>
                                    </div>
                                </FormGroup>
                                
                                <FormGroup row>
                                    <label className="col-xl-2 col-form-label">University</label>
                                    <div className="col-xl-10">
                                        <select
                                            className="form-control"
                                            placeholder="Select an option"
                                            value={this.state.university ? this.state.university : ""}
                                            onChange={this.handleUniversityChange}>
                                            <option key="">Select an option...</option>
                                            {this.state.universities.map((element) => <option key={element.id} value={element.id}>{element.name}</option>)}
                                        </select>
                                    </div>
                                </FormGroup>

                                <FormGroup row>
                                    <label className="col-xl-2 col-form-label">Age</label>
                                    <div className="col-xl-10">
                                        <select
                                            className="form-control"
                                            placeholder="Select an option"
                                            value={this.state.age ? this.state.age : ""}
                                            onChange={this.handleAgeChange}>
                                            <option key="">Select an option...</option>
                                            {this.state.ages.map((element) => <option key={element.value} value={element.value}>{element.label}</option>)}
                                        </select>
                                    </div>
                                </FormGroup>

                                <div className="clearfix">
                                    <div className="float-left mt-0">
                                        {/* <Link to="recover" className="text-muted">Forgot your password?</Link> */}
                                    </div>
                                </div>

                                <button className="btn btn-block btn-primary mt-3" onClick={this.submitRegistration}>Submit</button>
                            </form>
                        </div>
                    </div>
                </div>
            
            );
        }
    }
}

export default Signup;
