import BaseStore from '../../BaseStore'
import LoginConstants from './LoginConstants'

class LoginStore extends BaseStore {

    constructor() {
        super();
        this.subscribe(() => this._registerToActions.bind(this))
        this._user = null;
        this._token = null;
        this._encodedUserToken = null;
    }

    parseAndPersistTokenData = (encodedUserToken) => {
        const decodedUserToken = JSON.parse(encodedUserToken)

        this._encodedUserToken = encodedUserToken
        this._user = decodedUserToken.user
        this._token = decodedUserToken.token
    }

    _registerToActions(action) {
        switch (action.actionType) {
            case LoginConstants.LOGIN_USER:
                this.parseAndPersistTokenData(action.encodedUserToken)
                this.emitChange();
                break;

            case LoginConstants.LOGOUT_USER:
                this._user = null;
                this.emitChange();
                break;

            case LoginConstants.SET_USER_CURRENT_ORG:
                this.parseAndPersistTokenData(action.updatedToken)
                this.emitChange()
                break;

            default:
                break;
        };
    }

    get user() {
        return this._user;
    }

    get token() {
        return this._token;
    }

    get accessToken() {
        if (this._token) {
            return this._token.accessToken;
        } else {
            return "";
        }

    }

    /**
     * Check whether token is expired on the client side
     * 
     * @return {boolean}
     */
    isTokenExpired() {
        if (!this._token) {
            return false;

        }

        if (!this._token || !this._token.hasOwnProperty('expiresIn')) {
            return false;
        }

        var now = new Date();
        var tokenExpiration = new Date(this._token.expiresIn);
        return (now > tokenExpiration);
    }

    /**
     * Approximate client side check if user is logged in
     * 
     * @return {boolean}
     */
    isLoggedIn() {
        if (this.isTokenExpired()) {
            return false;
        }

        return !!this._user;
    }

    isPending() {
        if (!this._user) {
            return false;
        }

        return (this._user.system_role === "pending");
    }

    isSystemAdmin() {
        if (!this._user) {
            return false;
        }

        return (this._user.system_role === "sysadmin");
    }
}

export default new LoginStore();