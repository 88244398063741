
import React from 'react'
import { useDragLayer } from 'react-dnd'

const layerStyles = {
    position: 'fixed',
    pointerEvents: 'none',
    zIndex: 100,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
  }
function getItemStyles(initialOffset, currentOffset) {
    if (!initialOffset || !currentOffset) {
      return {
        display: 'none',
      }
    }

    let { x, y } = currentOffset
    x -= (initialOffset.x / 2)
    y -= 0
    
    const transform = `translate(${x}px, ${y}px)`
    return {
      transform,
      WebkitTransform: transform,
    }
  }
  export const CustomDragLayer = ({children, type, draggedItem }, ...props) => {
    const { itemType, isDragging, item, initialOffset, currentOffset } =
      useDragLayer((monitor) => ({
        item: monitor.getItem(),
        itemType: monitor.getItemType(),
        initialOffset: monitor.getInitialSourceClientOffset(),
        currentOffset: monitor.getSourceClientOffset(),
        isDragging: monitor.isDragging(),
      }))
    function renderItem() {
      switch (itemType) {
        case type:
          return (children)
        default:
          return null
      }
    }
    if (!isDragging || draggedItem.target_job_id !== item.target_job_id || draggedItem.target_dataset_field !== item.target_dataset_field) { //All draggable items need an ID
      return null
    }
    return (
      <div style={layerStyles}>
        <div
          style={getItemStyles(initialOffset, currentOffset)}
        >
          {renderItem()}
        </div>
      </div>
    )
  }