import React from 'react';
import { Redirect } from 'react-router-dom';
import { Row, Col, FormGroup, Input, InputGroup } from 'reactstrap';

import * as restAPI from '../../../../common/utilities/ApiService';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import $ from 'jquery';

import ReactGA from 'react-ga';
import WellSelector from '../Elements/WellSelector';
import LoadingSpinner from '../Elements/LoadingSpinner';

const formDateRangeStyle = {
    textAlign: 'center',
    marginTop: '7px'
};

class Create extends React.Component {

    hasMultipleWellsSelected() {
        return this.state.selectedJobs && this.state.selectedJobs.length > 1;
    }
    handleIncludeAllDataChange(event) {
        const isChecked = event.target.checked;
        this.setState({
            includeAllData: isChecked,
            showDateMessage: !isChecked  // Show message if the button is unclicked
        });
    }
    constructor(props) {
        super(props);

        this.state = {
            highlightedWells: [],
            availableJobs: [],
            selectedJobs: [],
            fileParameters: [],
            updateNumber: 1,
            description: null,
            include_date: true,
            include_time: true,
            include_datetime: false,
            include_timestamp: false,
            resolution: "raw",
            resolutions: [],
            start_date: null,
            end_date: null,
            max_date: true,
            saved: false,
            passedDashboardJobs: [],
            resolution_entry: 1,
            inactiveJobs: false, //new code,
            all_jobs: [],
            active_jobs: [],
            isLoading: true,
            includeAllData: true,
            showDateMessage: false,
            isResolutionInvalid: false,
            isDateInvalid: false,
            currentDate: false

        }
        this.handleIncludeAllDataChange = this.handleIncludeAllDataChange.bind(this);
        

        // Check if any jobs were passed from the dashboard
        if (props.location.state) {
            const { selectedJobs } = props.location.state;



            var transformedJobs = selectedJobs.map(selectedJob => {
                return {
                    jobId: selectedJob.jobId,
                    key: selectedJob.key,
                    label: selectedJob.label,
                    value: selectedJob.value
                }
            })

            this.state.passedDashboardJobs = transformedJobs;
        }


        this.handleMultiSelectChange = this.handleMultiSelectChange.bind(this);
        this.handleFieldSelectToggle = this.handleFieldSelectToggle.bind(this);
        this.handleAllFieldSelectToggle = this.handleAllFieldSelectToggle.bind(this);
        this.GetAllFieldSelectState = this.GetAllFieldSelectState.bind(this);
        this.GetAllFieldSelectToggleChecked = this.GetAllFieldSelectToggleChecked.bind(this);
        this.GetAllFieldSelectToggleIndeterminate = this.GetAllFieldSelectToggleIndeterminate.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSharedColumnToggle = this.handleSharedColumnToggle.bind(this);
        this.handleValueToggle = this.handleValueToggle.bind(this);
        this.handleResolutionChange = this.handleResolutionChange.bind(this);

        this.handleChecked = this.handleChecked.bind(this);
        
    }

    async componentDidMount() {
        await restAPI.get('/api/1/jobs?__v=2').then(res => {
            const allJobs = res.map(e => {
                return {
                    ...e,
                    label: e.name,
                    value: e.id,
                    options: e.jobs.map((d) => {
                        return { ...d, label: d.display_name, value: d.id }
                    })
                }
            });

            const activeJobs = res.filter(e => e.has_active).map(e => {
                return {
                    ...e,
                    label: e.name,
                    value: e.id,
                    options: e.jobs.filter(d => d.active).map((d) => {
                        return { ...d, label: d.display_name, value: d.id }
                    })
                }
            });

            this.setState({
                all_jobs: allJobs,
                active_jobs: activeJobs,
                isLoading: false
            });
        });
    }

    async hydrateJobData(selectedJobs) {
        const fileParameters = await Promise.all(selectedJobs.map(job =>
            restAPI.get(`/api/1/jobs/${job.value}/columns`)
        ))
        this.setState({ fileParameters: fileParameters });
    }

    handleMultiSelectChange(selectedJobs) {
        this.hydrateJobData(selectedJobs);
        this.setState({ selectedJobs: selectedJobs });

        // If more than one well is selected, set highlightedWells state
        if (selectedJobs && selectedJobs.length > 1) {
            // Extract all well IDs except the first one to highlight
            const highlightedWells = selectedJobs.slice(1).map(job => job.value);
            this.setState({ highlightedWells: highlightedWells });
        } else {
            this.setState({ highlightedWells: [] });
        }
    }

    handleChecked(bool) {
        this.setState({ inactiveJobs: bool })
    }

    GetAllFieldSelectToggleChecked(jobId) {
        return this.GetAllFieldSelectState(jobId) === 1;
    }
    GetAllFieldSelectToggleIndeterminate(jobId) {
        return this.GetAllFieldSelectState(jobId) === -1;
    }
    GetAllFieldSelectState(jobId) {
        var someChecked = false;
        var allChecked = true;
        this.state.fileParameters.forEach((fp) => {
            for (var key in fp.columns) {
                if (fp.columns[key]) {
                    someChecked = true;
                }
                else {
                    allChecked = false;
                }
            }
        });

        if (!someChecked) return 0;

        return allChecked ? 1 : -1
    }

    handleAllFieldSelectToggle(jobId, e) {

        var fileParameters = this.state.fileParameters;

        var editIdx = fileParameters.findIndex(e => (e.id === jobId));

        var state = this.GetAllFieldSelectState(jobId)

        if (editIdx > -1) {
            var newVal = state === 1 ? false : true;
            //fileParameters[editIdx].columns[key] = !fileParameters[editIdx].columns[key];
            for (var key in fileParameters[editIdx].columns) {
                fileParameters[editIdx].columns[key] = newVal;
            }
        }

        this.setState({
            fileParameters: fileParameters
        })
    }

    handleFieldSelectToggle(jobId, key, e) {

        var fileParameters = this.state.fileParameters;

        var editIdx = fileParameters.findIndex(e => (e.id === jobId));

        if (editIdx > -1) {
            fileParameters[editIdx].columns[key] = !fileParameters[editIdx].columns[key];
        }

        this.setState({
            fileParameters: fileParameters
        })
    }

    handleDateKeydown = (e) => {
    e.preventDefault();
    }

    handleSharedColumnToggle(key, e) {
        this.setState({
            [key]: !this.state[key]
        });
    }

    handleValueToggle(key, e) {
        this.setState({
            [key]: !this.state[key]
        });
    }

    handleResolutionChange(event) {
        const target = event.target;

        this.setState({
            resolution: target.value
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
    
        this.setState({ [name]: value }, () => {
            let enableSubmit = false;

            if (name === "max_date" && value) {
                this.setState({ isDateInvalid: false });
            } else if (this.state.max_date) {
                enableSubmit = true;  // "Include All Data" is checked
            } else if (this.state.current_date && this.state.start_date) {
                enableSubmit = true;  // "Include Data To Current Time" is checked and start date is selected
            } else if (!this.state.current_date && !this.state.max_date && this.state.start_date && this.state.end_date) {
                enableSubmit = true;  // Both checkboxes are unchecked and a valid date range is selected
            }
    
            this.setState({ isDateInvalid: !enableSubmit });
        });
    
        switch (target.name) {
            case "start_date":
                if (Date.parse(value) > Date.now()) {
                    // If date selected is in the future, reset to current date
                    this.setState({
                        [name]: new Date().toISOString().split('T')[0]
                    });
                } else {
                    this.setState({ [name]: value });
                }
                break;
    
            case "end_date":
                if (this.state.start_date && value < this.state.start_date) {
                    this.setState({
                        [name]: value,
                        start_date: value
                    });
                } else {
                    this.setState({ [name]: value });
                }
                break;
    
            case "max_date":
                if (value) {
                    this.setState({
                        start_date: null,
                        end_date: null,
                        current_date: false
                    });
                }
                break;
    
            case "current_date":
                if (value) {
                    this.setState({
                        end_date: null
                    });
                }
                // Update isDateInvalid again after the current_date logic is handled
                const isDateInvalidForCurrentTime = 
                    value && !this.state.start_date;
                this.setState({ isDateInvalid: isDateInvalidForCurrentTime });
                break;
    
            case "resolution_entry":
                if (value < 1) {
                    this.setState({
                        isResolutionInvalid: true,
                        [name]: value,
                    });
                } else {
                    this.setState({
                        isResolutionInvalid: false,
                        [name]: value,
                    });
                }
                break;
    
            default:
                this.setState({
                    [name]: value
                });
                break;
        }
    }
    
    

    onSubmit = e => {

        if (this.state.isResolutionInvalid) {
            e.preventDefault();
            return;
        }

        $("#fileSubmit").text("Saving...");
        $("#fileSubmit").attr("disabled", true);

        // Validate that at least one column is selected
        var hasSelectedColumn = false;
        this.state.fileParameters.forEach((fp) => {
            for (var key in fp.columns) {
                if (fp.columns[key]) {
                    hasSelectedColumn = true;
                }
            }
        });

        if (!hasSelectedColumn) {
            toast("You must select at least one column to export", {
                type: "warning",
                position: 'top-center'
            })

            $("#fileSubmit").text("Save");
            $("#fileSubmit").attr("disabled", false);

        } else {
            let resolution = this.state.resolution;
            if (this.state.resolution === "custom") {
                resolution = this.state.resolution_entry;
            }

            restAPI.post('/api/1/files/', {
                description: this.state.description,
                include_date: this.state.include_date,
                include_time: this.state.include_time,
                include_datetime: this.state.include_datetime,
                include_timestamp: this.state.include_timestamp,
                resolution: resolution,
                file_parameters: this.state.fileParameters,
                start_date: this.state.start_date,
                end_date: this.state.end_date,
                max_date: this.state.max_date
            }).then(res => {
                this.setState({
                    saved: true
                })
            });
        }

        e.preventDefault();
    }

    render() {

        const shouldShowDateMessage = this.state.isDateInvalid && 
                            (!this.state.max_date && (!this.state.current_date || this.state.current_date));

        ReactGA.pageview("/files/create");
        const currentDate = new Date().toISOString().split('T')[0];
        const renderRows = (
            <table className="table table-striped export-column-table">
                <tbody>
                    {this.state.fileParameters.map(function (fileParameterRow) {
                        return (
                            <tr key={fileParameterRow.id}>
                                <th>{fileParameterRow.display_name}</th>
                                <td className="width-75">
                                    {(Object.keys(fileParameterRow.columns).length > 3) &&
                                        <div className="form-check form-check-inline" >
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                checked={this.GetAllFieldSelectToggleChecked(fileParameterRow.id)}
                                                indeterminate={this.GetAllFieldSelectToggleIndeterminate(fileParameterRow.id)}
                                                onChange={(e) => this.handleAllFieldSelectToggle(fileParameterRow.id, e)}
                                            />
                                            <label className="form-check-label-bolder">Select All </label>
                                            <div class="form-check">  </div>
                                        </div>
                                    }
                                    {Object.keys(fileParameterRow.columns).map(function (key, idx) {
                                        return (
                                            <div className="form-check form-check-inline" key={"innerDiv-" + idx}>
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    checked={fileParameterRow.columns[key]}
                                                    onChange={(e) => this.handleFieldSelectToggle(fileParameterRow.id, key, e)}
                                                />
                                                <label className="form-check-label">{key}</label>
                                            </div>
                                        )
                                    }, this)}
                                </td>
                            </tr>
                        )
                    }, this)}
                </tbody>
            </table>
        );

        const renderForm = (
            <div className="card-body">
                <form className="form-horizontal" onSubmit={this.onSubmit}>

                    <FormGroup row>
                        <div className="col-md-3">
                            <div className="form-check form-check-inline" style={formDateRangeStyle}>
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    name="max_date"
                                    checked={this.state.max_date}
                                    onChange={this.handleInputChange}
                                />
                                <label className="form-check-label">Include All Data</label>
                            </div>
                            <div className="form-check form-check-inline" style={formDateRangeStyle}>
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    name="current_date"
                                    disabled={this.state.max_date}
                                    onChange={this.handleInputChange}
                                />
                                <label className="form-check-label">Include Data To Current Time</label>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-xl-5">
                                    <Input type="date"
                                        name="start_date"
                                        max={currentDate}
                                        value={this.state.start_date ? this.state.start_date : ""}
                                        onChange={this.handleInputChange}
                                        onKeyDown={this.handleDateKeydown}
                                        disabled={this.state.max_date} />
                                </div>
                                {!this.state.current_date && (
                                <>
                                <div className="col-xl-2">
                                    <p style={formDateRangeStyle}>
                                        to
                                    </p>
                                </div>
                                <div className="col-xl-5">
                                    <Input type="date"
                                        name="end_date"
                                        max={currentDate}
                                        value={this.state.end_date ? this.state.end_date : ""}
                                        onChange={this.handleInputChange}
                                        onKeyDown={this.handleDateKeydown}
                                        disabled={(this.state.max_date || this.state.current_date)} />
                                </div>
                            </>
                        )}
                            </div>
                            <div className="row">
                                <div className="col-xl-12">
                                    <label className="col-md-3 col-form-label">Date Range</label>
                                    <small>Note: Date selection is based on the UTC timestamp when data was collected.</small>
                                     {shouldShowDateMessage && <p style={{ color: 'red', fontWeight: 'bold'}}>Please select dates using the calendar icon</p>}
                                </div>
                            </div>
                        </div>
                    </FormGroup>

                    <FormGroup row>
                        <label className="col-md-3 col-form-label">Data Resolution</label>
                        <div className="col-md-5">
                            <select
                                className="form-control"
                                placeholder="Select an option"
                                value={this.state.resolution ? this.state.resolution : "raw"}
                                onChange={this.handleResolutionChange}>
                                <option value="raw">Raw Data</option>
                                <option value="custom">Custom Resolution</option>
                            </select>
                            {this.state.isResolutionInvalid && <p style={{ color: 'red', fontWeight: 'bold' }}>Resolution must be 1 second or greater </p>}
                        </div>
                        <div className="col-md-4">
                            <InputGroup>
                                <Input type="number"
                                    name="resolution_entry"
                                    placeholder="Custom Interval"
                                    value={this.state.resolution_entry ? this.state.resolution_entry : ""}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.resolution !== "custom"}
                                />
                                <span className='input-group-text'>Seconds</span>
                            </InputGroup>
                        </div>
                    </FormGroup>

                    <FormGroup row>
                        <label className="col-md-3 col-form-label">Description</label>
                        <div className="col-md-9">
                            <Input type="text"
                                placeholder="Optional file description"
                                name="description"
                                value={this.state.description ? this.state.description : ""}
                                onChange={this.handleInputChange} />
                        </div>
                    </FormGroup>

                    <FormGroup row>
                        <label className="col-md-3 col-form-label">Shared Columns</label>
                        <div className="col-md-9">
                            <div className="form-check form-check-inline">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={this.state.include_date}
                                    onChange={(e) => this.handleSharedColumnToggle("include_date", e)}
                                />
                                <label className="form-check-label">Date</label>
                            </div>

                            <div className="form-check form-check-inline">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={this.state.include_time}
                                    onChange={(e) => this.handleSharedColumnToggle("include_time", e)}
                                />
                                <label className="form-check-label">Time</label>
                            </div>

                            <div className="form-check form-check-inline">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={this.state.include_datetime}
                                    onChange={(e) => this.handleSharedColumnToggle("include_datetime", e)}
                                />
                                <label className="form-check-label">Date Time</label>
                            </div>

                            <div className="form-check form-check-inline">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={this.state.include_timestamp}
                                    onChange={(e) => this.handleSharedColumnToggle("include_timestamp", e)}
                                />
                                <label className="form-check-label">Timestamp (epoch time)</label>
                            </div>

                        </div>
                    </FormGroup>

                    <FormGroup row>
                        <div className="col-md-3"></div>
                        <div className="col-md-9">
                        <button id="fileSubmit" className="btn btn-sm btn-primary" type="submit" disabled={this.state.isResolutionInvalid || this.state.selectedJobs.length > 1 || this.state.isDateInvalid}>Submit</button>
                        </div>
                    </FormGroup>
                </form>
            </div>
        )

        var FileParameterRows;
        var FileForm;

        if (this.state.fileParameters.length > 0) {
            FileParameterRows = renderRows;
            FileForm = renderForm;
        } else {
            FileParameterRows = <div className="card-body text-center"><p>Select ONE job from the dropdown menu above to begin choosing columns for export.</p></div>
        }

        if (this.state.saved) {
            return (
                <Redirect to={"/files/list"} />
            )
        }
        else {
            return (
                this.state.isLoading
                    ? <LoadingSpinner/>
                    : <Row>
                        <Col md={12}>
                            <div className="card card-default">
                                <div className="card-header">
                                    <span className="card-title">Generate Export File</span>
                                </div>
                                <div className="card-body">
                                    <FormGroup row>
                                        <label className="col-xl-12 col-form-label">Included Job</label>
                                        { this.hasMultipleWellsSelected() && 
                                            <div style={{ color: 'red', fontWeight: 'bold'}}>
                                                Please select ONLY 1 well to download
                                            </div> 
                                        }
                                        <div className="col-xl-12">
                                            <WellSelector
                                                isMulti
                                                selectOptions={this.state.inactiveJobs ? this.state.all_jobs : this.state.active_jobs}
                                                selectedJobs={this.state.selectedJobs}
                                                handleMultiSelectChange={this.handleMultiSelectChange}
                                                highlightedWells={this.state.highlightedWells}
                                                showInactive={this.state.inactiveJobs}
                                                setShowInactive={this.handleChecked}
                                                placeholder="Select a well to download."
                                            />
                                        </div>
                                    </FormGroup>
                                </div>

                                {FileParameterRows}

                                {FileForm}

                                <div className="card-footer">
                                </div>
                            </div>
                        </Col>
                    </Row>
            );
        }
    }
};

export default Create;