import ReactTable from 'react-table'
import React, { useState, useEffect } from 'react'
import { SuccessButton, DangerButton } from '../Core/Shared'
import * as restAPI from '../../../../common/utilities/ApiService'
import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"

export const AddSharedDashboardModal = ({ setIsModalOpen, currentRows, updateFieldValue, dashboardId }) => {

    const [sharedDashboards, setSharedDashboards] = useState([])
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedDashboardId, setSelectedDashboardId] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        (async () => {
            const { current_org_id } = await restAPI.get('/api/1/users/me/orgs')
            const sharedDashboards = await restAPI.get(`/api/1/dashboarding/shared/${current_org_id}`)
            setSharedDashboards(sharedDashboards)
            setIsLoading(false);
        })()
    }, []);

    const removeAllIds = (rows) => {
        let newRows = [...rows];
        newRows.forEach((r, i) => {
            delete r.id;
            r.dashboard_id = dashboardId;
            r.ordinal = currentRows.length + i;
            r.tiles.forEach(t => {
                delete t.id;
                delete t.row_id;
                t.graph = removeGraphIds(t.graph);
                t.widgets = removeWidgetArrIds(t.widgets);
            })
        })
        return newRows
    }

    const removeGraphIds = (graph) => {
        let newGraph = graph
            ? { ...graph }
            : null
        if (newGraph) {
            delete newGraph.id
            delete newGraph.tile_id
            newGraph.axes.forEach(a => {
                delete a.id
                delete a.graph_id
                a.parameters.forEach(p => {
                    delete p.id
                    delete p.axis_id
                })
            });
        }
        return newGraph;
    }

    const removeWidgetArrIds = (widgetArr) => {
        let newWidgetArr = [...widgetArr];
        newWidgetArr.forEach(w => {
            delete w.id;
            delete w.tile_id;
        });
        return newWidgetArr;
    }

    const onConfirm = async () => {
        const { rows } = await restAPI.get(`/api/1/dashboarding/${selectedDashboardId}`)
        const copiedRows = removeAllIds(rows);
        const newRows = currentRows.concat(copiedRows);
        updateFieldValue('rows', newRows);
        setIsModalOpen(false);
    };

    return !isLoading &&
        <Modal
            style={{ maxWidth: '1600px', width: '90vw', marginTop: '100px' }}
            isOpen={true}
            className='modal-dialog'
            contentClassName='custom-modal-style'
        >
            <ModalHeader
                close={<button className="close" onClick={() => setIsModalOpen(false)}>×</button>}
            >Add Shared Dashboard
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col xs sm md lg xl={{ size: 8, offset: 2 }}>
                        <div className='card card-default'>
                            <ReactTable
                                getTdProps={(state, rowInfo, col, instance) => {
                                    return {
                                        style: {
                                            cursor: 'pointer',
                                            background: selectedRow === rowInfo?.index && '#e1ebfa'
                                        },
                                        onClick: () => {
                                            if (rowInfo && rowInfo.row) {
                                                if (selectedRow === rowInfo.index) {
                                                    // deselect row
                                                    setSelectedRow(null);
                                                    setSelectedDashboardId(null);
                                                } else {
                                                    // select row
                                                    setSelectedRow(rowInfo.index);
                                                    // set dashboard id only if different
                                                    if (rowInfo.row._original.id !== selectedDashboardId) {
                                                        setSelectedDashboardId(rowInfo.row._original.id);
                                                    }
                                                }
                                            }
                                        },
                                    }
                                }}
                                filterable
                                data={sharedDashboards}
                                defaultPageSize={10}
                                className='-striped -highlight'
                                columns={[
                                    {
                                        Header: 'Title',
                                        accessor: 'title',
                                    },
                                    {
                                        Header: 'Created By',
                                        accessor: 'name',
                                        className: 'text-center',
                                    },
                                    {
                                        Header: 'Created',
                                        accessor: 'date_created',
                                        className: 'text-center',
                                        filterable: false,
                                        Cell: e => new Date(e.original.date_created).toLocaleString()
                                    },
                                    {
                                        Header: 'Last Updated',
                                        accessor: 'date_updated',
                                        className: 'text-center',
                                        filterable: false,
                                        Cell: e => new Date(e.original.date_updated).toLocaleDateString()
                                    },
                                    // {
                                    //     Header: 'Last Streamed',
                                    //     accessor: 'last_streamed',
                                    //     className: 'text-center',
                                    //     filterable: false,
                                    //     Cell: e => e.original.last_streamed === 'N/A' ? e.original.last_streamed : new Date(e.original.last_streamed).toLocaleString()
                                    // }
                                ]}
                            />
                        </div>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <DangerButton
                    text='Cancel'
                    onClick={() => setIsModalOpen(false)}
                />
                <SuccessButton
                    text='Confirm'
                    disabled={!selectedDashboardId}
                    onClick={() => onConfirm()}
                />
            </ModalFooter>
        </Modal >
}