import React from 'react';
import {
    TabContent, TabPane, Nav, NavLink, NavItem, Row, Col,
    FormGroup, Label,
    Input
} from 'reactstrap';
import classnames from 'classnames';

import * as restAPI from '../../../../common/utilities/ApiService';
import GFunctionPlot from './Components/GFunctionPlotPlotly';
import ParameterPlot from './Components/ParameterPlot';
import LogLogPlot from './Components/LogLogPlotPlotly';
import ReactGA from 'react-ga';

var moment = require('moment');

export default class Detail extends React.Component {
    constructor(req) {
        super()

        this.wellId = req.match.params.wellId;

        this.state = {
            isLoaded: false,
            job: null,
            activeStep: 0,
            rawData: {},
            rawDataLoaded: false,
            gFunctionData: {},
            gFunctionDataLoaded: false,
            activeTab: '1',
            stepOneDone: false,
            fip: "",
            fip_elapsed: "",
            fip_dt: "",
            isip: "",
            isip_elapsed: "",
            isip_dt: "",
            updatedUsername: null,
            updatedTs: null,
            gFunctionX1: 1,
            gFunctionY1: 1,
            gFunctionMessage: "",
            gFunctionSlopeMessage: "",
            parametersLoaded: false,
            extremeMin: null,
            extremeMax: null,
            slopeOverlays: []
        }

        this.gFunctionX1_Current = 1;
        this.gFunctionY1_Current = 1;

        this.changeFIP = this.changeFIP.bind(this);
        this.changeISIP = this.changeISIP.bind(this);
        this.setParameterPlotExtremes = this.setParameterPlotExtremes.bind(this);
        this.doneStepOne = this.doneStepOne.bind(this);
        this.editParameters = this.editParameters.bind(this);
        this.toggle = this.toggle.bind(this);

        this.changeGFunctionXY1 = this.changeGFunctionXY1.bind(this);
    }

    async componentDidMount() {
        await restAPI.get('/api/1/jobs/' + this.wellId).then(data => {
            this.setState({
                isLoaded: true,
                job: data.job
            });
        });

        await restAPI.get("/api/1/wells/" + this.wellId + "/parameters").then(data => {
            var newState = {
                parametersLoaded: true
            }

            var hasParameters = false;

            if (data.parameter_extreme_min && data.parameter_extreme_max) {
                newState.extremeMin = data.parameter_extreme_min;
                newState.extremeMax = data.parameter_extreme_max;
            }

            if (data.updated_ts && data.updated_by) {
                newState.updatedTs = data.updated_ts;
                newState.updatedUsername = data.updated_by.name;
            }

            if (data.parameter_fip_ts && data.parameter_fip_elapsed_time) {
                newState.fip = parseInt(data.parameter_fip_ts);
                newState.fip_elapsed = parseInt(data.parameter_fip_elapsed_time);

                let m = moment(parseInt(data.parameter_fip_ts) * 1000);
                let s = m.toISOString();

                newState.fip_dt = s;

                hasParameters = true;
            }

            if (data.parameter_isip_ts && data.parameter_isip_elapsed_time) {
                newState.isip = parseInt(data.parameter_isip_ts);
                newState.isip_elapsed = parseInt(data.parameter_isip_elapsed_time);

                let m = moment(parseInt(data.parameter_fip_ts) * 1000);
                let s = m.toISOString();

                newState.isip_dt = s;

                hasParameters = true;
            }

            this.setState(newState);

            return {
                hasParameters: hasParameters,
                gfunction_x: data.gfunction_x,
                gfunction_y: data.gfunction_y
            }

        }).then((data) => {
            var hasParameters = data.hasParameters,
                gfunction_x = data.gfunction_x,
                gfunction_y = data.gfunction_y;

            if (hasParameters) {
                restAPI.post('/api/1/wells/' + this.wellId + "/gFunction", {
                    "fip": this.state.fip,
                    "isip": this.state.isip
                }).then(ret => {
                    this.toggle('2');

                    this.gFunctionX1_Current = (gfunction_x ? parseFloat(gfunction_x) : 1);
                    this.gFunctionY1_Current = (gfunction_y ? parseFloat(gfunction_y) : Math.max(...ret.data.smoothed["p"]));

                    var slope = this.gFunctionY1_Current / this.gFunctionX1_Current;

                    this.setState({
                        gFunctionData: ret.data.smoothed,
                        gFunctionDataLoaded: true,
                        activeStep: 1,
                        gFunctionX1: gfunction_x ? parseFloat(gfunction_x) : 1,
                        gFunctionY1: gfunction_y ? parseFloat(gfunction_y) : Math.max(...ret.data.smoothed["p"]),
                        gFunctionSlopeMessage: "Slope: " + slope.toFixed(2).toString()
                    });
                });
            } else {
                restAPI.get('/api/1/wells/' + this.wellId + "/dfitAnalysis").then(data => {
                    this.setState({
                        rawDataLoaded: true,
                        rawData: data.raw
                    });
                });
            }
        });
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
                gFunctionX1: this.gFunctionX1_Current,
                gFunctionY1: this.gFunctionY1_Current
            });
        }
    }

    changeFIP(start_ts, xVal) {
        var time_elapsed = (parseInt(xVal) - start_ts) / 1000;
        var ts = parseInt(xVal) / 1000;
        var m = moment(ts * 1000);
        var s = m.toISOString();

        this.setState({
            fip: ts,
            fip_elapsed: time_elapsed,
            fip_dt: s
        })
    }

    changeISIP(start_ts, xVal) {
        var time_elapsed = (parseInt(xVal) - start_ts) / 1000;
        var ts = parseInt(xVal) / 1000;
        var m = moment(ts * 1000);
        var s = m.toISOString();

        this.setState({
            isip: ts,
            isip_elapsed: time_elapsed,
            isip_dt: s
        })
    }

    setParameterPlotExtremes(eMin, eMax) {
        restAPI.post("/api/1/wells/" + this.wellId + "/parameters", {
            extreme_min: eMin,
            extreme_max: eMax
        }).then(res => {
            console.log("Saved parameter plot extremes: " + res.data.parameter_extreme_min + ", " + res.data.parameter_extreme_max);
        });
    }

    editParameters(e) {
        if (!this.state.rawDataLoaded) {
            restAPI.get('/api/1/wells/' + this.wellId + "/dfitAnalysis").then(data => {
                this.setState({
                    rawDataLoaded: true,
                    rawData: data.raw
                });
            });
        }

        this.setState({
            gFunctionData: null,
            gFunctionDataLoaded: false,
            activeStep: 0
        });
    }

    doneStepOne(e) {
        e.preventDefault();

        var fip = this.state.fip; // parseInt($("#fip").val());
        var isip = this.state.isip; // parseInt($("#isip").val());

        if (fip > 0 && isip > 0) {
            restAPI.post("/api/1/wells/" + this.wellId + "/parameters", {
                fip_ts: this.state.fip,
                fip_elapsed_time: this.state.fip_elapsed,
                isip_ts: this.state.isip,
                isip_elapsed_time: this.state.isip_elapsed
            }).then(ret => {
                console.log("FIP Updated: " + ret.data.parameter_fip_ts + " - " + ret.data.parameter_fip_elapsed_time);
                console.log("ISIP Updated: " + ret.data.parameter_isip_ts + " - " + ret.data.parameter_isip_elapsed_time);
            });

            restAPI.post('/api/1/wells/' + this.wellId + "/gFunction", {
                "fip": fip,
                "isip": isip
            }).then(ret => {
                this.toggle('2');

                this.setState({
                    gFunctionData: ret.data.smoothed,
                    gFunctionDataLoaded: true,
                    activeStep: 1,
                    gFunctionX1: this.gFunctionX1_Current !== 1 ? this.gFunctionX1_Current : 1,
                    gFunctionY1: this.gFunctionY1_Current !== 1 ? this.gFunctionY1_Current : Math.max(...ret.data.smoothed["p"])
                });
            });
        }
    }

    changeGFunctionXY1(x1, y1) {
        this.gFunctionX1_Current = x1;
        this.gFunctionY1_Current = y1;

        restAPI.post("/api/1/wells/" + this.wellId + "/parameters", {
            gfunction_x: x1,
            gfunction_y: y1
        }).then(res => {
            var slope = y1 / x1;

            this.setState({
                gFunctionSlopeMessage: "Slope: " + slope.toFixed(2).toString(),
                gFunctionMessage: "Slope saved..."
            })

            var updateMessage = new Promise(resolve => setTimeout(resolve, 2500));

            updateMessage.then(() => {
                this.setState({
                    gFunctionMessage: ""
                })
            })

            console.log("Saved gfunction endpoint: " + x1 + ", " + y1);
        });
    }

    addSlopeOverlay() {
        // (1/2, 1/4, -3/4, -1/2 and -1)
    }

    removeSlopeOverlay() {

    }

    render() {

        const renderParameterPlot = (
            <ParameterPlot
                data={this.state.rawData}
                callbackSetFIP={this.changeFIP}
                callbackSetISIP={this.changeISIP}
                callbackSetExtremes={this.setParameterPlotExtremes}
                fip={this.state.fip}
                isip={this.state.isip}
                extremeMin={this.state.extremeMin}
                extremeMax={this.state.extremeMax}
            />
        )

        const renderGFunction = (
            <div className="card-body">
                <Row>
                    <Col md={6}>
                        <span>{this.state.gFunctionSlopeMessage}</span>
                        <span className="text-success" style={{ marginLeft: "10px" }}>{this.state.gFunctionMessage}</span>
                    </Col>
                </Row>

                <GFunctionPlot
                    data={this.state.gFunctionData}
                    x1={this.state.gFunctionX1}
                    y1={this.state.gFunctionY1}
                    callbackSetXY1={this.changeGFunctionXY1}
                />
            </div>
        )

        const renderLogLog = (
            <div className="card-body">
                <LogLogPlot
                    data={this.state.gFunctionData}
                />
            </div>
        )

        var parameterPlot;
        if (this.state.rawDataLoaded && this.state.parametersLoaded) {
            parameterPlot = renderParameterPlot;
        } else {
            parameterPlot = <span>Loading...</span>
        }

        var gFunctionTab;
        if (this.state.gFunctionDataLoaded) {
            gFunctionTab = renderGFunction;
        } else {
            gFunctionTab = <span>Loading...</span>
        }

        var loglogTab;
        if (this.state.gFunctionDataLoaded) {
            loglogTab = renderLogLog;
        } else {
            loglogTab = <span>Loading...</span>
        }

        const renderParameterSelection = (
            <div className="card-body">
                <Row>
                    <Col md={6}>
                        <h3>Parameter Selection</h3>
                    </Col>

                    <Col md={6} style={{ textAlign: "right" }}>
                        <button onClick={this.doneStepOne} className="btn btn-primary">Save Parameters/View Closure Plots <i className="fa fa-arrow-right"></i></button>
                    </Col>
                </Row>

                {parameterPlot}
            </div>
        )

        const renderTabs = (
            <>
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === '2' })}
                            onClick={() => { this.toggle('2'); }}
                        >
                            G-Function Analysis
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === '3' })}
                            onClick={() => { this.toggle('3'); }}
                        >
                            Log-Log Analysis
                        </NavLink>
                    </NavItem>

                    <li style={{ float: "right", marginLeft: "auto", marginRight: "20px" }}>
                        <button onClick={this.editParameters} className="btn btn-primary"><i className="fa fa-pencil" style={{ marginRight: "5px" }}></i> Edit Parameters</button>
                    </li>
                </Nav>

                <TabContent activeTab={this.state.activeTab} style={{ padding: 0, marginTop: 0 }}>
                    <TabPane tabId="2">
                        {gFunctionTab}
                    </TabPane>
                    <TabPane tabId="3">
                        {loglogTab}
                    </TabPane>
                </TabContent>

                {/*
                <div className="card-footer" style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="submit">Save</button>
                </div>
                */}
            </>
        )

        var activeScreen;

        if (this.state.activeStep === 0) {
            activeScreen = renderParameterSelection;
        } else {
            activeScreen = renderTabs;
        }

        if (this.state.isLoaded) {
            ReactGA.pageview("/analyze/" + this.state.job.name);

            return (
                <>
                    <Row style={{ marginBottom: 20 }}>
                        <Col md={12}>
                            <h2>{this.state.job.display_name} &nbsp;<small>Closure Analysis</small></h2>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={12}>
                            <div className="card card-default">
                                <div className="card-body">
                                    <form>
                                        <Row>
                                            <Col sm={6}>
                                                <Row>
                                                    <Col sm={12}>
                                                        <FormGroup>
                                                            <Label>Fracture Initiation/Breakdown Pressure Timestamp</Label>
                                                            <Input type="text" name="fip" id="fip" value={this.state.fip} readOnly />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm={6}>
                                                        <FormGroup>
                                                            <Input type="text" name="fip-display-elapsed" id="fip-display" value={this.state.fip_elapsed} readOnly />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={6}>
                                                        <FormGroup>
                                                            <Input type="text" name="fip-display-dt" id="fip-display-dt" value={this.state.fip_dt} readOnly />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </Col>

                                            <Col sm={6}>
                                                <Row>
                                                    <Col sm={12}>
                                                        <FormGroup>
                                                            <Label>Instantaneous Shut-In Pressure Timestamp</Label>
                                                            <Input type="text" name="isip" id="isip" value={this.state.isip} readOnly />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm={6}>
                                                        <FormGroup>
                                                            <Input type="text" name="fip-display-elapsed" id="fip-display" value={this.state.isip_elapsed} readOnly />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={6}>
                                                        <FormGroup>
                                                            <Input type="text" name="fip-display-dt" id="fip-display-dt" value={this.state.isip_dt} readOnly />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12}>
                                                {(this.state.updatedTs && this.state.updatedUsername) ?
                                                    (<small className="text-muted">Last updated 2019-07-10T06:49:06.000Z by Kyle Thomas</small>) : (<small></small>)
                                                }
                                            </Col>
                                        </Row>
                                    </form>
                                </div>

                                {activeScreen}
                            </div>
                        </Col>
                    </Row>




                </>
            )
        } else {
            return (
                <>
                </>
            )
        }
    }
}