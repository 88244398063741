import React, { Component } from "react";
import AlertModal from "./AlertModal";
import * as restAPI from '../../../../../common/utilities/ApiService';

export default class AlertListTab extends Component {

    constructor(props) {
        super(props);

        this.state = {
            // This is the current alert being edited
            working_alert: {},
            working_alert_id: null,
            working_alert_key: 0,
            showModal: false
        };

        this.duplicateRow = this.duplicateRow.bind(this);
        this.editRow = this.editRow.bind(this);
        this.saveAlert = this.saveAlert.bind(this);
        this.showCreateAlertModal = this.showCreateAlertModal.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
    }

    async componentDidMount() {

    }

    showCreateAlertModal() {
        // TODO: ?
        let working_alert_key = this.state.working_alert_key + 1;

        this.setState({
            working_alert_key: working_alert_key,
            working_alert: {},
            working_alert_id: null,
            showModal: true
        });
    }

    editRow(alert, isDelete = false) {
        let working_alert_key = this.state.working_alert_key + 1;
        if (isDelete) {
            restAPI.post('/api/1/wells/alerts/' + alert.id, {
                "operation": "DELETE"
            }).then(res => {
                this.props.refreshAlerts();
            }).catch(err => {
                console.log(err);
            });
        } else {
            //edit alerts in progress
            let working_alert = {
                subject: alert.subject,
                set_point_value: alert.set_point_value,
                refactory_period: alert.refactory_period,
                users: alert.users,
                email_list: alert.email_list,
                phone_list: alert.phone_list,
                job_id: alert.job_id,
                field_name: alert.field_name,
                field: alert.field,
                condition: alert.condition,
                user_list: alert.user_list,
                id: alert.id
            };
            this.setState({
                working_alert: working_alert,
                working_alert_id: alert.id,
                working_alert_key: working_alert_key,
                showModal: true
            })
        }
    }

    duplicateRow(alert) {
        let working_key = this.state.working_alert_key + 1;
        let working_alert = {
            subject: alert.subject,
            set_point_value: alert.set_point_value,
            refactory_period: alert.refactory_period,
            users: alert.users,
            email_list: alert.email_list,
            phone_list: alert.phone_list,
            job_id: alert.job_id,
            field_name: alert.field_name,
            field: alert.field,
            condition: alert.condition,
            user_list: alert.user_list
        };

        this.setState({
            working_alert: working_alert,
            working_alert_id: null,
            working_alert_key: working_key,
            showModal: true
        })
    }

    saveAlert(workingAlertId, alertFormValues) {
        let t_alert = alertFormValues;
        t_alert.id = workingAlertId;

        restAPI.post('/api/1/wells/alerts', t_alert).then(res => {
            // Force an update to the list of alerts since we've saved one
            this.props.refreshAlerts();

            // Clear form values
            this.setState({
                working_alert: {},
                working_alert_id: null,
                showModal: false
            })
        });


    }

    toggleModal() {
        this.setState(prevState => ({
            working_alert_id: null,
            working_alert: {},
            // working_alert_key: null,
            showModal: !prevState.showModal
        }));
    }

    render() {
        // Notes: 
        //   - this.props.availableUsers contains the list of availableUsers
        //   - this.props.availableJobs contains the list of availableJobs
        //   - this.props.formReady is true or false based on whether all the jobs/users are 
        //     done loading (in case its needed, it may not need to be used)


        return (
            <>
                {/* TODO: Create alert button that calls this.showCreateAlertModal() */}
                <button className="float-right btn btn-sm btn-success" onClick={this.showCreateAlertModal}> Add New Alert </button>

                <AlertModal
                    show={this.state.showModal}
                    availableJobs={this.props.availableJobs}
                    availableUsers={this.props.availableUsers}
                    initialAlertFormValues={this.state.working_alert}
                    currentAlertId={this.state.working_alert_id}
                    saveAlert={this.saveAlert}
                    toggleModal={this.toggleModal}
                    key={this.state.working_alert_key} />

                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>Well</th>
                            <th>Alert Subject</th>
                            <th>Users</th>
                            <th>Phone Numbers</th>
                            <th style={{width: '15%'}}>Email List</th>
                            <th>Criteria</th>
                            <th style={{width: '150px'}}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.alerts.map(function (alert) {
                            return (
                                <tr key={alert.id}>
                                    <td>{alert.job.name}</td>
                                    <td>{alert.subject}</td>
                                    <td>
                                        {alert.users.map(u => {
                                            return (
                                                <span key={u.id} style={{ marginRight: "5px" }}>{u.name}</span>
                                            )
                                        })}
                                    </td>
                                    <td>{alert.phone_list.split("|").join(", ")}</td>
                                    <td>{alert.email_list.split("|").join(", ")}</td>
                                    <td>
                                        {alert.field_name}
                                        {alert.condition_display}
                                        {alert.set_point_value}
                                    </td>
                                    <td style={{ textAlign: "right" }}>
                                        <button onClick={() => this.duplicateRow(alert)} className="btn-xs btn-success" style={{ marginRight: 20 }} title="Duplicate"><i className="fa fa-copy"></i></button>
                                        <button onClick={() => this.editRow(alert, false)} className="btn-xs btn-primary" style={{ marginRight: 20 }} title="Edit"><i className="fa fa-pencil"></i></button>
                                        <button onClick={() => this.editRow(alert, true)} className="btn-xs btn-danger" title="Delete"><i className="fa fa-times"></i></button>
                                    </td>
                                </tr>
                            )
                        }, this)}
                    </tbody>
                </table>
            </>
        );
    }

}

