import React from 'react';
import {
    Row, Col, Button, FormGroup,
    Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';
import Select from 'react-select';
import CreatableSelect from 'react-select/lib/Creatable';
import FormElement from "../Core/Elements/FormElement";

import * as restAPI from '../../../../common/utilities/ApiService';
import ReactGA from 'react-ga';

var fieldList = [
    { value: "pressure", label: "Pressure" },
    { value: "rate", label: "Rate" },
    { value: "temperature", label: "Temperature" },
];

var conditionTypeList = [
    { value: 1, label: ">" },
    { value: 2, label: ">=" },
    { value: 3, label: "<" },
    { value: 4, label: "<=" },
    { value: 5, label: "Change > x in 1 minute" },
    { value: 6, label: "Change > x in 1 hour" },
]

const components = {
    DropdownIndicator: null,
};

const createOption = (label) => ({
    label,
    value: label,
});


export default class Alerts extends React.Component {
    constructor(req) {
        super()

        this.wellId = req.match.params.wellId;

        this.state = {
            isLoaded: false,
            alerts: [],

            alert: {
                id: null,
                job_id: this.wellId,
                subject: null,
                user_list: null,
                phone_list: null,
                email_list: null,
                field_name: null,
                condition: null,
                set_point_value: null,
                refractory_period: 60
            },

            availableFields: [],
            availableConditionTypes: [],
            availableUsers: [],

            selectedUsers: [],

            inputEmail: "",
            emailList: [],

            inputPhone: "",
            phoneList: [],

            selected_condition: null,
            selected_field_name: null,
            state_count: 1,
            modal: false
        }

        this.toggle = this.toggle.bind(this);
        this.newAlertModal = this.newAlertModal.bind(this);
        this.handleModalInputChange = this.handleModalInputChange.bind(this);
        this.handleAlertUsersChange = this.handleAlertUsersChange.bind(this);
        this.handleAlertEmailsChange = this.handleAlertEmailsChange.bind(this);
        this.handleAlertPhonesChange = this.handleAlertPhonesChange.bind(this);

        this.handleFieldChange = this.handleFieldChange.bind(this);
        this.handleConditionChange = this.handleConditionChange.bind(this);

        this.submitAlert = this.submitAlert.bind(this);
        this.editRow = this.editRow.bind(this);
    }

    async componentDidMount() {
        await restAPI.get('/api/1/wells/alerts?wellId=' + this.wellId).then(res => {
            this.setState({ alerts: res.alerts });
        });


        await restAPI.get('/api/1/users?orgFilter=1').then(res => {
            this.setState({ availableUsers: res.users });
        });

        this.setState({
            availableFields: fieldList,
            availableConditionTypes: conditionTypeList
        })
    }

    handleModalInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        var alert = this.state.alert;

        alert[name] = value;

        this.setState({
            alert: alert
        });
    }

    handleFieldChange(field) {
        var alert = this.state.alert;

        alert.field_name = field.value;

        this.setState({
            alert: alert,
            selected_field_name: field
        });
    }

    handleConditionChange(condition) {
        var alert = this.state.alert;

        alert.condition = condition.value;

        this.setState({
            alert: alert,
            selected_condition: condition
        });
    }

    handleAlertUsersChange(selectedUserArray) {
        this.setState({
            selectedUsers: selectedUserArray,
        });
    }

    handleAlertEmailsChange = (emailList, actionMeta) => {
        this.setState({ emailList });
    };

    handleAlertEmailsInputChange = (inputEmail) => {
        this.setState({ inputEmail });
    };

    handleAlertEmailsKeyDown = (event) => {
        const { inputEmail, emailList } = this.state;

        if (!inputEmail) return;

        switch (event.key) {
            case 'Enter':
            case 'Tab':
                // if (isValidEmail(inputEmail))
                if (inputEmail) {
                    this.setState({
                        inputEmail: '',
                        emailList: [...emailList, createOption(inputEmail)],
                    });
                }

                event.preventDefault();
                break
            default: break
        }
    };

    handleAlertPhonesChange = (phoneList, actionMeta) => {
        this.setState({ phoneList });
    };

    handleAlertPhonesInputChange = (inputPhone) => {
        this.setState({ inputPhone });
    };

    handleAlertPhonesKeyDown = (event) => {
        const { inputPhone, phoneList } = this.state;

        if (!inputPhone) return;

        switch (event.key) {
            case 'Enter':
            case 'Tab':
                // if (isValidPhone(inputPhone))
                if (inputPhone) {
                    this.setState({
                        inputPhone: '',
                        phoneList: [...phoneList, createOption(inputPhone)],
                    });
                }

                event.preventDefault();
                break
            default: break
        }
    };



    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    newAlertModal() {

        this.setState({
            alert: {
                id: null,
                job_id: this.wellId,
                subject: null,
                user_list: null,
                phone_list: null,
                email_list: null,
                field_name: null,
                condition: null,
                set_point_value: null,
                refractory_period: 60
            },
            emailList: [],
            phoneList: [],
            selectedUsers: [],
            state_count: this.state.state_count + 1,
            selected_condition: null,
            selected_field_name: null
        });

        this.toggle();
    }

    editRow(a, isDelete = false) {
        if (isDelete) {
            restAPI.post('/api/1/wells/alerts/' + a.id, {
                "operation": "DELETE"
            }).then(res => {
                restAPI.get('/api/1/wells/alerts?wellId=' + this.wellId).then(res => {
                    this.setState({
                        alerts: res.alerts,
                        modal: false
                    });
                }).catch(err => {
                    console.log(err);
                })
            }).catch(err => {
                console.log(err);
            });
        } else {
            // Populate modal values
            console.log(a)

            var selectedUsers = [];
            var sUs = a.user_list.split("|").filter(u => u.length > 0);

            sUs.forEach(sU => {
                this.state.availableUsers.forEach(au => {
                    if (au.id === parseInt(sU)) {
                        selectedUsers.push({
                            key: au.id,
                            label: au.name,
                            value: au.id
                        })
                    }
                })
            });

            var selected_condition = conditionTypeList.filter(ct => ct.value === a.condition);

            var selected_field = fieldList.filter(f => f.value === a.field_name)

            this.setState({
                alert: {
                    id: a.id,
                    job_id: a.job_id,
                    subject: a.subject,
                    user_list: a.user_list,
                    phone_list: a.phone_list,
                    email_list: a.email_list,
                    field_name: a.field_name,
                    condition: a.condition,
                    set_point_value: a.set_point_value,
                    refractory_period: a.refractory_period,
                },
                emailList: a.email_list.split("|").filter(e => e.length).map(e => createOption(e)),
                phoneList: a.phone_list.split("|").filter(p => p.length).map(p => createOption(p)),
                selectedUsers: selectedUsers,
                state_count: this.state.state_count + 1,
                selected_condition: selected_condition,
                selected_field_name: selected_field
            })

            this.toggle();

        }
    }

    submitAlert(e) {
        e.preventDefault();
        var email_list_string = this.state.emailList.map(ed => {
            return ed.value;
        }).join("|");
  
        var phone_list_string = this.state.phoneList.map(ed => {
            return ed.value;
        }).join("|");
    
        var user_list_string = this.state.selectedUsers.map(ed => {
            return ed.value;
        }).join("|");
        
        this.setState({
            email_list : email_list_string,
            phone_list : phone_list_string,
            user_list : user_list_string
        });

        const alertPayload = {
            ...this.state.alert_template,
            email_list : email_list_string,
            phone_list: phone_list_string,
            user_list: user_list_string
        }

        restAPI.post('/api/1/wells/alerts', alertPayload).then(res => {
            restAPI.get('/api/1/wells/alerts?wellId=' + this.wellId).then(res => {
                this.setState({
                    alerts: res.alerts,
                    modal: false
                });
            }).catch(err => {
                console.log(err);
            })
        }).catch(err => {
            console.log(err);
        });
    }

    render() {
        ReactGA.pageview("/alerts");

        return (
            <>
                <Row>
                    <Col md={12}>
                        <div className="card card-default">
                            <div className="card-header">
                                <span className="card-title">Alerts</span>
                                <button className="float-right btn btn-sm btn-success" onClick={this.newAlertModal}>
                                    Add New Alert
                                </button>
                            </div>
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th>Alert Subject</th>
                                        <th>Users</th>
                                        <th>Phone Numbers</th>
                                        <th>Email List</th>
                                        <th>Criteria</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.alerts.map(function (alert) {
                                        return (
                                            <tr key={alert.id}>
                                                <td>{alert.subject}</td>
                                                <td>
                                                    {alert.users.map(u => {
                                                        return (
                                                            <span key={u.id} style={{ marginRight: "5px" }}>{u.name}</span>
                                                        )
                                                    })}
                                                </td>
                                                <td>{alert.phone_list}</td>
                                                <td>{alert.email_list}</td>
                                                <td>
                                                    {alert.field_name}
                                                    {alert.condition_display}
                                                    {alert.set_point_value}
                                                </td>
                                                <td style={{ textAlign: "center" }}>
                                                    <button onClick={() => this.editRow(alert, false)} className="btn btn-sm btn-primary" style={{ marginRight: 20 }}>Edit</button>
                                                    <button onClick={() => this.editRow(alert, true)} className="btn btn-sm btn-danger">Delete</button>
                                                </td>
                                            </tr>
                                        )
                                    }, this)}
                                </tbody>

                            </table>
                        </div>
                    </Col>
                </Row>

                <div>
                    <Modal isOpen={this.state.modal} toggle={this.toggle} className="modal-lg">
                        <ModalHeader toggle={this.toggle}>Create Alert</ModalHeader>
                        <ModalBody>
                            <form className="form-horizontal" onSubmit={this.onSubmit}>
                                <FormElement label="Subject"
                                    placeholder="Alert Subject (included in alert messages)"
                                    value={this.state.alert.subject}
                                    name="subject"
                                    onChange={this.handleModalInputChange} />

                                <FormElement label="Refractory Period (Mins)"
                                    placeholder="60"
                                    value={this.state.alert.refractory_period}
                                    name="refractory_period"
                                    onChange={this.handleModalInputChange} />

                                <FormGroup row>
                                    <label className="col-xl-2 col-form-label">Users</label>
                                    <div className="col-xl-10">
                                        <Select
                                            isMulti
                                            className="form-control form-select-element"
                                            placeholder="Select users"
                                            value={this.state.selectedUsers}
                                            options={this.state.availableUsers.map(user => ({
                                                key: user.id,
                                                label: user.name,
                                                value: user.id
                                            }))}
                                            onChange={this.handleAlertUsersChange} />
                                    </div>
                                </FormGroup>

                                <FormGroup row>
                                    <label className="col-xl-2 col-form-label">Emails</label>
                                    <div className="col-xl-10">

                                        <CreatableSelect
                                            components={components}
                                            inputValue={this.state.inputEmail}
                                            isClearable
                                            isMulti
                                            menuIsOpen={false}
                                            onChange={this.handleAlertEmailsChange}
                                            onInputChange={this.handleAlertEmailsInputChange}
                                            onKeyDown={this.handleAlertEmailsKeyDown}
                                            placeholder="Type an email and press enter"
                                            value={this.state.emailList}
                                        />
                                    </div>
                                </FormGroup>

                                <FormGroup row>
                                    <label className="col-xl-2 col-form-label">Phone Numbers</label>
                                    <div className="col-xl-10">
                                        <CreatableSelect
                                            components={components}
                                            inputValue={this.state.inputPhone}
                                            isClearable
                                            isMulti
                                            menuIsOpen={false}
                                            onChange={this.handleAlertPhonesChange}
                                            onInputChange={this.handleAlertPhonesInputChange}
                                            onKeyDown={this.handleAlertPhonesKeyDown}
                                            placeholder="Type a phone number and press enter..."
                                            value={this.state.phoneList}
                                        />
                                    </div>
                                </FormGroup>
                                <hr />
                                <FormGroup row>
                                    <label className="col-xl-2 col-form-label">Field</label>
                                    <div className="col-xl-10">
                                        <Select
                                            key={this.state.state_count}
                                            className="form-control form-select-element"
                                            placeholder="Select field"
                                            value={this.state.selected_field_name}
                                            options={fieldList.map(element => ({
                                                key: element.value,
                                                label: element.label,
                                                value: element.value
                                            }))}
                                            onChange={this.handleFieldChange} />
                                    </div>
                                </FormGroup>

                                <FormGroup row>
                                    <label className="col-xl-2 col-form-label">Comparison Type</label>
                                    <div className="col-xl-10">
                                        <Select
                                            className="form-control form-select-element"
                                            placeholder="Select field"
                                            value={this.state.selected_condition}
                                            options={conditionTypeList.map(element => ({
                                                key: element.value,
                                                label: element.label,
                                                value: element.value
                                            }))}
                                            onChange={this.handleConditionChange} />
                                    </div>
                                </FormGroup>

                                <FormElement label="Set Point"
                                    placeholder="Set Point Value"
                                    value={this.state.alert.set_point_value}
                                    name="set_point_value"
                                    onChange={this.handleModalInputChange} />

                                <button className="btn btn-block btn-primary mt-3" onClick={this.submitAlert}>Submit</button>
                            </form>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={this.toggle}>Cancel</Button>
                        </ModalFooter>
                    </Modal>
                </div>
            </>
        )
    }
}