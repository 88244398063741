
export const ON_HOVER_CIRCLE_RADIUS = 3.5
const GRAPH_MARGIN_MULTIPLIER = 0.05

export const getMarginedExtent = ([min, max]) => {
    //given extent will return that adjusted extent to incllulde graph margins
    const range = max - min
    const graphMargin = (range * GRAPH_MARGIN_MULTIPLIER)
    const adjustedMin = min - graphMargin
    const adjustedMax = max + graphMargin
    return [adjustedMin, adjustedMax]
}

export const roundToThousandthsPlace = (num, nullValue) => (num === null || num === undefined) ? nullValue : Math.round(num * 1000) / 1000

export const formatTickLabel = (tick) => {
    // decimaled value
    const floatValue = parseFloat(tick).toFixed(3)
    // get first 8 digits + 1 decimal
    const splicedTick = floatValue.toString().slice(0, 9)
    // return tick as number
    return +splicedTick;
}