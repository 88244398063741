import React from 'react';

export default class Detail extends React.Component {
    constructor(req) {
        super()

        this.wellId = req.match.params.wellId;

        this.state = {}
    }

    async componentDidMount() {
    }

    render() { return <> Detail </> }
}