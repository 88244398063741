
import { Row, Col } from 'reactstrap'
import { GraphCard } from './GraphCard'
import { IconButton } from '../Core/Shared'
import { useContextMenu } from 'react-contexify'
import React, { useEffect, useState } from 'react'
import { GraphConfigModal } from './GraphConfigModal'
import { DataManagementContextProvider } from '../../Contexts/DataManagementContext'

export const GraphTile = ({
    tile,
    canEdit,
    all_jobs,
    rowWidth,
    removeTile,
    rowOrdinal,
    dashboard_id,
    updateGraphTile,
    x_axis_units_of_measure,
}) => {
    const [showModal, setShowModal] = useState(false)

    const graph_id = tile?.graph?.id || `r${rowOrdinal}t${tile.ordinal}`

    useEffect(() => {
        if (tile.graph && !tile.graph.temp_id) {
            updateGraph({
                ...tile.graph,
                temp_id: graph_id
            })
        }
    }, [tile.graph])

    const updateGraph = (graph) => {
        const newTileState = {
            ...tile,
            graph: { ...graph }
        }
        updateGraphTile(newTileState)
        setShowModal(false)
    }

    const updateLinkedGroup = (newGroupId) => {
        const newGraphState = {
            ...tile.graph,
            linked_group: newGroupId
        }
        updateGraph(newGraphState)
    }

    const { show } = useContextMenu({ id: `rightClickMenu-${graph_id}` })

    const openConfigurationForm = () => {
        setShowModal(true)
    }

    const configureGraph = () => {
        setShowModal(true)
    }

    const graphTileWidth = tile.size === 2 ? rowWidth - 2 : (rowWidth - 2) / 2
    return <Row style={{ height: '100%', }}>
        <Col className='graphTileContainer' id={graph_id}>
            {tile.graph
                ? <DataManagementContextProvider>
                    <GraphCard
                        canEdit={canEdit} show={show} tile={tile}
                        graph_id={graph_id} showModal={showModal} updateGraph={updateGraph} updateLinkedGroup={updateLinkedGroup} setShowModal={setShowModal}
                        dashboard_id={dashboard_id}
                        openConfigurationForm={openConfigurationForm} all_jobs={all_jobs} x_axis_units_of_measure={x_axis_units_of_measure}
                        removeTile={removeTile}
                        graphTileWidth={graphTileWidth}
                    />
                </DataManagementContextProvider>
                : <>
                    <IconButton
                        icon={'fa-edit'}
                        className='hoverableEditButton'
                        onClick={configureGraph}
                        style={{ fontSize: '4em' }}
                    />
                    <h3>Configure Graph</h3>
                    {/* New Graph Modal */}
                    <GraphConfigModal
                        showModal={showModal} setShowModal={setShowModal}
                        graph={tile.graph} graph_id={graph_id} all_jobs={all_jobs}
                        x_axis_units_of_measure={x_axis_units_of_measure}
                        onSubmitGraphConfig={updateGraph}
                    />
                </>}
        </Col>
    </Row>
}