import LoginConstants from './LoginConstants.js'
import AppDispatcher from '../../AppDispatcher.js'
//import RouterContainer from '../services/RouterContainer'

const persistTokenToLocalStorage = (encodedUserToken) => {
    const savedEncodedUserToken = localStorage.getItem('encodedUserToken')
    if (savedEncodedUserToken !== encodedUserToken) {
        localStorage.setItem('encodedUserToken', encodedUserToken)
    }
}

export default {
    loginUser: (encodedUserToken) => {
        const decodedUserToken = JSON.parse(encodedUserToken)

        const now = new Date()
        const tokenExpiration = new Date(decodedUserToken.token.expiresIn)
        if (now > tokenExpiration) {
            localStorage.removeItem('encodedUserToken');
            return false;
        } else {
            AppDispatcher.dispatch({
                actionType: LoginConstants.LOGIN_USER,
                encodedUserToken: encodedUserToken
            });

            persistTokenToLocalStorage(encodedUserToken)
        }
    },
    logoutUser: () => {
        // RouterContainer.get().transitionTo('/login');
        localStorage.removeItem('encodedUserToken');
        AppDispatcher.dispatch({
            actionType: LoginConstants.LOGOUT_USER
        });
    },
    setUserCurrentOrg: (updatedToken) => {
        persistTokenToLocalStorage(updatedToken)
        AppDispatcher.dispatch({
            actionType: LoginConstants.SET_USER_CURRENT_ORG,
            updatedToken
        });
    }
}
