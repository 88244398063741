import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from "reactstrap";
import React from 'react';
import { SuccessButton, DangerButton } from '../Core/Shared';

export const ModalBase = ({ isOpen, setIsOpen, onCancel, onSubmit, titleText = 'Attention:',
    bodyContent, cancelButtonText = 'Cancel', submitButtonText = 'Submit',
    customModalStyle = {}, isSubmitButtonDisabled }) => {

    const ModalHeaderCloseButton = ({ onClick }) => <button className="close" onClick={onClick}> × </button>
    const closeModal = () => setIsOpen(false)

    const onClickCancel = () => {
        onCancel && onCancel()
        closeModal()
    }
    const onClickSubmit = () => {
        onSubmit && onSubmit()
        closeModal()
    }

    return !isOpen
        ? <></>
        : <Modal
            centered
            isOpen={isOpen}
            className='modal-dialog'
            contentClassName='custom-modal-style'
            style={{ maxWidth: '95vw', width: 'fit-content', marginTop: '0', ...customModalStyle }}
        >
            <ModalHeader
                children={<h1>{titleText}</h1>}
                close={<ModalHeaderCloseButton onClick={closeModal} />}
            />

            <ModalBody>
                {bodyContent}
            </ModalBody>

            <ModalFooter>
                <DangerButton
                    text={cancelButtonText}
                    onClick={onClickCancel}
                />
                <SuccessButton
                    text={submitButtonText}
                    onClick={onClickSubmit}
                    disabled={isSubmitButtonDisabled}
                />
            </ModalFooter>
        </Modal>
}