import React, { useState, useContext } from 'react'
import { Popover, PopoverBody, PopoverHeader, Button } from 'reactstrap'
import { IconButton } from '../Core/Shared'
import { CirclePicker } from '../Core/ReactColor/CirclePicker'
import { DashboardingFormContext } from '../../Contexts/DashboardingFormContext'
import { DarkModeContext } from '../../theme/DarkModeContext'

function GraphLinkForm({ graph_id, updateLinkedGroup, graph, canEdit }) {

  const isDark = useContext(DarkModeContext)
  const [showGraphLinkForm, setShowGraphLinkForm] = useState(false)
  const linkid = 'Popover-' + graph_id

  const { formState, setValueForLinkedGroup } = useContext(DashboardingFormContext)

  const updateThisGraphsLinkedGroupSharedValue = (graphId, currentLinkedGroupId, newLinkedGroupId) => {
    updateLinkedGroup(newLinkedGroupId)

    const otherExistingLinkedGroupGraph = (linkedGroupId) => {
      const linkedGroupGraphs = formState?.rows?.flatMap(r => r.tiles.flatMap(t => t.graph)).filter(graph => graph?.linked_group === linkedGroupId);
      return linkedGroupGraphs.find(graph => graph?.id !== graphId && graph?.temp_id !== graphId);
    }

    const getNewLinkedGroupValue = () => {
      const timeWindow = graph.time_amount * graph.unit_of_time?.duration * 1000
      const endOfWindow = graph.lastStreamed
      const startOfWindow = isNaN(timeWindow) ? graph.firstStreamed : endOfWindow - timeWindow
      return graph.xScaleDomain ? graph.xScaleDomain : [startOfWindow, endOfWindow]

    }

    if (!currentLinkedGroupId && newLinkedGroupId) { // null to something
      const doesOtherNewLinkedGroupGraphExist = otherExistingLinkedGroupGraph(newLinkedGroupId)
      const newLinkedGroupValue = getNewLinkedGroupValue()
      // if no graph, update new value
      !doesOtherNewLinkedGroupGraphExist && setValueForLinkedGroup(newLinkedGroupId, newLinkedGroupValue)
    } else if (currentLinkedGroupId && newLinkedGroupId) { // something to something
      const doesOtherNewLinkedGroupGraphExist = otherExistingLinkedGroupGraph(newLinkedGroupId)
      const newLinkedGroupValue = getNewLinkedGroupValue()
      // if no graph, update new value
      !doesOtherNewLinkedGroupGraphExist && setValueForLinkedGroup(newLinkedGroupId, newLinkedGroupValue)
      const doesOtherCurrentLinkedGroupGraphExist = otherExistingLinkedGroupGraph(currentLinkedGroupId)
      // if no current graph reset
      !doesOtherCurrentLinkedGroupGraphExist && setValueForLinkedGroup(currentLinkedGroupId, [])
    } else if (currentLinkedGroupId && !newLinkedGroupId) { // something to null
      const doesOtherCurrentLinkedGroupGraphExist = otherExistingLinkedGroupGraph(currentLinkedGroupId)
      // if no current graph reset
      !doesOtherCurrentLinkedGroupGraphExist && setValueForLinkedGroup(currentLinkedGroupId, [])
    }
  };

  const handleLinkGraphClick = (e) => {
    e.stopPropagation()
    setShowGraphLinkForm(!showGraphLinkForm)
  }

  const handleSelectLinkedGroup = (linked_id) => {
    updateThisGraphsLinkedGroupSharedValue(graph_id, graph.linked_group, linked_id);
    setShowGraphLinkForm(false)
  }

  const handleUnlinkGraph = () => {
    updateThisGraphsLinkedGroupSharedValue(graph_id, graph.linked_group, null);
    setShowGraphLinkForm(false)
  }

  let colors = ["#ff0000", "#ff8300", "#ebc934", "#388e4b", "#002ce1", "#7000e1", "#e100d0", "#00bcd4"]

  let linkedColor = colors[graph.linked_group - 1]

  const linkedGroup = <div style={{ fontSize: '1em', position: 'absolute', right: '60px', padding: '2px 3px 3px 5px', backgroundColor: linkedColor, borderRadius: '50%', width: '25px', color: 'white', fontWeight: 'bolder' }}>{graph.linked_group}</div>;


  return (canEdit
    ? <>
      {linkedGroup}
      <IconButton
        id={linkid}
        icon='fa-solid fa-link'
        className='hoverableIconButton'
        onClick={handleLinkGraphClick}
        style={{
          fontSize: '1.5em', position: 'absolute', top: '0px', right: '30px', padding: '2px 3px 3px 5px', backgroundColor: 'aliceblue',
          borderRadius: '4px'
        }}
      />

      <Popover
        trigger='legacy'
        placement='top'
        target={linkid}
        isOpen={showGraphLinkForm}
        toggle={handleLinkGraphClick}
      >
        <PopoverHeader style={{ backgroundColor: '#3d67a0', color: 'white', textAlign: 'center' }}>
          Graph Linking
        </PopoverHeader>
        <PopoverBody style={{ paddingLeft: '20px' }}>
          Select a color group to link to
          <br></br>
          <br></br>
          <div style={{ paddingLeft: '15px' }}>
            <CirclePicker
              colors={colors}
              onCircleClick={handleSelectLinkedGroup}
              circleSpacing={15}
              width={'200px'} />
            <br />
          </div>
          <div style={{ paddingLeft: '25px', zIndex: 999 }}>
            <Button style={{ paddingLeft: '10px', backgroundColor: 'aliceblue' }} onClick={handleUnlinkGraph}>
              <IconButton
                icon='fa-solid fa-chain-broken'
                className='unlinkGraphButton'
                onClick={handleUnlinkGraph}
                style={{
                  fontSize: '1.4em', position: 'center', top: '0px', right: '0px', padding: '3px 3px 3px 5px', backgroundColor: 'aliceblue',
                  borderRadius: '4px'
                }}
              />
              &nbsp;&nbsp;<div style={{color: isDark ? "black" : "white", fontSize: '1.4em'}}>Unlink Graph</div>
            </Button>
          </div>
        </PopoverBody>
      </Popover>
    </>
    : <>{linkedGroup}</>
  )
}
export default GraphLinkForm


