import React, { useState } from 'react'
import { FormTextInput } from '../Core/FormInputs'
import Select from 'react-select'
import { Alert, Col, Row } from 'reactstrap'

export const XAxisParameterControl = ({ graph, units_of_time, updateLocalStateGraph }) => {

    const configTimeDurationValue = graph.time_amount ?? null
    const configUoTSelectValue = units_of_time.find(UoT => UoT.id === graph?.unit_of_time?.id) ?? null

    const [isInputInteger, setIsInputsInteger] = useState(true)
    const [localTimeDuration, setLocalTimeDuration] = useState(configTimeDurationValue)
    const [localUnitOfTime, setLocalUnitOfTime] = useState(configUoTSelectValue)

    const selectStyles = {
        container: (base, state) => ({
            ...base,
        })
    };

    const handleTimeAmountChange = (e) => {
        const time_amount = e.target.value.trim() // remove spacebar input

        if (time_amount.length === 0) {
            setIsInputsInteger(true)
            setLocalTimeDuration(null)
            updateLocalStateGraph('time_amount', null);
            setLocalUnitOfTime(null);
            updateLocalStateGraph('unit_of_time', null);

        } else {
            setIsInputsInteger(!isNaN(time_amount) && time_amount > 0)
            setLocalTimeDuration(time_amount)
            updateLocalStateGraph('time_amount', time_amount)
        }
    }

    const handleUnitOfTimeChange = (selectedUnitObj) => {
        setLocalUnitOfTime(selectedUnitObj);
        updateLocalStateGraph('unit_of_time', selectedUnitObj)
    }

    return (
        <Row style={{ paddingTop: '1em' }}>
            <Col>
                <FormTextInput
                    label='X-Axis Time Amount'
                    value={localTimeDuration}
                    placeholder='Enter Time Amount as Integer'
                    onChange={(e) => handleTimeAmountChange(e)}
                />
                {isInputInteger ? null :
                    <Alert color="danger">
                        Value Entered is not valid
                    </Alert>
                }
            </Col>
            <Col>
                <label htmlFor='uom'>X-Axis Unit of Measurement</label>
                <Select
                    inputId='uom'
                    options={units_of_time}
                    value={localUnitOfTime}
                    onChange={(selectedUnitObj) => handleUnitOfTimeChange(selectedUnitObj)}
                    styles={selectStyles}
                    isDisabled={!localTimeDuration}
                />
            </Col>
        </Row>
    )
}
