
import React, { Component } from "react";
import { HashRouter } from 'react-router-dom';

import Routes from './Routes';

import LoginActions from '../../common/modules/Auth/LoginActions';

import ReactGA from 'react-ga';

// Vendor dependencies
import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@blueprintjs/popover2/lib/css/blueprint-popover2.css'

// Application Styles
import '../../common/styles/bootstrap.scss';
import '../../common/styles/app.scss';

import {DarkModeProvider} from './theme/DarkModeContext'

let encodedUserToken = localStorage.getItem('encodedUserToken');

if (encodedUserToken) {
    LoginActions.loginUser(encodedUserToken);
}

ReactGA.initialize('UA-126736160-2', {
    /* debug: true */
});

class App extends Component {

    render() {
        return (         
        <DarkModeProvider>  
            <HashRouter>
                <Routes />
            </HashRouter>
        </DarkModeProvider> 
        );
    }
}

export default App;