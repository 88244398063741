import { SetYScaleModal } from '../SetYScaleModal'
import { Menu, Item, Submenu } from 'react-contexify'
import React, {useState } from 'react'

export const LineGraphContextMenu = ({ 
    graph,
    graph_id, 
    openConfigurationForm, 
    updateGraph, 
    canEdit,
    isDataStreamPaused, 
    openGraphDeletionModal, 
    toggleIsDataStreamPaused,
    resetGraph,

    xAxisZoomOut,
    autoScale,
}) => {

    const [yAxis, setYAxis] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModalContext = (axis) => {
        setYAxis(axis)
        setIsModalOpen(true)
    }

    return <>
    <Menu id={`rightClickMenu-${graph_id}`}>
        {graph.id &&
            <Item onClick={resetGraph}>
                Reset Graph
            </Item>
        }
        {/* hidingLiveData*/}
        {/* {graph.show_live_data &&
            <Item onClick={toggleIsDataStreamPaused}>
                {isDataStreamPaused ? 'Resume Live Data' : 'Pause Live Data'}
            </Item>
        } */}
        <Item onClick={({ event }) => openConfigurationForm(event)}>
            Open Configuration Form
        </Item>
        <Item onClick={xAxisZoomOut}>
            X-Axis Zoom Out
        </Item>
        <Submenu label='Set Y Scale'>
            {graph.axes.map((y, idx) =>
                <Item
                    key={idx}
                    onClick={() => openModalContext(y)}
                >
                    <span style={{ color: y.color }}>Axis {idx + 1}</span>
                </Item>
            )}
        </Submenu>
        <Item onClick={autoScale}>
            Auto-Scale Graph
        </Item>
        {canEdit &&
            <Item onClick={openGraphDeletionModal}>
                Delete Graph
            </Item>
        }
    </Menu>  
    {isModalOpen && 
        <SetYScaleModal 
            setIsModalOpen={setIsModalOpen} 
            yAxisOrdinal={yAxis.ordinal} 
            graph={graph} 
            updateGraph={updateGraph} />}
    </>
}