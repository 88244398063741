import React, { Component} from 'react';
import {DarkModeToggle} from '../../components/Core/DarkModeToggle'
import { Link, withRouter } from 'react-router-dom';
import {
    Nav,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown,
    NavItem
} from 'reactstrap';
import LoginActions from '../../../../common/modules/Auth/LoginActions.js';

import LoginStore from '../../../../common/modules/Auth/LoginStore';
import * as restAPI from '../../../../common/utilities/ApiService';


class Header extends Component {

    constructor(props) {
        super(props);

        this.state = {
            notificationsOpen: false,
            isSystemAdmin: LoginStore.isSystemAdmin(),
            name: null,
            currentOrgId: null,
            currentOrgName: null,
            availableOrgs: [],
            isLoaded: false,
            isLoaded2: false,
            dashboards: []
        };

        this.toggleNotifications = this.toggleNotifications.bind(this);
        this.changeCurrentOrg = this.changeCurrentOrg.bind(this);
    }

    async componentDidMount() {
        const { orgs, current_org_id, current_org_name } = await restAPI.get('/api/1/users/me/orgs')
        const user_displayName = LoginStore?.user?.name ?? ''

        this.setState({
            name: user_displayName,
            currentOrgId: current_org_id,
            currentOrgName: current_org_name,
            availableOrgs: orgs,
            isLoaded: true
        });

        LoginStore.addChangeListener(this.handleUpdateCurrentOrg.bind(this))
    }

    async componentWillUnmount() {
        LoginStore.removeChangeListener(this.handleUpdateCurrentOrg)
        //await this.props.disconnectSocket();
    }

    handleUpdateCurrentOrg() {
        const newCurrentOrgId = LoginStore && LoginStore.user
            ? LoginStore.user.current_org_id
            : null

        if (newCurrentOrgId) {
            const newCurrentOrgName = (this.state.availableOrgs.find(o => o.id === newCurrentOrgId)?.name)
            this.setState({
                currentOrgId: newCurrentOrgId,
                currentOrgName: newCurrentOrgName,
            })
        }
    }

    toggleNotifications() {
        /*
        this.props.fetchNotifications({}, () => {
            this.setState({ notificationsOpen: !this.state.notificationsOpen });
        });
        */
    }

    sidebarToggle(e) {
        e.preventDefault();
        document.body.classList.toggle('sidebar-hidden');
    }

    sidebarMinimize(e) {
        e.preventDefault();
        document.body.classList.toggle('sidebar-minimized');
    }

    mobileSidebarToggle(e) {
        e.preventDefault();
        document.body.classList.toggle('sidebar-mobile-show');
    }

    asideToggle(e) {
        e.preventDefault();
        document.body.classList.toggle('aside-menu-hidden');
    }

    changeCurrentOrg(orgId) {
        restAPI.post('/api/1/users/me/org', { org_id: orgId }).then(res => {
            if (res.status === 200) {
                LoginActions.setUserCurrentOrg(JSON.stringify(res.data.updatedToken))
                this.props.history.push("/dashboarding/home")
            }
        });
    }

    render() {
        const renderOrgSelector = (
            
            <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav><i className="fa fa-caret-down"></i><i className="fa fa-building"></i>
                    {this.state.currentOrgId ? this.state.currentOrgName : "Select Organization"}
                </DropdownToggle>
                <DropdownMenu className="animated fadeIn confined-scrollable" style={{ paddingBottom: 0 }}>
                    <DropdownItem>Select an Organization</DropdownItem>

                    <DropdownItem divider />

                    {this.state.availableOrgs.map(function (org) {
                        const dynamicStyle = org.has_an_active_job
                            ? { background: '#0064ff26', fontWeight: 'bold' }
                            : {}

                        return (
                            <Link key={org.id} to='#' role="button" onClick={() => this.changeCurrentOrg(org.id)}>
                                <DropdownItem style={dynamicStyle}>{org.name}</DropdownItem>
                            </Link>
                        )
                    }, this)}
                </DropdownMenu>
            </UncontrolledDropdown>
        )

        var orgSelector;

        if (this.state.isLoaded) {
            orgSelector = renderOrgSelector
        } else {
            orgSelector = <span>Not Loaded</span>
        }

        return (
            <header className="topnavbar-wrapper">
                <nav className="navbar topnavbar navbar-expand-lg">
                    <div className="navbar-header">
                        <a className="navbar-brand" href="#/">
                            <div className="brand-logo">
                                <img className="img-fluid" src="img/logo.png" alt="App Logo" height="34" />
                            </div>
                            <div className="brand-logo-collapsed">
                                <img className="img-fluid" src="img/logo-single.png" alt="App Logo" />
                            </div>
                        </a>
                    </div>

                    <div className="navbar-collapse" id="topnavbar">
                        <Nav navbar className="mr-auto flex-column flex-lg-row">
                            <NavItem>
                                <Link className="nav-link" to="/dashboarding/home">Dashboards</Link>
                            </NavItem>
                            <NavItem>
                                <Link className="nav-link" to="/wells">Wells</Link>
                            </NavItem>
                            <NavItem>
                                <Link className="nav-link" to="/files/list">Downloads</Link>
                            </NavItem>
                            {/* <NavItem>
                                <Link className="nav-link" to="/alerts">Alerts</Link>
                            </NavItem> */}
                        </Nav>

                        
                            <Nav className="flex-row mr-10" navbar>
                            <DarkModeToggle/>
                            {orgSelector}

                            <UncontrolledDropdown nav inNavbar>
                                <DropdownToggle nav><i className="fa fa-caret-down"></i><i className="fa fa-user"></i>{this.state.name}</DropdownToggle>
                                <DropdownMenu end className="animated fadeIn auto-adjust-left">
                                    {this.state.isSystemAdmin ? (
                                        <a href="/admin#/orgs">
                                            <DropdownItem>
                                                <i className="fa fa-lock"></i>System Administration</DropdownItem>
                                        </a>
                                    ) : ""}

                                    
                                    <Link to='/profile'>
                                        <DropdownItem>
                                            <i className="fa fa-user"></i>Profile</DropdownItem>
                                    </Link>

                                    <DropdownItem divider />
                                    <Link to='/logout'> <DropdownItem><i className="fa fa-lock"></i>Logout</DropdownItem></Link>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </Nav>
                    </div>
                </nav>
            </header>
        );
    }
}

export default withRouter(Header);



