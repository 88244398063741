import React, { useState } from 'react'
import { DefaultOpenAccordion } from './Accordion'
import WellSelector from './WellSelector';
import { DraggableJobParameter } from './DraggableJobParameter'

import 'bootstrap/dist/css/bootstrap.min.css';

export const YAxisParameterSelect = ({ graph, all_jobs }) => {

    const parametersIdList = graph?.axes.flatMap(axes => axes.parameters).map(parameter => parameter.target_job_id);

    const getValues = (selectOptionsArr) => {
        return selectOptionsArr.flatMap(job_group_obj => {
            return job_group_obj.options.filter(option => {
                return parametersIdList?.includes(option.jobId)
            })
        })
    }

    const [showInactive, setShowInactive] = useState(false)

    let selectOptions = all_jobs.filter(job_group => (showInactive || job_group.has_active)).map((job_group) => {
        return {
            label: job_group.name,
            value: job_group.id,
            options: job_group.jobs.filter((dataset) => (showInactive || dataset.active))
                .map((dataset) => {
                    
                    const dataset_measures = dataset?.dataset_measures?.length > 0
                        ? dataset.dataset_measures.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
                        : null

                    return {
                        jobId: dataset.id,
                        label: dataset.display_name,
                        value: dataset.id,
                        dataset_measures,
                        dataset_id: dataset.dataset_id,
                        latest_value_ts: dataset.dataset_latest_value_ts
                    }
                }).sort((a, b) => a.label.localeCompare(b.label))
        }
    })

    const [selectedJobs, setSelectedJobs] = useState(getValues(selectOptions))

    const handleMultiSelectChange = (selectedJobsArray) => {
        setSelectedJobs([...selectedJobsArray])
    }

    const createAccordionBody = (job) => {
        const bodyContent = job.dataset_measures
            ?
            job.dataset_measures.map(measure => {
                let parameter = {
                    temp_id: `${job.jobId}-${measure}`,
                    job_name: job.label,
                    target_dataset_field: measure,
                    target_job_id: job.jobId
                }
                return <DraggableJobParameter parameter={parameter} />
            })
            :
            <h3>No Metrics on Well</h3>

        return <div style={{ padding: '2%' }}>{bodyContent}</div>
    }

    const createAccordionArr = (selectedJobs) => {
        return selectedJobs.map(job => {
            let accordion = {
                id: job.jobId,
                header: job.label,
                body: createAccordionBody(job)
            }
            return accordion
        })
    }

    let accordion = createAccordionArr(selectedJobs)

    return (<>
        <WellSelector
            showInactive={showInactive} setShowInactive={setShowInactive}
            selectOptions={selectOptions} selectedJobs={selectedJobs}
            handleMultiSelectChange={handleMultiSelectChange}
            graph={graph} style={{ padding: '0 0 1em 0' }}
            isMulti
        />
        <DefaultOpenAccordion
            accordions={accordion}
            accordionClassName='yParameterSelectAccordionContainer'
        />
    </>
    )
}
