import React from 'react'
import PropTypes from 'prop-types'
import reactCSS from 'reactcss'
import merge from 'lodash/merge'
import * as material from 'material-colors'
import { ColorWrap } from './ColorWrap'
import CircleSwatch from './CircleSwatch'

export const CirclePicker = ({ width, onClick, onCircleClick, onSwatchHover, colors, hex, circleSize,
  styles: passedStyles = {}, circleSpacing, className = '' }) => {
  const styles = reactCSS(merge({
    'default': {
      card: {
        width,
        display: 'flex',
        flexWrap: 'wrap',
        marginRight: -circleSpacing,
        marginBottom: -circleSpacing,
      },
    },
  }, passedStyles))

  return (
    <div style={ styles.card } className={ `circle-picker ${ className }` }>
      { colors.map( (c,idx) => (
        <CircleSwatch 
          key={ c }
          color={ c }
          number= {idx + 1}
          onClick={ () => onCircleClick (idx + 1)}
          onSwatchHover={ onSwatchHover }
          active={ hex === c.toLowerCase() }
          circleSize={ circleSize }
          circleSpacing={ circleSpacing }
        />
      )) }
    </div>
  )
}
CirclePicker.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  circleSize: PropTypes.number,
  circleSpacing: PropTypes.number,
  styles: PropTypes.object,
}
CirclePicker.defaultProps = {
  width: 252,
  circleSize: 28,
  circleSpacing: 14,
  colors: [material.red['500'], material.pink['500'], material.purple['500'],
    material.deepPurple['500'], material.indigo['500'], material.blue['500'],
    material.lightBlue['500'], material.cyan['500'], material.teal['500'],
    material.green['500'], material.lightGreen['500'], material.lime['500'],
    material.yellow['500'], material.amber['500'], material.orange['500'],
    material.deepOrange['500'], material.brown['500'], material.blueGrey['500']],
  styles: {},
}

export default ColorWrap(CirclePicker)