import DashboardForm from './Form'
import { toast } from 'react-toastify'
import React, { useEffect, useState } from 'react'
import * as restAPI from '../../../../common/utilities/ApiService'
import LoginStore from '../../../../common/modules/Auth/LoginStore'
import { DashboardingFormContextProvider } from '../../Contexts/DashboardingFormContext'

export default ({ match }) => {

    const dashboardId = match.params.dashboardId

    const [formState, setFormState] = useState({ formMeta_isLoading: true });
    const [promptNameMatchOverride, setPromptNameMatchOverride] = useState(false);

    const updateFieldValue = (field, newValue) => setFormState({
        ...formState,
        [field]: newValue
    })

    // check existing dashboard created by id = user id. if new dashboard, allow edit
    const canEdit = (formState.created_by_id === LoginStore.user.id
        || LoginStore.user.is_an_rds_user)

    useEffect(() => {
        if (dashboardId) {
            (async () => {
                const data = await restAPI.get(`/api/1/dashboarding/${dashboardId}`)
                setFormState(state => ({
                    ...state,
                    ...data,
                    formMeta_isLoading: false
                }))
            })()
        }
    }, [dashboardId])

    //#region helpers
    const notifySuccess = () => toast('Dashboard Saved Successfully', { type: 'success' })
    const notifyError = (msg = 'Unexpected Error. Please Try Again.') => toast(msg, { type: 'error' })

    const saveDashboard = (formState, nameMatchOverride = false) => {

        const dashboardPayload = { ...formState }
        const createdById = formState.created_by_id;

        const isValid = dashboardPayload?.title?.trim()

        if (!isValid) {
            notifyError('A Dashboard Title is Required')
        } else {
            restAPI.post(`/api/1/dashboarding/${dashboardId}/${createdById}`, {
                dashboard: dashboardPayload,
                nameMatchOverride
            }).then(res => {
                setPromptNameMatchOverride(false)
                const data = res.data
                notifySuccess()
                setFormState(state => ({
                    ...state,
                    ...data,
                    formMeta_isLoading: false
                }))
            }).catch(err => {
                if (err?.response?.data?.anticipated_error
                    && err?.response?.status === 409
                    && !err?.response?.data?.is_exact) {
                    setPromptNameMatchOverride(true)
                } else {
                    const customMessage = (err?.response?.data?.error) || null
                    notifyError(customMessage)
                }
            })
        }
    }

    const saveDashboardWithNameMatchOverride = () => {
        saveDashboard(formState, true)
    }
    //#endregion helper

    return <DashboardingFormContextProvider formValues={{ formState, setFormState }}>
        <DashboardForm
            canEdit={canEdit}
            formState={formState}
            setFormState={setFormState}
            saveDashboard={saveDashboard}
            updateFieldValue={updateFieldValue}
            promptNameMatchOverride={promptNameMatchOverride}
            setPromptNameMatchOverride={setPromptNameMatchOverride}
            saveDashboardWithNameMatchOverride={saveDashboardWithNameMatchOverride}
        />
    </DashboardingFormContextProvider>
}