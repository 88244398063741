import React, { useState, useEffect } from 'react'
import { SuccessButton, DangerButton } from '../Core/Shared'
import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import { FormTextInput } from '../Core/FormInputs'
import { ValidationMessage } from './ValidationMessage'
import { yScaleMinMaxValidationMessage } from './GraphConfigModalValidation'

export const SetYScaleModal = ({ setIsModalOpen, yAxisOrdinal, graph, updateGraph }) => {

    const graphStateAxis = graph.axes.find(a => a.ordinal === yAxisOrdinal);
    const [scale, setScale] = useState({ min_value: graphStateAxis.min_value, max_value: graphStateAxis.max_value });
    const [isConfirmDisabled, setIsConfirmDisabled] = useState(false);

    useEffect(() => {
        const errorMessage = yScaleMinMaxValidationMessage(scale.min_value, scale.max_value, graphStateAxis.parameters.length);
        setIsConfirmDisabled(errorMessage);
    }, [scale])

    const handleInputChange = (e) => {
        let stateKey = e.target.name;
        let input = e.target.value.trim();
        if (input.length === 0) { // if backspace to empty input
            setScale(prev => ({ ...prev, [stateKey]: null }))
        } else {
            setScale(prev => ({ ...prev, [stateKey]: input }))
        }
    }

    const onConfirm = () => {
        let newAxes = [...graph.axes];
        const updatedAxis = { ...graphStateAxis, min_value: scale.min_value, max_value: scale.max_value };
        const axisToUpdateIndex = newAxes.indexOf(graphStateAxis);
        newAxes[axisToUpdateIndex] = updatedAxis;
        updateGraph({ ...graph, axes: newAxes });
        setIsModalOpen(false)
    }


    return (
        <Modal
            style={{ width: '60vw', marginTop: '100px' }}
            isOpen={true}
            className='modal-dialog'
            contentClassName='custom-modal-style'
        >
            <ModalHeader
                close={<button className="close" onClick={() => setIsModalOpen(false)}>×</button>}
            >Set Y Scale
            </ModalHeader>
            <ModalBody>
                <Row style={{ gap: '5px' }}>
                    <Col>
                        <FormTextInput
                            label='Minimum Value'
                            name='min_value'
                            value={scale.min_value}
                            placeholder='Enter minimum value'
                            onChange={handleInputChange}
                        />
                    </Col>
                    <Col>
                        <FormTextInput
                            label='Maximum Value'
                            name='max_value'
                            value={scale.max_value}
                            placeholder='Enter maximum value'
                            onChange={handleInputChange}
                        />
                    </Col>
                </Row>
                <ValidationMessage min={scale.min_value} max={scale.max_value} parameterCount={graphStateAxis.parameters.length} />
            </ModalBody>
            <ModalFooter>
                <DangerButton
                    text='Cancel'
                    onClick={() => setIsModalOpen(false)}
                />
                <SuccessButton
                    text='Confirm'
                    disabled={isConfirmDisabled}
                    onClick={onConfirm}
                />
            </ModalFooter>
        </Modal >
    );
}