import React, { useState } from 'react'
import { FormTextInput } from '../Core/FormInputs'
import { Row, Col, Container } from "reactstrap"
import { ValidationMessage } from './ValidationMessage'

export const YAxisInputs = ({ updateAxes, axis, ...props }) => {

    const [scale, setScale] = useState({ min_value: axis.min_value, max_value: axis.max_value })

    const handleInputChange = (e) => {
        let stateKey = e.target.name;
        let input = e.target.value.trim();

        if (input.length === 0) { // if backspace to empty input
            updateAxes({ ...axis, [stateKey]: null })
            setScale(prev => ({ ...prev, [stateKey]: null }))
        } else {
            updateAxes({ ...axis, [stateKey]: input })
            setScale(prev => ({ ...prev, [stateKey]: input }))
        }
    }

    return <Container>
        <Row style={{ gap: '5px' }}>
            <Col>
                <FormTextInput
                    label='Minimum Value'
                    name='min_value'
                    value={scale.min_value}
                    placeholder='Enter minimum value'
                    onChange={handleInputChange}
                />
            </Col>
            <Col>
                <FormTextInput
                    label='Maximum Value'
                    name='max_value'
                    value={scale.max_value}
                    placeholder='Enter maximum value'
                    onChange={handleInputChange}
                />
            </Col>
            <ValidationMessage min={scale.min_value} max={scale.max_value} parameterCount={axis.parameters.length} />
        </Row>
    </Container>
}
