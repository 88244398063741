import React from 'react';
import Select from 'react-select';

export default function WellSelector({
  highlightedWells = [],
  showInactive,
  setShowInactive,
  selectOptions,
  graph = null,
  isMulti,
  singleSelect = false,
  selectedJobs,
  handleMultiSelectChange,
  placeholder = 'Select wells to view on dashboard',
  style = {},
  ...props
}) {

  const handleChange = (selectedValue) => {
    if (singleSelect) {
      handleMultiSelectChange(selectedValue ? [selectedValue] : null);
    } else if (isMulti) {
      handleMultiSelectChange(selectedValue);
    } else {
      handleMultiSelectChange(selectedValue ? selectedValue[0] : null);
    }
  };

  const addSelectedByGroup = (group) => {
    if (singleSelect) {
      handleMultiSelectChange(group ? [group[0]] : null);
    } else if (isMulti) {
      handleMultiSelectChange(group);
    } else {
      handleMultiSelectChange(group ? group[0] : null);
    }
  };

  const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#000'
  };

  const groupBadgeStyles = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
    float: "right"
  };

  const formatGroupLabel = (data) => (
    <div style={groupStyles} onClick={() => addSelectedByGroup(data.options)}>
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  );

  const optionStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginLeft: "25px"
  };

  const formatOptionLabel = (data) => {
    const isHighlighted = highlightedWells.includes(data.value);
    const baseColor = isHighlighted ? 'red' : '#000';
    let selected = 0;
    
    if (selectedJobs) {
      selected = selectedJobs.filter(job => job.value === data.value).length;
    }

    return (
      <div style={{ ...optionStyles, color: baseColor }}>
        {data.label}
        {(!selected && data.dataset_measures != null) ?
          <div>
            {
              data.dataset_measures.filter((dm, idx) => idx < 5 && dm).map((dm, idx, arr) => {
                if (idx === 0 && arr.length >= 4) return (<span style={groupBadgeStyles}>...</span>)
                else {
                  if (dm == null) {
                    return (<span style={groupBadgeStyles}>null measure</span>)
                  }
                  return (<span style={groupBadgeStyles}>{dm}</span>)
                }
              })
            }
          </div>
          : <span></span>}
      </div>
    );
  };

  const selectStyles = {
    container: (base, state) => ({
      ...base,
      zIndex: "999"
    }),
    valueContainer: (base, state) => ({
      ...base,
      maxHeight: '75px',
      overflowY: 'scroll'
    })
  };

  return (
    <>
      <div style={{ ...style }}>
        <div style={{ float: "right", width: "auto" }}>
          <div className="input-group-append">
            <div className="input-group-text" style={{ "height": "38px" }}>
              <input type="checkbox" name="inactiveJobs"
                checked={showInactive}
                onChange={() => {
                  setShowInactive(!showInactive)
                }}
                style={{ zIndex: "1000" }} />
            </div>
            <span className="input-group-text" id="">Include Inactive</span>
          </div>
        </div>
        <div>
          <Select
            isMulti={isMulti}
            options={selectOptions}
            value={selectedJobs}
            formatGroupLabel={formatGroupLabel}
            formatOptionLabel={formatOptionLabel}
            styles={selectStyles}
            placeholder={placeholder}
            onChange={handleChange}
          />
        </div>
      </div>
    </>
  );
}

