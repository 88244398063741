import React from 'react';
import { Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { Row, Col, FormGroup } from 'reactstrap';
import WellSelector from '../Elements/WellSelector';
import * as restAPI from '../../../../common/utilities/ApiService';

const FileDownload = require('js-file-download');

export default class QuickDownloads extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            availableJobs: [],
            selectedJobs: [],
            fileParameters: [],
            all_jobs: []
        };

        this.handleMultiSelectChange = this.handleMultiSelectChange.bind(this);
        this.downloadFile = this.downloadFile.bind(this);
        this.downloadFileHourly = this.downloadFileHourly.bind(this);
    }

    async componentDidMount() {
        await restAPI.get('/api/1/jobs?__v=2').then(res => {
            this.setState({ all_jobs: res });
        });
    }

    handleMultiSelectChange(selectedJobArray) {
        let currentSelectedJobs = new Set(this.state.selectedJobs);
        let jobsMultiSelect = new Set(selectedJobArray);

        // Find those values in A not in B;
        let jobsAdded = new Set([...jobsMultiSelect].filter(
            x => !currentSelectedJobs.has(x)));
        let jobsRemoved = new Set([...currentSelectedJobs].filter(
            x => !jobsMultiSelect.has(x)));

        this.hydrateJobData(jobsAdded, jobsRemoved);

        this.setState({
            selectedJobs: selectedJobArray
        })
    }

    hydrateJobData(jobsAdded, jobsRemoved) {
        var fileParameters = this.state.fileParameters;


        jobsAdded.forEach(job => {
            restAPI.get('/api/1/jobs/' + job.value + "/columns").then(res => {

                fileParameters.push(res);

                this.setState({
                    fileParameters: fileParameters
                });
            });
        });

        jobsRemoved.forEach(job => {
            var delIdx = fileParameters.findIndex(e => (e.id === job.jobId));

            while (delIdx !== -1) {
                fileParameters.splice(delIdx, 1);

                delIdx = fileParameters.findIndex(e => (e.id === job.jobId));
            }

            this.setState({
                fileParameters: fileParameters
            });
        });
    }

    downloadFile = (well_id, metric, display_name) => e => {
        e.preventDefault();

        console.log(well_id);
        console.log(metric);

        let url = "/api/1/wells/" + well_id + "/download/" + metric + "?type=sample";

        restAPI.get(url).then(res => {
            console.log(res);
            FileDownload(res, 'sampled-' + display_name.replace(/;/g, '-') + "-" + metric + '.csv');
        });
    }

    downloadFileHourly = (well_id, metric, display_name) => e => {
        e.preventDefault();

        console.log(well_id);
        console.log(metric);

        let url = "/api/1/wells/" + well_id + "/download/" + metric + "?type=summary";

        restAPI.get(url).then(res => {
            console.log(res);
            FileDownload(res, 'summary-' + display_name.replace(/;/g, '-') + "-" + metric + '.csv');
        });
    }

    render() {

        const renderRows = (
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th style={{ width: "30%" }}>Well Name</th>
                        <th style={{ width: "30%" }}>Metric</th>
                        <th>Smart Sample Data <br /> Download</th>
                        <th>Hourly Summary Data <br /> Download</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.fileParameters.map(function (fileParameterRow) {
                        return Object.keys(fileParameterRow.columns).map(function (key, idx) {
                            return (
                                <tr key={fileParameterRow.id + "-" + idx}>
                                    <td>{fileParameterRow.display_name}</td>
                                    <td><code>{key}</code></td>
                                    <td><Link to={"#"} onClick={this.downloadFile(fileParameterRow.id, key, fileParameterRow.display_name)}>Download (csv)</Link></td>
                                    <td><Link to={"#"} onClick={this.downloadFileHourly(fileParameterRow.id, key, fileParameterRow.display_name)}>Download (csv)</Link></td>
                                </tr>
                            );
                        }, this)
                    }, this)}
                </tbody>
            </table>
        );

        let FileParameterRows;

        if (this.state.fileParameters.length > 0) {
            FileParameterRows = renderRows;
        } else {
            FileParameterRows = <div className="card-body text-center"><p>Select jobs from the dropdown menu above to begin choosing columns for export.</p></div>
        }

        return (
            <>
                <div className="card-body" style={{ minHeight: "400px" }}>
                    <FormGroup row>
                        <label className="col-xl-12 col-form-label">Included Jobs</label>
                        <div className="col-xl-12">
                            <WellSelector
                                isMulti={true}
                                selectedJobs={this.state.selectedJobs}
                                handleMultiSelectChange={this.handleMultiSelectChange}
                                state_count={1}
                                jobs={this.state.all_jobs}
                                placeholder="Select wells to download."
                            />
                        </div>
                        <hr />
                    </FormGroup>

                    <Row>
                        <Col>
                            {FileParameterRows}
                        </Col>
                    </Row>
                </div>


            </>
        )
    }

}
