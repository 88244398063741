import React from "react";
import { Button as StrapButton } from 'reactstrap'

export const Button = ({ text = '', leftIcon, rightIcon, onClick = () => true, ...props }) => {
    return <StrapButton
        onClick={onClick}
        {...props}
    >
        {leftIcon && <i className={`fa ${leftIcon}`} style={{ marginRight: '1em' }} />}
        {text}
        {rightIcon && <i className={`fa ${rightIcon}`} style={{ marginLeft: '1em' }} />}
    </StrapButton>
}

export const IconButton = ({ icon, onClick = () => true, style = {}, className = '', ...props }) => {
    return <i
        onClick={onClick}
        className={`fa ${icon} ${className}`}
        style={{ cursor: 'pointer', fontSize: '2em', ...style }}
        {...props}
    />
}

export const SuccessButton = (props) => {
    return (
        <Button color="success" { ...props }/>
    )
}

export const DangerButton = (props) => {
    return (
        <Button color="danger" { ...props }/>
    )
}

