import React, { useEffect, useState } from 'react'
import {
  Accordion as StrapAccordion,
  AccordionItem as StrapAccordionItem,
  AccordionHeader as StrapAccordionHeader,
  AccordionBody as StrapAccordionBody,
} from 'reactstrap'

const createAccordionRow = (accordionRow, idx) => {
  return (
    <StrapAccordionItem key={`accordion-${idx}`}>
      <StrapAccordionHeader targetId={accordionRow.id.toString()} cssModule={{ 'accordion-button': 'user-defined-accordion-button' }}>
        {accordionRow.header}
      </StrapAccordionHeader>
      <StrapAccordionBody accordionId={accordionRow.id.toString()}>
        {accordionRow.body}
      </StrapAccordionBody>
    </StrapAccordionItem>
  )
}
export const DefaultOpenAccordion = ({ ...props }) => {
  return DefaultAccordion({ defaultOpen: true, ...props })
}

export const DefaultClosedAccordion = ({ ...props }) => {
  return DefaultAccordion({ defaultOpen: false, ...props })
}

export const DefaultAccordion = ({ accordions, defaultOpen, ...props }) => {
  const [accordionIds, setAccordionIds] = useState([]);
  const [openIds, setOpenIds] = useState([]);

  useEffect(() => {
    const propIds = accordions.map((accordion) => accordion.id.toString());

    //@nico this seems super inefficient
    const removedItems = accordionIds.filter((acId) => !propIds.includes(acId));
    const addedItems = propIds.filter((propId) => !accordionIds.includes(propId));

    if (defaultOpen) {
      setOpenIds(openIds.concat(addedItems).filter((openId) => !removedItems.includes(openId)))
    }

    setAccordionIds(accordionIds.concat(addedItems).filter((accordionId) => !removedItems.includes(accordionId)));
  }, [accordions])

  const ToggleOpen = (openId) => {
    setOpenIds(openIds.includes(openId) ? openIds.filter((id) => openId !== id) : [...openIds, openId]);
  }

  return (
    <Accordion accordions={accordions} open={openIds} stayOpen toggle={ToggleOpen} key={openIds.length} {...props}>
    </Accordion>
  )
}

export const MixedAccordion = ({ accordions = [], ...props }) => {
  if (!accordions?.length) {
    return <></>;
  }

  const [openIds, setOpenIds] = useState(['1', '2', '3', '4', '5', '6', '7', '8']);


  const ToggleOpen = (id) => {
    setOpenIds(openIds.includes(id) ? openIds.filter((oid) => id !== oid) : [...openIds, id]);
  }

  return (
    <Accordion accordions={accordions} open={openIds} stayOpen toggle={ToggleOpen} key={openIds.length} {...props}>
    </Accordion>
  )
}

export const Accordion = ({ children, accordions, accordionClassName, ...props }) => {
  return (
    <StrapAccordion {...props} key={accordions.length} className={accordionClassName}>
      {accordions.map((accordionRow, idx) => createAccordionRow(accordionRow, idx))}
    </StrapAccordion>
  )
}