import * as React from 'react'
import LoginStore from '../../../../common/modules/Auth/LoginStore'

const TITLE_COLUMN = {
    Header: 'Title',
    accessor: 'title',
    action: 'Edit',
    filterMethod: (filter, row) => 
        row[filter.id].toLowerCase().includes(filter.value.toLowerCase())
}
const ORG_COLUMN = {
    Header: 'Organization',
    accessor: 'org_name',
    className: 'text-center',
    filterable: true,
    action: 'Edit',
    filterMethod: (filter, row) => 
        row[filter.id].toLowerCase().includes(filter.value.toLowerCase())
}
const CREATED_COLUMN = {
    Header: 'Created',
    accessor: 'date_created',
    className: 'text-center',
    filterable: false,
    action: 'Edit',
    Cell: e => new Date(e.original.date_created).toLocaleString()
}
const LAST_UPDATED_COLUMN = {
    Header: 'Last Updated',
    accessor: 'date_updated',
    className: 'text-center',
    filterable: false,
    action: 'Edit',
    Cell: e => new Date(e.original.date_updated).toLocaleString()
}
// const LAST_STREAMED_COLUMN = {
//     Header: 'Last Streamed',
//     accessor: 'last_streamed',
//     className: 'text-center',
//     filterable: false,
//     action: 'Edit',
//     Cell: e => e.original.last_streamed === 'N/A' ? e.original.last_streamed : new Date(e.original.last_streamed).toLocaleString()
// }
const SHARED_COLUMN = {
    Header: 'Shared',
    accessor: 'is_sharing_enabled',
    width: 75,
    className: 'text-center',
    filterable: false,
    action: 'Edit',
    Cell: e =>
        e.row.is_sharing_enabled
            ? <i className='fa fa-check' style={{ fontSize: "1.5em", color: '#000000' }}></i>
            : <i></i>
}
const DELETE_COLUMN = {
    Header: '',
    width: 35,
    className: 'text-center',
    filterable: false,
    action: 'Delete',
    Cell: e =>
        e.original.created_by_id === LoginStore.user.id
            ? <i className='fa fa-trash' style={{ fontSize: "1.5em", color: '#990000' }}></i>
            : <i></i>
}

export const createdBy = [
    TITLE_COLUMN,
    {
        Header: 'Created By',
        accessor: 'name',
        className: 'text-center',
        action: 'Edit',
        filterMethod: (filter, row) => 
        row[filter.id].toLowerCase().includes(filter.value.toLowerCase())
    },
    ORG_COLUMN,
    CREATED_COLUMN,
    LAST_UPDATED_COLUMN,
    // LAST_STREAMED_COLUMN,
    SHARED_COLUMN,
    DELETE_COLUMN
]
export const noCreatedBy = [
    TITLE_COLUMN,
    ORG_COLUMN,
    CREATED_COLUMN,
    LAST_UPDATED_COLUMN,
    // LAST_STREAMED_COLUMN,
    SHARED_COLUMN,
    DELETE_COLUMN
]