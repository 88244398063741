import { DndProvider } from 'react-dnd';
import { ModalBase } from './ModalBasic';
import { Container, Row, Col } from 'reactstrap';
import React, { useState, useEffect } from 'react';
import { YAxisGraphConfig } from './YAxisGraphConfig';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { YAxisParameterSelect } from './YAxisParameterSelect';
import { XAxisParameterControl } from './XAxisParameterControl';
import { GraphPropertiesControl } from './GraphPropertiesControl';
import { yScaleMinMaxValidationMessage } from './GraphConfigModalValidation'

const DEFAULT_EMPTY_GRAPH = {
    graph_name: '',
    show_live_data: false,
    graph_resolution_id: 1,
    axes: []
}

export const GraphConfigModal = ({ showModal, setShowModal, graph, graph_id, all_jobs,
    x_axis_units_of_measure, onSubmitGraphConfig }) => {

    const [localStateGraph, setLocalStateGraph] = useState({})
    const [isConfirmDisabled, setIsConfirmDisabled] = useState(false)

    useEffect(() => {
        const graphForLocalState = graph || DEFAULT_EMPTY_GRAPH
        setLocalStateGraph(graphForLocalState)
    }, [showModal])

    useEffect(() => {
        const validTimeAmount = localStateGraph?.time_amount ? !isNaN(localStateGraph.time_amount) : true;
        const validYAxesInputs = localStateGraph?.axes?.map(a => {
            return !yScaleMinMaxValidationMessage(a.min_value, a.max_value, a.parameters.length);
        });
        const validXAxisInputs = ((localStateGraph?.time_amount??0) && (localStateGraph?.unit_of_time??0)) || 
            ((!localStateGraph?.time_amount??0) && (!localStateGraph?.unit_of_time??0))
        setIsConfirmDisabled(!(validTimeAmount && validYAxesInputs?.every(b => b) && validXAxisInputs));
    }, [localStateGraph])

    const updateLocalStateGraph = (key, value) => {
        setLocalStateGraph(prev => ({ ...prev, [key]: value }));
    }

    const submitChanges = () => {
        const newGraphObject = { ...localStateGraph }
        onSubmitGraphConfig(newGraphObject)
    }

    const bodyContent = <DndProvider backend={HTML5Backend}>
        <Row>
            <Col>
                <Row style={{ padding: '0 1em 2em 1em' }}>
                    <YAxisParameterSelect all_jobs={all_jobs} graph={localStateGraph} />
                </Row>
                <Row>
                    <Container>
                        <GraphPropertiesControl
                            graph={localStateGraph}
                            updateLocalStateGraph={updateLocalStateGraph}
                        />
                        <XAxisParameterControl
                            graph={localStateGraph}
                            units_of_time={x_axis_units_of_measure}
                            updateLocalStateGraph={updateLocalStateGraph}
                        />
                    </Container>
                </Row>
            </Col>
            <Col>
                <YAxisGraphConfig
                    graph={localStateGraph}
                    updateLocalStateGraph={updateLocalStateGraph}
                />
            </Col>
        </Row>
    </DndProvider >

    return <ModalBase
        isOpen={showModal}
        bodyContent={bodyContent}
        onSubmit={submitChanges}
        setIsOpen={setShowModal}
        submitButtonText='Apply'
        cancelButtonText='Cancel'
        customModalStyle={{ width: '80vw' }}
        isSubmitButtonDisabled={isConfirmDisabled}
        titleText={`Graph Configuration: ${localStateGraph.graph_name || `Graph-${graph_id}`}`}
    />
}
