import React from 'react';

import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import {ToastContainer, Flip} from 'react-toastify'
import LoginStore from '../../common/modules/Auth/LoginStore';

import User from '../../common/components/User/User';
import Core from './components/Core/Core';
import Files from './components/Files/Files';
import Profile from './components/Profile/Edit';
import Wells from './components/Wells/Wells';
import ProfileAlerts from './components/Profile/Alerts';
import Alerts from './components/Alerts/Alerts';
import Dashboarding from './components/Dashboarding';


// These are pages that are rendered using the basic error template 
const errorPageRoutes = [
    '/404',
    '/500',
    '/maintenance'
];

// These are pages that are rendered using the basic user template 
const userPageRoutes = [
    '/login',
    '/register',
    '/logout',
    '/resetPassword',
    '/forgotPassword',
    '/signup'
];

// Todo: Move to own component
const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route exact {...rest} render={(props) => (
        LoginStore.isLoggedIn() === true
            ? <Component {...props} />
            : <Redirect to='/login' />
    )} />
)

const Routes = ({ location }) => {
    
    if (errorPageRoutes.indexOf(location.pathname) > -1) {
        return (
            <div className="App">
                <h1>Error Page</h1>
            </div>
        )
    } else if (userPageRoutes.indexOf(location.pathname) > -1) {
        return (
            <User.Layout>
                <Switch location={location}>
                    <Route path="/login" component={User.Login} />
                    <Route path="/logout" component={User.Logout} />
                    <Route path="/resetPassword" component={User.ResetPassword} />
                    <Route path="/forgotPassword" component={User.ForgotPassword} />
                    <Route path="/signup" component={User.Signup} />
                </Switch>
            </User.Layout>
        )
    } else {
        return (
            <Core.Layout>
                <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                transition={Flip}/>
                <Switch>
                    <PrivateRoute path='/dashboarding/home' component={Dashboarding.Home} exact/>

                    <PrivateRoute path="/profile/alerts" component={ProfileAlerts} />
                    <PrivateRoute path="/alerts" component={Alerts} />
                    
                    <PrivateRoute path='/profile' component={Profile} />

                    <PrivateRoute path='/wells' component={Wells.List} exact/>
                    <PrivateRoute path='/wells/:wellId' component={Wells.Detail} exact/>
                    <PrivateRoute path='/wells/:wellId/analyze' component={Wells.Analyze} exact/>

                    <PrivateRoute path='/wells/:wellId/alerts' component={Wells.Alerts} exact/>

                    <PrivateRoute path='/files/list' component={Files.List}/>
                    <PrivateRoute path='/files/create' component={Files.Create}/>

                    <PrivateRoute path='/dashboarding/home' component={Dashboarding.Home} exact/>
                    <PrivateRoute path='/dashboarding/new' component={Dashboarding.New} exact/>
                    <PrivateRoute path='/dashboarding/:dashboardId/edit' component={Dashboarding.Edit} exact/>

                    <Redirect from="/" to="/dashboarding/home" />
                </Switch>
            </Core.Layout>
        )
    }
}

export default withRouter(Routes);