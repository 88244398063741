import React from 'react'
import { IconButton } from '../Core/Shared'
import {
  Card as StrapCard,
} from 'reactstrap'
import GraphLinkForm from './GraphLinkForm'

export const CardWithTopRightButton = ({ graph_id, canEdit, cardStyle, hideButton, onTopRightClick, buttonIcon, onContextMenu, updateLinkedGroup, graph, children }) => {
  const topRightButton = <IconButton
    icon={buttonIcon}
    className='hoverableIconButton'
    onClick={onTopRightClick}
    style={{
      fontSize: '1.5em', position: 'absolute', top: '0px', right: '0px', zIndex: 100, padding: '2px 3px 3px 5px', backgroundColor: 'aliceblue',
      borderRadius: '4px'
    }}
  />


  return (
    <Card style={cardStyle} onContextMenu={onContextMenu}>
      {canEdit && !hideButton && topRightButton}
      <GraphLinkForm graph_id={graph_id} updateLinkedGroup={updateLinkedGroup} graph={graph} canEdit={canEdit} />
      {children}
    </Card>
  )
}

export const CardWithTopRightButtonAndCancel = ({ canEdit, cardStyle, hideButton, onTopRightClick, onCancelClick, buttonIcon, onContextMenu,
  isEditing, children }) => {

  const topRightButton = <IconButton
    icon={buttonIcon}
    className='hoverableIconButton'
    onClick={onTopRightClick}
    style={{
      fontSize: '1.5em', position: 'absolute', top: '0px', right: '0px', padding: '2px 3px 3px 5px', backgroundColor: 'aliceblue',
      borderRadius: '4px', zIndex: 0
    }}
  />
  const cancelButton = <IconButton
    icon='fa-times'
    className='hoverableIconButton'
    onClick={onCancelClick}
    style={{
      fontSize: '1.5em', position: 'absolute', top: '0px', right: '35px', padding: '2px 3px 3px 5px', backgroundColor: 'aliceblue',
      borderRadius: '4px', zIndex: 0
    }}
  />

  return (
    <Card style={cardStyle} onContextMenu={onContextMenu}>
      {canEdit && !hideButton && <>
        {isEditing && cancelButton} {topRightButton}
      </>
      }
      {children}
    </Card>
  )
}

export const Card = ({ children, ...props }) => {
  return (
    <StrapCard {...props}>
      {children}
    </StrapCard>
  )
}