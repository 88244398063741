import React, { Component } from "react";
import { Redirect } from 'react-router-dom'

import Auth from '../../modules/Auth/AuthService';

class Logout extends Component {
    state = {
        redirectToLogin: false
    }

    componentDidMount() {
        Auth.logout();
        this.setState({ redirectToLogin: true });
    }

    render() {
        if (this.state.redirectToLogin) {
            return <Redirect to='/login' />
        } else {
            return (
                <div className="block-center mt-4 wd-fixed-user-form">
                    <div className="card card-flat">
                        <div className="card-header text-center bg-dark">
                            <a href="/app">
                                <img className="block-center rounded login-logo" src="img/logo.png" alt="Logo" />
                            </a>
                        </div>
                        <div className="card-body">
                            <p className="text-center">Logging out...</p>
                        </div>
                    </div>
                </div>
            )
        }
    }

    
}

export default Logout;