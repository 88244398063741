export const yScaleMinMaxValidationMessage = (min, max, parameterCount = 0) => {
    const minInputNaN = isNaN(min);
    const maxInputNaN = isNaN(max);
    const minInputPopulated = min !== null;
    const maxInputPopulated = max !== null;
    const minMaxValidRange = (!minInputNaN && !maxInputNaN && minInputPopulated && maxInputPopulated)
        ? parseFloat(min) <= parseFloat(max)
        : !parameterCount
            ? true
            : false;

    return minInputNaN
        ? 'Minimum value is not an integer'
        : maxInputNaN
            ? 'Maximum value is not an integer'
            : (!minInputPopulated && parameterCount > 0)
                ? 'Please enter minimum value'
                : (!maxInputPopulated && parameterCount > 0)
                    ? 'Please enter maximum value'
                    : !minMaxValidRange
                        ? 'Minimum value should be less than the maximum value'
                        : null;
};