
import Select from 'react-select'
//hidingLiveData 
// import { LiveBadge } from './LiveBadge'
import { useContextMenu } from 'react-contexify'
import React, { useContext, useEffect, useState } from 'react'
import * as restAPI from '../../../../common/utilities/ApiService'
import { WidgetContextMenu } from './D3Elements/WidgetContextMenu'
import { CardWithTopRightButtonAndCancel } from '../Elements/Card'
import { DashboardingFormContext } from '../../Contexts/DashboardingFormContext'
import { DeletionModal } from './DeletionModal'
import { DarkModeContext } from '../../theme/DarkModeContext'

export const Widget = ({ widget_id, canEdit, widget, jobSelectOptions, jobId, datasetField, updateWidget, removeWidget }) => {
    const [isEditing, setIsEditing] = useState(false)
    const [dataValueResponse, setDataValueResponse] = useState(null)

    const [editModeSelectedJob, setEditModeSelectedJob] = useState('')
    const [editModeSelectedMeasure, setEditModeSelectedMeasure] = useState('')
    const [isWidgetDeletionModalOpen, setIsWidgetDeletionModalOpen] = useState(false)

    const { formState: { show_live_data } } = useContext(DashboardingFormContext)

    const getJobFromOptionsById = (jobId) => jobSelectOptions.flatMap(i => i.options).find(job => job.jobId === jobId)
    const getDatasetFieldOptionsByJobId = (jobId) => getJobFromOptionsById(jobId)?.dataset_measures
        ?.map(measure => ({ key: measure, label: measure, value: measure }))
        ?? []
    const measureOptions = getDatasetFieldOptionsByJobId(editModeSelectedJob?.jobId)

    useEffect(() => { //want to fetch/map/set the data value from api based on state values 
        if (!jobId || !datasetField) { //need to have both of these for there to be a usable data response
            setDataValueResponse(null)
        } else {
            if (show_live_data) {
                setDataValueResponse({ loading: true })
                const ws = new WebSocket(process.env.SOCKET_BASE_URL);
                ws.onopen = () => {
                    console.log(`widget ${jobId} connected`)
                    ws.send(JSON.stringify('connected'))
                }
                ws.onerror = (e) => { console.log(e) }

                ws.onmessage = (message) => {
                    const m = JSON.parse(message.data)
                    switch (typeof m) {
                        case 'string':
                            if (m === 'send parameters') {
                                const { target_job_id, target_dataset_field } = widget;
                                ws.send(JSON.stringify({ widget_id, target_job_id, target_dataset_field }))
                            } else if (m === 'pong') {

                            }
                            break;
                        case 'object':
                            //make sure you only render data on websocket widget message
                            if (m.type === 'widget') {
                                const { target_job_id, target_dataset_field } = widget;
                                if (m.subscription_id === target_job_id + target_dataset_field + widget_id) {
                                    setDataValueResponse(m)
                                }
                            }
                            break;
                        default:
                            console.log('unrecognized data return for live data')
                    }
                }

                return () => {
                    ws.close();
                }
            } else {
                setDataValueResponse({ loading: true })
                restAPI.get(`/api/1/dashboarding/widget/latest/${datasetField}/${jobId}`,)
                    .then(res => {
                        setDataValueResponse({ ...res.influxData, last_checked: res.last_checked })
                    })
            }
        }
    }, [show_live_data, jobId, datasetField])

    useEffect(() => {
        //want to keep editMode state in sync with other state values in the case that other state is changed
        setEditModeSelectedJob(getJobFromOptionsById(jobId) ?? '')
        setEditModeSelectedMeasure(getDatasetFieldOptionsByJobId(jobId).find(o => o.value === datasetField) ?? '')
    }, [jobSelectOptions, isEditing])

    useEffect(() => {
        //when editmodeSelectedJob is changed, clear out the measure value if 
        //it is not a valid option for the new selected job option
        setEditModeSelectedMeasure(measureOptions.find(o => o.value === editModeSelectedMeasure.value) ?? '')
    }, [editModeSelectedJob])

    const { show } = useContextMenu({ id: `rightClickMenu-${widget_id}` })
    const contextMenuHandler = (event) => {
        show(event);
        event.stopPropagation()
    }

    const WidgetValue = () => {
        if (!dataValueResponse) {
            return (
                <p>Edit to add Metric</p>
            )
        } else if (dataValueResponse.loading === true) {
            return (
                <p>Loading...</p>
            )
        }

        const isValidValue = (dataValueResponse.value || dataValueResponse.value === 0)
        const parsedValueToDisplay = isValidValue
            ? parseFloat(dataValueResponse.value).toFixed(2)
            : 'No Data'
        const parsedValueDateTimeToDisplay = isValidValue
            ? `@ ${new Date(dataValueResponse?.tmstamp).toLocaleString()}`
            : ''
        const parsedLastCheckedDateTimeToDisplay = new Date(dataValueResponse?.last_checked).toLocaleString()

        const { isDark } = useContext(DarkModeContext);

        return (
            <>
                <div>
                    <svg viewBox="0 0 60 12">
                        <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" fontSize={12} fill={`${isDark ? 'rgb(168, 161, 149)' : 'black'}`}>
                            {parsedValueToDisplay}
                        </text>
                    </svg>
                    <p style={{ fontSize: '2em' }}>{parsedValueDateTimeToDisplay}</p>
                </div>
                <div style={{ fontSize: '1em', paddingTop: '0.4em' }}>
                    <p style={{ marginBottom: '-0.4em' }}>
                        last checked at:  {parsedLastCheckedDateTimeToDisplay}
                    </p>
                </div>
            </>
        )
    }
    const confirmWidgetDeletion = () => {
        setIsWidgetDeletionModalOpen(false)
        removeWidget(widget.id)
    }

    const confirmChanges = () => {
        updateWidget({
            target_job_id: editModeSelectedJob.jobId,
            target_dataset_field: editModeSelectedMeasure.value
        })
        setIsEditing(false)
    }
    const handleTopRightClick = () => isEditing
        ? confirmChanges()
        : setIsEditing(true)

    return <>
        {/* hidingLiveData */}
        {/* {dataValueResponse
            && !dataValueResponse.loading
            && !isEditing
            && <LiveBadge
                noText
                isLive={show_live_data}
                containerStyle={{ top: '0.5px', left: '1px', zIndex: 1 }}
            />
        } */}
        <CardWithTopRightButtonAndCancel
            canEdit={canEdit}
            isEditing={isEditing}
            onContextMenu={contextMenuHandler}
            onTopRightClick={handleTopRightClick}
            onCancelClick={() => setIsEditing(false)}
            buttonIcon={isEditing ? 'fa-check' : 'fa-edit'}
            cardStyle={{ paddingTop: '10px', height: '100%', border: '1px solid lightgray', position: 'relative', justifyContent: isEditing ? 'center' : 'space-evenly' }}
        >
            {dataValueResponse && !dataValueResponse.loading &&
                <p>{dataValueResponse.job_display_name || getJobFromOptionsById(jobId)?.label}: {dataValueResponse.measure || datasetField}</p>
            }
            {isEditing
                ? !jobSelectOptions?.length
                    ? <p>Loading Job Options...</p>
                    : <>
                        <Select
                            styles={selectStyles}
                            options={jobSelectOptions}
                            value={editModeSelectedJob}
                            placeholder='Select a Job/Well'
                            onChange={setEditModeSelectedJob}
                        />
                        <br />
                        <Select
                            styles={selectStyles}
                            options={measureOptions}
                            value={editModeSelectedMeasure}
                            placeholder='Select a Measure'
                            isDisabled={!editModeSelectedJob}
                            onChange={setEditModeSelectedMeasure}
                            key={`${editModeSelectedJob?.jobId}-${editModeSelectedMeasure?.value}`}
                        />
                    </>
                : <WidgetValue />
            }
        </CardWithTopRightButtonAndCancel>

        <DeletionModal
            onSubmit={confirmWidgetDeletion}
            isOpen={isWidgetDeletionModalOpen}
            setIsOpen={setIsWidgetDeletionModalOpen}
            entityDisplayIdentifier={`Widget Id: ${widget?.id}`}
            entityDisplayType='widget'
        />
        {widget_id && canEdit &&
            <WidgetContextMenu
                widget_id={widget_id}
                canEdit={canEdit}
                openWidgetDeletionModal={() => setIsWidgetDeletionModalOpen(true)}
            />
        }
    </>
}

const selectStyles = {
    container: (base, state) => ({
        ...base,
        top: '30px',
        paddingLeft: '0.5em',
        paddingRight: '0.5em'
    }),
    menu: (base) => ({
        ...base,
        zIndex: 5
    })
};