import Select from 'react-select';
import React, { Component } from "react";
import LoginStore from "../../../../../common/modules/Auth/LoginStore";
import { FormGroup, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export default class ContactGroupModal extends Component {
    constructor(props) {
        super(props);

        var user_id = LoginStore.user.id;
        let selectedUsers = [];
        if (this.props.initialContactFormValues && this.props.initialContactFormValues.user_list) {
            var sUs = this.props.initialContactFormValues.user_list.split("|").filter(u => u.length > 0);

            sUs.forEach(sU => {
                this.props.availableUsers.forEach(au => {
                    if (au.id === parseInt(sU)) {
                        selectedUsers.push({
                            key: au.id,
                            label: au.name,
                            value: au.id
                        })
                    }
                })
            });
        } else {
            selectedUsers = this.props.availableUsers.map(user => ({
                key: user.id,
                label: user.name,
                value: user.id
            })).filter(userOption => userOption.key === user_id)
        }

        this.state = {
            contact: this.props.initialContactFormValues,
            workingContactId: this.props.currentContactId,
            availableUsers: this.props.availableUsers,
            selectedUsers: selectedUsers,
        };

        this.handleContactUsersChange = this.handleContactUsersChange.bind(this);
        this.saveContact = this.saveContact.bind(this);
    }

    handleContactUsersChange(selectedUserArray) {
        this.setState({
            selectedUsers: selectedUserArray
        });
    }

    saveContact = event => {
        let contact = this.state.contact;

        var user_list_string = this.state.selectedUsers.map(ed => {
            return ed.value;
        }).join("|");
        contact.user_list = user_list_string;

        this.props.saveContact(this.state.workingContactId, contact);
    }

    render() {

        return (
            <Modal isOpen={this.props.show} toggle={this.props.toggleModal} className="modal-lg">
                <ModalHeader toggle={this.props.toggleModal}>
                    Create/Edit Contact Group
                </ModalHeader>

                <ModalBody>
                    <FormGroup row>
                        <label className="col-xl-2 col-form-label">Users</label>
                        <div className="col-xl-10">
                            <Select
                                isMulti
                                className="form-control form-select-element"
                                placeholder="Select users"
                                value={this.state.selectedUsers}
                                options={this.props.availableUsers.map(user => ({
                                    key: user.id,
                                    label: user.name,
                                    value: user.id
                                }))}
                                onChange={this.handleContactUsersChange} />
                        </div>
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.saveContact} >Save</Button>
                    <Button color="secondary" onClick={this.props.toggleModal}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }

}

