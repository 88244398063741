import React from 'react';
// import { Link } from 'react-router-dom';
import {
    Row,
    Col,
} from 'reactstrap';

import * as restAPI from '../../../../common/utilities/ApiService';
import ReactTable from "react-table";
import "react-table/react-table.css";

export default class List extends React.Component {
    constructor(req) {
        super()

        this.state = {
            wells: []
        }
    } 
    
    async componentDidMount() {
        await restAPI.get('/api/1/jobs')
            .then(res => {
                this.setState({
                    wells: res
                });
            });
    }


    render() {
        return (
            <Row>
                <Col md={ 12 }>
                    
                    <div className="card card-default">

                        <ReactTable
                            data={this.state.wells}
                            className="-striped -highlight"
                            defaultPageSize={50}
                            filterable
                            columns={[
                                {
                                    Header: "Name",
                                    accessor: "display_name",
                                    filterMethod: (filter,row) => 
                                        row[filter.id].toLowerCase().includes(filter.value.toLowerCase()),
                                }, 
                                {
                                    Header: "Created",
                                    accessor: "created_ts",
                                    width: 300,
                                    className: "text-center",
                                }, 
                                // {
                                //     Header: "DFIT Analysis",
                                //     Cell: props => {
                                //         const { original } = props;
                                //         return (
                                //             <Link to={"/wells/" + original.id + "/analyze" }>Create</Link>
                                //         )
                                //     },
                                //     width: 200,
                                //     className: "text-center",
                                // }, 
                            ]}
                        />
                    </div>
                </Col>
            </Row>
        )
    }
}