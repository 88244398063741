import React from 'react';
import { Link } from 'react-router-dom';
import { TabContent, TabPane, Nav, NavLink, NavItem, Row, Col } from 'reactstrap';
import * as restAPI from '../../../../common/utilities/ApiService';
import classnames from 'classnames';
import QuickDownloads from './QuickDownloads';

import ReactGA from 'react-ga';
import ReactTable from "react-table";
import "react-table/react-table.css";

class List extends React.Component {
    constructor() {
        super()

        this.toggle = this.toggle.bind(this);

        const lastActiveTab = localStorage.getItem('activeTab') || '2';

        this.state = {
            files: [],
            attachments: [],
            activeTab: lastActiveTab
        }
    }

    async componentDidMount() {
        await restAPI.get('/api/1/files').then(res => {
            this.setState({ files: res.files });
        });

        restAPI.get('/api/1/files/attachments').then(res => {
            this.setState({ attachments: res.attachments });
        });
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            localStorage.setItem('activeTab', tab);
            this.setState({
                activeTab: tab
            });
        }
    }

    render() {
        // ReactGA.initialize('UA-126736160-2');
        ReactGA.pageview("/files/list");
        return (
            <Row>
                <Col md={12}>

                    <div className="card card-default">
                        <Nav tabs>
                        <NavItem>
                                <NavLink
                                    className={classnames({ active: this.state.activeTab === '2' })}
                                    onClick={() => { this.toggle('2'); }}
                                >
                                    Final Data Files
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: this.state.activeTab === '1' })}
                                    onClick={() => { this.toggle('1'); }}
                                >
                                    Custom Downloads
                                </NavLink>
                            </NavItem>
                           
                        </Nav>

                        <TabContent activeTab={this.state.activeTab} style={{ padding: 0, marginTop: 0 }}>
                            <TabPane tabId="1">
                                <div className="card-body">
                                    <p>
                                        Below is a list of all export files that have been generated for the current organization's jobs.
                                    </p>
                                    <p className="text-center">
                                        <Link className="btn btn-primary" to="/files/create">
                                            Create a New Download File
                                        </Link>
                                    </p>
                                </div>
                                <hr />

                                <ReactTable
                                    data={this.state.files}
                                    className="-striped -highlight"
                                    defaultPageSize={50}
                                    filterable
                                    columns={[
                                        {
                                            Header: "Name",
                                            id: "name",
                                            accessor: d => d.file_name,
                                            Cell: props => {
                                                const { original } = props;
                                                return (
                                                    original.file_key
                                                        ? <a href={"https://s3.amazonaws.com/rds-data-stage/" + original.file_key}> {original.file_name} </a>
                                                        : <span>{original.file_name}</span>
                                                )
                                            },
                                            width: 400,
                                            filterMethod: (filter, row) => 
                                                row[filter.id].toLowerCase().includes(filter.value.toLowerCase())
                                        },
                                        {
                                            Header: "Description",
                                            accessor: "description",
                                            filterMethod: (filter, row) =>
                                                row[filter.id].toLowerCase().includes(filter.value.toLowerCase())
                                        },
                                        {
                                            Header: "Status",
                                            Cell: props => {
                                                const { original } = props;
                                                return (
                                                    original.process_finish_ts
                                                        ? (original.file_key
                                                            ? <span>Ready</span>
                                                            : <span className='badge badge-danger'>Error</span>)
                                                        : (original.process_start_ts
                                                            ? <span className='badge badge-info'>Processing</span>
                                                            : <span className='badge badge-warning'>Pending</span>)
                                                )
                                            },
                                            width: 100,
                                            className: "text-center",
                                        },
                                        {
                                            Header: "Created",
                                            accessor: "created_ts",
                                            width: 300,
                                            className: "text-center",
                                        },
                                    ]}
                                />
                            </TabPane>

                            <TabPane tabId="2">
                                <ReactTable
                                    data={this.state.attachments}
                                    className="-striped -highlight"
                                    defaultPageSize={50}
                                    filterable
                                    columns={[
                                        {
                                            Header: "Name",
                                            id: "name",
                                            accessor: "filename",
                                            Cell: props => {
                                                const { original } = props;
                                                return (
                                                    <a href={original.signedUrl}> {original.filename} </a>
                                                )
                                            },
                                            filterMethod: (filter, row) =>
                                                row[filter.id].toLowerCase().includes(filter.value.toLowerCase())
                                        },
                                        {
                                            Header: "Size",
                                            id: "size",
                                            Cell: props => {
                                                const { original } = props;
                                                return (
                                                    <div> {original.sizeInBytes / 1000000} MB </div>
                                                )
                                            },
                                        },
                                        {
                                            Header: "Updated",
                                            accessor: "lastModified",
                                        },
                                    ]}
                                />
                            </TabPane>

                            <TabPane tabId="3">
                                <QuickDownloads />
                            </TabPane>

                        </TabContent>

                    </div>
                </Col>
            </Row>
        )
    }
};

export default List;