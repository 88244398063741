import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';

// import ReactGA from 'react-ga';
// ReactGA.initialize('UA-126736160-2');
// ReactGA.pageview(window.location.pathname + window.location.search);

ReactDOM.render(<App />, document.getElementById("app"));

const storedDarkModePreference = localStorage.getItem('darkMode');
const darkStyleSheet = document.getElementById("dark-mode-stylesheet");

if (storedDarkModePreference === 'true') {
    darkStyleSheet.removeAttribute("disabled");
} else {
    darkStyleSheet.setAttribute("disabled", "disabled");
}