import React, { createContext, useContext, useState } from 'react';
const MAX_GRAPHLINKEDGROUP_COUNT = 8

export const DashboardingFormContext = createContext(null)

const getLinkedGroupState = () => {
    const [linkedGroupSharedValues, setLinkedGroupSharedValues] = useState(Array.from({ length: MAX_GRAPHLINKEDGROUP_COUNT }, () => []))

    const setValueForLinkedGroup = (groupId, newValue) => {
        setLinkedGroupSharedValues((old) => old.map((existingValue, idx) => idx === (groupId - 1) ? newValue : existingValue))
    }

    return {
        linkedGroupSharedValues,
        setValueForLinkedGroup
    }
}

const getSelectedGraphState = () => {
    const [selectedGraphId, setSelectedGraphId] = useState(null)

    return {
        selectedGraphId, setSelectedGraphId
    }
}

const dashboardingMouseOver = () =>{
    const [sharedMouseOver, setSharedMouseOver] = useState({timestamp: null, graphId: null})

    return {
        sharedMouseOver, setSharedMouseOver
    }
}

export const DashboardingFormContextProvider = ({ formValues: { formState, setFormState }, children }) => {
    const { selectedGraphId, setSelectedGraphId } = getSelectedGraphState()
    const { linkedGroupSharedValues, setValueForLinkedGroup } = getLinkedGroupState()
    const { sharedMouseOver, setSharedMouseOver} = dashboardingMouseOver()

    const updateLinkedGroupAndIncludedGraphs = (targetLinkedGroup, newValue) => {
        const newRows = [...formState.rows]
        newRows.forEach(r => {
            r.tiles.forEach(t => {
                if (t?.graph?.linked_group === targetLinkedGroup) {
                    t.graph.xScaleDomain = newValue
                }
            })
        })
        setFormState({
            ...formState,
            rows: newRows
        })
        setValueForLinkedGroup(targetLinkedGroup, newValue)
    }

    const stateAndHelpersBag = {
        //Dashboard Form Values and Setter 
        formState,
        setFormState,
        //Linked Group Values and Setter
        linkedGroupSharedValues,
        setValueForLinkedGroup: updateLinkedGroupAndIncludedGraphs,
        //Selected Graph Value and Setter
        selectedGraphId,
        setSelectedGraphId,
        //Mouseover Timestamp/Graph Id
        sharedMouseOver, 
        setSharedMouseOver
    }

    return <DashboardingFormContext.Provider value={stateAndHelpersBag}>
        {children}
    </DashboardingFormContext.Provider>
}

export const updateDashboardContextFieldValue = (field, newValue) => {
    const { formState, setFormState } = useContext(DashboardingFormContext)
    const newState = { ...formState }
    newState[field] = newValue
    setFormState(newState)
}