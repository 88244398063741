import * as d3 from "d3";

export const doubleDomain = (domain, firstStreamed, lastStreamed) => {

    const [start, end] = domain;
    const halfDiff = Math.floor((end - start) / 2);

    const newStart = +start - halfDiff, newEnd = +end + halfDiff;
    const startBeforeFirst = newStart <= firstStreamed;
    const endAfterLast = newEnd >= lastStreamed;

    if (startBeforeFirst && endAfterLast) {
        return domain
    } else if (startBeforeFirst) {
        return [+start, +newEnd]
    } else if (endAfterLast) {
        return [+newStart, +end]
    } else {
        return [+newStart, +newEnd]
    }
}

export const drawXAxis = (seriesData, width, xAxisRef, xScaleDomain) => {
    
    const defaultExtent = d3.extent(seriesData.map(sd => sd.dt).flat(), d => d);
    const xScale = d3.scaleTime()
        .range([0, width])
        .domain(defaultExtent);

    const xAxisEl = d3.select(xAxisRef.current)
    xAxisEl.selectAll('*').remove()

    const xAxis = xAxisEl
        .append('svg')
        .attr('width', width)
        .attr('height', 30)
        .style("font-size", "7px")
        .call(d3.axisBottom(xScale).tickSize(3).tickSizeOuter(0));

    return { xAxis, xScale };
}

