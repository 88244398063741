import React from 'react';
import { ModalBase } from "../Elements/ModalBasic";

export const NameMatchOverrideModal = ({ isOpen, setIsOpen, onSubmit, matchingName }) => {

    const bodyContent = <div style={{textAlign: 'center'}}>
        <h4>It is strongly encouraged that you use a unique name for your Dashboard.</h4>
        <h5>Your dashboard title only differs from an existing dashboard's title in its capitalization.</h5>
        <h5>{`Are you sure you want to save this dashboard${matchingName ? ` with the name "${matchingName}"`: ''}?`}</h5>
    </div>

    return <ModalBase
        isOpen={isOpen}
        onSubmit={onSubmit}
        setIsOpen={setIsOpen}
        bodyContent={bodyContent}
        submitButtonText='Yes, Save'
        cancelButtonText='No, Continue Editing'
        titleText='Near-Identical Dashboard Name Detected'
    />
}