import React from 'react'
import { Alert } from "reactstrap"
import { yScaleMinMaxValidationMessage } from './GraphConfigModalValidation'


export const ValidationMessage = ({ min, max, parameterCount = 0, ...props }) => {

    const errorMessage = yScaleMinMaxValidationMessage(min, max, parameterCount);

    return errorMessage && <Alert color='danger'>{errorMessage}</Alert>;
}
