
import React from 'react'
import { DndTypes } from '../../../../common/utilities/dragTypes'
import { useDrop } from 'react-dnd'
import { YAxisJobParameter } from './DraggableJobParameter'

export const YAxisDropZone = ({ children, axisNumber, axis, updateAxes, graph, ...props }) => {

  const addPropertyToAxis = (parameter) => {
    const newParameter = {
      ...parameter,
      axis_id: axis.id
    }
    addParameters(newParameter)
  }

  const addParameters = (newParameter) => {
    const tempParameters = [...axis.parameters]
    tempParameters.push(newParameter)
    const newAxisState = {
      ...axis,
      parameters: tempParameters
    }
    updateAxes(newAxisState)
  }

  const removeParameter = (parameterToDelete) => {
    const newParameters = [...axis.parameters]
    const parameterToDeleteIndex = newParameters.indexOf(parameterToDelete);
    newParameters.splice(parameterToDeleteIndex, 1);

    const newAxisState = {
      ...axis,
      parameters: newParameters
    }
    updateAxes(newAxisState)
  }

  const getAxisParameters = (axis) => {
    return axis.parameters.map(parameter => {
      return <YAxisJobParameter parameter={parameter} onRemove={() => removeParameter(parameter)} />
    })
  }

  // eslint-disable-next-line
  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: DndTypes.JOBPARAMETER,
      drop: (item) => {
        addPropertyToAxis(item)
      },
      collect: monitor => ({
        isOver: !!monitor.isOver(),
      })
    }), [graph]) // graph is added as a dependency so the drop zone's memoization is updated when the graph is edited. 

  return (
    <div
      ref={drop}
      style={{
        position: 'relative',
        width: '100%',
        height: '100%',
        padding: '2% 2% 2% 2%'
      }}
    >
      {children}
      {getAxisParameters(axis)}
    </div>
  )

}