import React from 'react';

import '../../../common/styles/general.scss';

class Layout extends React.Component {

    render() {
        return (
            <div className={"wrapper user-wrapper"}>
                {this.props.children}
            </div>
        );
    }
}

export default Layout;