import axios from "axios";

import LoginStore from '../../../../common/modules/Auth/LoginStore';

// Set default params and headers for axios
axios.defaults.baseURL = process.env.API_BASE_URL;
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common.Accept = "application/json";

// GET method
export const get = async (endPoint, params = {}) => {
    var access_token = LoginStore.accessToken;
    const token = `Bearer ${access_token}`
    
    // return axios.get(endPoint, addHeaders(userConfig));
    // return new Promise(resolve => setTimeout(() => resolve({ id: 1, job: 'testjob' }), 1000))
    const result = await axios.get(
        endPoint,
        { params: params, headers: { Authorization: token } }
    );
    
    return result.data;
};


// POST method
export const post = async (endPoint, params = {}, userConfig = {}) => {
    var access_token = LoginStore.accessToken;
    const token = `Bearer ${access_token}`

    try {
        return axios.post(endPoint, params, { headers: { Authorization: token } });
    } catch (e) {

        return new Promise((_, reject) => reject("Axios Error:", e));
    }
};

export const put = async (endPoint, params = {}, userConfig = {}) => {
    var access_token = LoginStore.accessToken;
    const token = 'Bearer ' + access_token;
    return axios.put(endPoint, params, { headers: { Authorization: token } });
};
// DELETE method
export const remove = async (endPoint, userConfig = {}) => {
    //const token = await `Bearer ${cookie.load("token")}`;
    var access_token = LoginStore.accessToken;
    const token = 'Bearer ' + access_token;

    return axios.delete(endPoint, {
        headers: { Authorization: token }
    });
};
