import DashboardForm from './Form'
import { toast } from 'react-toastify'
import React, { useState, useEffect } from 'react'
import * as restAPI from '../../../../common/utilities/ApiService'
import LoginStore from '../../../../common/modules/Auth/LoginStore'
import { DashboardingFormContextProvider } from '../../Contexts/DashboardingFormContext'

const DEFAULT_FORM_STATE = {
    title: null,
    is_sharing_enabled: false,
    show_live_data: false,
    rows: [],
}

export default ({ history }) => {

    const [formState, setFormState] = useState(DEFAULT_FORM_STATE);
    const [promptNameMatchOverride, setPromptNameMatchOverride] = useState(false);
    const updateFieldValue = (field, newValue) => setFormState({
        ...formState,
        [field]: newValue
    })

    useEffect(() => {
        (async () => {
            const { user: { current_org_id } } = await restAPI.get(`/api/1/users/${LoginStore.user.id}`)
            setFormState(prev => ({ ...prev, org_id: current_org_id }))
        })()
    }, []);

    //#region helpers
    const notifySuccess = () => toast('Success!', { type: 'success' })
    const notifyError = (msg = 'Unexpected Error. Please Try Again.') => toast(msg, { type: 'error' })

    const saveDashboard = (formState, nameMatchOverride = false) => {
        const dashboardPayload = { ...formState }

        const isValid = dashboardPayload?.title?.trim()

        if (!isValid) {
            notifyError('A Dashboard Title is Required')
        } else {
            restAPI.post('/api/1/dashboarding/', {
                dashboard: dashboardPayload,
                nameMatchOverride
            }).then(res => {
                notifySuccess()
                history.push(`/dashboarding/${res.data.id}/edit`)
            }).catch(err => {
                if (err?.response?.data?.anticipated_error && err?.response?.status === 409 && !err?.response?.data?.is_exact) {
                    setPromptNameMatchOverride(true)
                } else {
                    const customMessage = (err?.response?.data?.error) || null
                    notifyError(customMessage)
                }
            })
        }
    }

    const saveDashboardWithNameMatchOverride = () => {
        saveDashboard(formState, true)
    }
    //#endregion helpers

    return <DashboardingFormContextProvider formValues={{ formState, setFormState }}>
        <DashboardForm
            canEdit
            formState={formState}
            setFormState={setFormState}
            saveDashboard={saveDashboard}
            updateFieldValue={updateFieldValue}
            promptNameMatchOverride={promptNameMatchOverride}
            setPromptNameMatchOverride={setPromptNameMatchOverride}
            saveDashboardWithNameMatchOverride={saveDashboardWithNameMatchOverride}
        />
    </DashboardingFormContextProvider>
}