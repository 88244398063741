import React from 'react';
import {
    FormGroup,
    Input,
} from 'reactstrap';

export default class FormElement extends React.Component {
   render() {
      return (
        <FormGroup row>
            <label className="col-xl-2 col-form-label">{this.props.label}</label>
            <div className="col-xl-10">
                <Input type="text"
                    placeholder={this.props.placeholder}
                    name={this.props.name}
                    value={this.props.value ? this.props.value : "" }
                    onChange={this.props.onChange} />
            </div>
        </FormGroup>
      );
   }
}