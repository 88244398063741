import React, { Component } from "react";
import { FormGroup } from "reactstrap";
import Select from 'react-select';

/**
 * 
 * show_inactive is a prop as opposed to state on WellSelector
 */
export default class WellMeasureSelector extends Component {
    constructor(props) {
        super(props);

        var options = this.props.jobs.filter(job_group => (this.props.show_inactive || job_group.has_active))
            .map((job_group) => {
                return {
                    label: job_group.name,
                    value: job_group.id,
                    options: job_group.jobs.filter((dataset) => (this.props.show_inactive || dataset.active))
                        .map((dataset) => {
                            return {
                                jobId: dataset.id,
                                key: dataset.name,
                                label: dataset.display_name,
                                value: dataset.id,

                                dataset_measures: dataset.dataset_measures,
                                dataset_id: dataset.dataset_id,
                                latest_value_ts: dataset.dataset_latest_value_ts,
                                active: dataset.active ? "true" : "false"
                            }
                        })
                }
            })

        let selected_dataset = null;
        let selected_measure = null;
        let selected_dataset_available_measures = [];

        if (this.props.jobId) {
            let selected_dataset_arr = options
                .map(optionGroup => optionGroup.options.filter(dataset => dataset.jobId === this.props.jobId))
                .filter(selected => selected.length && selected[0])
            if (selected_dataset_arr.length) {
                selected_dataset = selected_dataset_arr[0][0];
                selected_dataset_available_measures = selected_dataset.dataset_measures.map(dm => {
                    return {
                        dataset_id: dm.dataset_id,
                        key: dm.measure,
                        label: dm.measure,
                        value: dm.measure,

                        measure: dm.measure,
                        job_id: dm.jobId,
                        latest_entry_dt: dm.latest_entry_dt,
                        latest_value: dm.latest_value
                    }
                });
            }

            if (this.props.measure && selected_dataset) {
                let selected_measure_arr = selected_dataset_available_measures.filter(dm => dm.measure === this.props.measure)

                if (selected_measure_arr.length) {
                    selected_measure = selected_measure_arr[0];
                }
            }
        }


        this.state = {
            selected_dataset: selected_dataset,
            selected_dataset_available_measures: selected_dataset_available_measures,
            selected_measure: selected_measure,
            options: options
        }

        this.handleWellSelectionChange = this.handleWellSelectionChange.bind(this);
        this.handleMeasureSelectionChange = this.handleMeasureSelectionChange.bind(this);
        this.bubbleSelectionChange = this.bubbleSelectionChange.bind(this);
        this.handleInitialValues = this.handleInitialValues.bind(this);
    }

    bubbleSelectionChange() {
        let dataset_id = this.state.selected_dataset ?
            this.state.selected_dataset.dataset_id : null;

        let job_id = this.state.selected_dataset ?
            this.state.selected_dataset.jobId : null;

        let measure_name = this.state.selected_measure ?
            this.state.selected_measure.measure : null;

        this.props.handleSelectionChange(dataset_id, measure_name, job_id);
    }


    handleWellSelectionChange(selection) {
        //MITCH TODO: Null check to stop 
        console.log(selection);
        let available_measures = selection.dataset_measures.map(dm => {
            return {
                dataset_id: selection.dataset_id,
                key: dm,
                label: dm,
                value: dm,
                measure: dm,
                job_id: selection.jobId,
                latest_entry_dt: null,
                latest_value: null
            }
        });

        this.setState({
            selected_dataset_available_measures: available_measures,
            selected_dataset: selection,
            selected_measure: null
        }, this.bubbleSelectionChange);
    }

    handleMeasureSelectionChange(measure_selection) {
        this.setState({
            selected_measure: measure_selection
        }, this.bubbleSelectionChange);
    }

    handleInitialValues() {

    }

    render() {
        // var options = this.props.jobs.filter(job_group => {
        //     if (this.props.show_inactive || job_group.has_active)
        //         return job_group;
        // }).map((job_group) => {
        //     return {
        //         label: job_group.name,
        //         value: job_group.id,
        //         options: job_group.jobs.filter((dataset) => {
        //             if (this.props.show_inactive || dataset.active)
        //                 return dataset;
        //         }).map((dataset) => {
        //             return {
        //                 jobId: dataset.id,
        //                 key: dataset.name,
        //                 label: dataset.display_name,
        //                 value: dataset.id,

        //                 dataset_measures: dataset.dataset_measures,
        //                 dataset_id: dataset.dataset_id,
        //                 latest_value_ts: dataset.dataset_latest_value_ts,
        //                 active: dataset.active ? "true" : "false"
        //             }
        //         })
        //     }
        // })

        const groupStyles = {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            fontSize: '16px',
            fontWeight: 'bold',
            color: '#000'
        };

        const groupBadgeStyles = {
            backgroundColor: '#EBECF0',
            borderRadius: '2em',
            color: '#172B4D',
            display: 'inline-block',
            fontSize: 12,
            fontWeight: 'normal',
            lineHeight: '1',
            minWidth: 1,
            padding: '0.16666666666667em 0.5em',
            textAlign: 'center',
            float: "right"
        };

        const formatGroupLabel = data => (
            <div style={groupStyles} onClick={() => this.addSelectedByGroup(data.options)}>
                <span>{data.label}</span>
                <span style={groupBadgeStyles}>{data.options.length}</span>
            </div>
        );

        const optionStyles = {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginLeft: "25px"
        };

        const formatOptionLabel = (data) => {
            var selected = (this.state.selected_dataset && this.state.selected_dataset.value === data.value);

            return (
                (!selected) ?
                    <div style={optionStyles}>
                        {data.label}
                        <div>
                            {(data.latest_value_ts ?
                                <span style={groupBadgeStyles}>Latest Value: {data.latest_value_ts}</span> : <span></span>
                            )}
                        </div>
                    </div> :
                    <span>{data.label}</span>
            )
        };

        const selectStyles = {
            container: (base, state) => ({
                ...base,
                zIndex: "999"
            })
        };

        const selectStylesSecond = {
            container: (base, state) => ({
                ...base,
                zIndex: "998"
            })
        };

        return (
            <>
                <FormGroup row>
                    <label className="col-xl-2 col-form-label">Well</label>
                    <div className="col-xl-10">
                        <Select
                            className="form-control form-select-element"
                            placeholder="Select well"
                            options={this.state.options}
                            value={this.state.selected_dataset}
                            formatGroupLabel={formatGroupLabel}
                            formatOptionLabel={formatOptionLabel}
                            styles={selectStyles}
                            onChange={this.handleWellSelectionChange} />
                    </div>
                </FormGroup>

                <FormGroup row>
                    <label className="col-xl-2 col-form-label">Field</label>
                    <div className="col-xl-10">
                        <Select
                            className="form-control form-select-element"
                            placeholder={this.state.selected_dataset ? "Select a field" : "Select well to populate list"}
                            options={this.state.selected_dataset_available_measures}
                            value={this.state.selected_measure}
                            styles={selectStylesSecond}
                            onChange={this.handleMeasureSelectionChange} />

                        {(this.state.selected_measure) ?
                            <>
                                <small id="passwordHelpBlock" className="form-text text-muted">
                                    Latest Entry: {this.state.selected_measure.latest_value} @ {this.state.selected_measure.latest_entry_dt}
                                </small>
                            </> : <></>
                        }

                    </div>
                </FormGroup>


            </>
        )

    }


}