import React from 'react'
//hidingLiveData
// import Tooltip from './Tooltip'
import { Row, /*Col*/ } from 'reactstrap'
import { FormTextInput, /*FormCheckboxInput*/ } from '../Core/FormInputs'

export const GraphPropertiesControl = ({ graph, updateLocalStateGraph }) => {

    const setGraphName = (graph_name) => {
        updateLocalStateGraph('graph_name', graph_name)
    }

    //hidingLiveData
    // const setShowLiveData = (show_live_data) => {
    //     updateLocalStateGraph('show_live_data', show_live_data)
    // }

    //hidingLiveData
    // const canGraphBeLive = (() => {
    //     const allowed = (graph.time_amount * graph.unit_of_time?.duration ?? 0) <= 12 * 60 * 60
    //     if (!allowed && graph.show_live_data) {
    //         setShowLiveData(false);
    //     }
    //     return allowed
    // })()

    return <>
        <Row>
            <FormTextInput
                label='Graph Name'
                placeholder='Enter Graph Name'
                value={graph.graph_name}
                onChange={(e) => setGraphName(e.target.value)}
            />
        </Row>
        {/* hidingLiveData */}
        {/*
        <Row>
            <Col>
                <Tooltip
                    minimal
                    intent='warning'
                    disabled={canGraphBeLive}
                    className={canGraphBeLive ? '' : 'notAllowed'}
                    content='Live Graphs cannot exceed a 12-hour time window'
                >
                    <div style={{ marginLeft: "1.3rem", height: '1.5rem' }}>
                        <FormCheckboxInput
                            disabled={!canGraphBeLive}
                            label='Allow Data Streaming'
                            value={graph.show_live_data}
                            onChange={() => setShowLiveData(!graph.show_live_data)}
                        />
                    </div>
                </Tooltip>
            </Col>
</Row>*/}
        {/* <Row>
            <Col style={{ paddingTop: '1em' }}>
                Commented out until performance limitation for High/Extreme can be addressed.
                <GraphResolutionSelect graph={graph} updateLocalStateGraph={updateLocalStateGraph} />
            </Col>
        </Row>*/}
    </>
}
