import React, { Component } from "react";
import { Row, Col } from "reactstrap";

import Plot from 'react-plotly.js';

/*
Default Colors: 

[
    -- '#1f77b4',  # muted blue
    -- '#ff7f0e',  # safety orange
    '#2ca02c',  # cooked asparagus green
    '#d62728',  # brick red
    '#9467bd',  # muted purple
    '#8c564b',  # chestnut brown
    '#e377c2',  # raspberry yogurt pink
    '#7f7f7f',  # middle gray
    '#bcbd22',  # curry yellow-green
    '#17becf'   # blue-teal
]
*/


export default class LogLogPlot extends Component {

    shapes = [];
    min_x = 0;
    max_x = 0;

    constructor(props) {
        super(props);

        this.shapes = [];

    }

    render() {
        const min_t = Math.min([...this.props.data["g_time"]]);

        return(
            <>
            <Row>
                <Col md={12}>
            <Plot 
                data={[
                    {
                        x: this.props.data["g_time"],
                        y: this.props.data["dP"],
                        type: 'scatter',
                        mode: 'lines+points',
                        marker: { color: 'red' },
                        name: 'Pressure',
                        line: {
                            shape: 'spline'
                        }
                    },
                    {
                        x: this.props.data["g_time"],
                        y: this.props.data["dPdt"],
                        type: 'scatter',
                        mode: 'lines+points',
                        marker: { color: 'yellow' },
                        name: 'Semi Log',
                        yaxis: 'y2',
                        line: {
                            shape: 'spline'
                        }
                    },
                    {
                        x: [0.177827941,0.316227766,0.562341325,1,1.77827941,3.16227766,5.623413252,10,17.7827941,31.6227766],
                        y: [0.00001,0.0001,0.001,0.01,0.1,1,10,100,1000,10000],
                        type: 'scatter',
                        mode: 'lines+points',
                        name: 'Char = 1/4',
                        yaxis: 'y3',
                        marker: { color: '#9467bd' },
                        visible: "legendonly"
                    },
                    {
                        x: [0.031622777,0.1,0.316227766,1,3.16227766,10,31.6227766],
                        y: [0.01,0.1,1,10,100,1000,10000],
                        type: 'scatter',
                        mode: 'lines+points',
                        name: 'Char = 1/2',
                        yaxis: 'y4',
                        marker: { color: '#2ca02c' },
                        visible: "legendonly"
                    },
                    {
                        x: [31.622777,10.000000,3.162278,1.000000,0.316228,0.100000],
                        y: [0.100000,1.000000,10.000000,100.000000,1000.000000,10000.000000],
                        type: 'scatter',
                        mode: 'lines+points',
                        name: 'Char = -1/2',
                        yaxis: 'y5',
                        marker: { color: '#17becf' },
                        visible: "legendonly"
                    },
                    {
                        x: [39.810717,7.079458,1.258925,0.223872,0.039811],
                        y: [1.000000,10.000000,100.000000,1000.000000,10000.000000],
                        type: 'scatter',
                        mode: 'lines+points',
                        name: 'Char = -3/4',
                        yaxis: 'y6',
                        marker: { color: '#8c564b' },
                        visible: "legendonly"
                    },
                    {
                        x: [31.622777,3.162278,0.316228,0.031623,0.003162],
                        y: [1.000000,10.000000,100.000000,1000.000000,10000.000000],
                        type: 'scatter',
                        mode: 'lines+points',
                        name: 'Char = -1',
                        yaxis: 'y7',
                        marker: { color: '#e377c2' },
                        visible: "legendonly"
                    }


                ]}
                layout={{
                    title: '',
                    paper_bgcolor: '#1b1c1e',
                    plot_bgcolor: '#212125',
                    xaxis: {
                        domain: [0.05, 0.87],
                        range: [min_t, 1.5],
                        title: '',
                        rangemode: 'tozero',
                        //range: [0, max_t],
                        type: 'log',
                        gridcolor: '#3d3d44',
                        gridwidth: 1
                    },
                    yaxis: {
                        title: 'Delta P',
                        //fixedrange: true,
                        rangemode: 'tozero',
                        range: [.5, 5],
                        type: 'log',
                        position: 0.0,
                        gridcolor: '#3d3d44',
                        gridwidth: 1,
                    },
                    yaxis2: {
                        overlaying: 'y',
                        side: 'left',
                        anchor: 'x',
                        title: 'Derivative',
                        rangemode: 'tozero',
                        type: 'log',
                        position: 0.02,
                        scaleanchor: "y",
                        scaleratio: 1,
                        range: [.5, 5],
                    },
                    yaxis3: {
                        overlaying: 'y',
                        side: 'right',
                        anchor: 'x',
                        title: 'Slope = 1/4',
                        rangemode: 'tozero',
                        type: "log",
                        color: '#9467bd',
                        scaleanchor: "y",
                        scaleratio: 1,
                        range: [.5, 5],
                    },
                    yaxis4: {
                        overlaying: 'y',
                        side: 'right',
                        title: 'Slope = 1/2',
                        rangemode: 'tozero',
                        type: "log",
                        scaleanchor: "y",
                        scaleratio: 1,
                        range: [.5, 5],
                        position: 0.90,
                        color: '#2ca02c',
                    },
                    yaxis5: {
                        overlaying: 'y',
                        side: 'right',
                        title: 'Slope = -1/2',
                        rangemode: 'tozero',
                        type: "log",
                        scaleanchor: "y",
                        scaleratio: 1,
                        range: [0, 4.5],
                        position: 0.93,
                        color: '#17becf',
                    },
                    yaxis6: {
                        overlaying: 'y',
                        side: 'right',
                        title: 'Slope = -3/4',
                        rangemode: 'tozero',
                        type: "log",
                        scaleanchor: "y",
                        scaleratio: 1,
                        range: [0, 4.5],
                        position: 0.96,
                        color: '#8c564b',
                    },
                    yaxis7: {
                        overlaying: 'y',
                        side: 'right',
                        title: 'Slope = -1',
                        rangemode: 'tozero',
                        type: "log",
                        scaleanchor: "y",
                        scaleratio: 1,
                        range: [0, 4.5],
                        position: .99,
                        color: '#e377c2',
                        visible: false
                    },
                    autosize: true,
                    dragmode: "pan"
                    //shapes: this.shapes
                }}
                useResizeHandler
                style={{ width: '100%', height: '500px' }}
                config={{ editable: true }}
                onLegendClick={(e) => {
                    console.log("legendclick");
                    console.log(e);
                    for (var idx in e.data) {
                        console.log(e.data[idx].visible);
                    }
                }}
                onRestyle={(e) => {
                    console.log("restyle");
                    console.log(e);

                    if (e[0] && "visible" in e[0]) {
                        
                        if (e[0].visible[0]) {
                            console.log("Yes")
                        } else {
                            console.log("No")
                        }
                    }
                    
                }}
            />
            </Col>
            </Row>

            <Row>
                <Col md={12}>
                
                </Col>
                <Col md={12}>
            
                </Col>
            </Row>
            </>
        )
    }
}

