import React from 'react'

//Dev Note: You likely want to use the memoized LiveBadge 
//          exported below instead of RawLiveBadge
const RawLiveBadge = ({ isLive, noText, containerStyle = {} }) => {
    const dynamicLiveClassName = isLive ? 'on' : ''

    return <div className='liveBadge-container' style={containerStyle}>
        <div className={`badge liveBadge ${dynamicLiveClassName}`}>
            <div className='liveBadge-content'>

                {!noText && isLive &&
                    <span style={{ marginRight: '12px' }}>
                        LIVE
                    </span>
                }

                <span className={`liveBadge-icon ${dynamicLiveClassName}`}>
                    <svg height='12' width='12' viewBox='0 0 512 512'>
                        {isLive
                            ? <path d="M192 32c0-17.7 14.3-32 32-32C383.1 0 512 128.9 512 288c0 17.7-14.3 32-32 32s-32-14.3-32-32C448 164.3 347.7 64 224 64c-17.7 0-32-14.3-32-32zM60.6 220.6L164.7 324.7l28.4-28.4c-.7-2.6-1.1-5.4-1.1-8.3c0-17.7 14.3-32 32-32s32 14.3 32 32s-14.3 32-32 32c-2.9 0-5.6-.4-8.3-1.1l-28.4 28.4L291.4 451.4c14.5 14.5 11.8 38.8-7.3 46.3C260.5 506.9 234.9 512 208 512C93.1 512 0 418.9 0 304c0-26.9 5.1-52.5 14.4-76.1c7.5-19 31.8-21.8 46.3-7.3zM224 96c106 0 192 86 192 192c0 17.7-14.3 32-32 32s-32-14.3-32-32c0-70.7-57.3-128-128-128c-17.7 0-32-14.3-32-32s14.3-32 32-32z" />
                            : <path d="M75.8 304.8L1 35.7c-.7-2.5-1-5-1-7.5C0 12.6 12.6 0 28.2 0H482.4C498.8 0 512 13.2 512 29.6c0 1.6-.1 3.3-.4 4.9L434.6 496.1c-1.5 9.2-9.5 15.9-18.8 15.9c-9.2 0-17.1-6.6-18.7-15.6L336 160 307.2 303.9c-1.9 9.3-10.1 16.1-19.6 16.1c-9.2 0-17.2-6.2-19.4-15.1L240 192 210.6 368.2c-1.5 9.1-9.4 15.8-18.6 15.8s-17.1-6.7-18.6-15.8L144 192 115.9 304.3c-2.3 9.2-10.6 15.7-20.1 15.7c-9.3 0-17.5-6.2-20-15.2z" />
                        }
                    </svg>
                </span>

            </div>
        </div>
    </div >
}

const badgePropsAreEqual = (prevBadge, nextBadge) => prevBadge.isLive === nextBadge.isLive && prevBadge.isWidget === nextBadge.isWidget
export const LiveBadge = React.memo(RawLiveBadge, badgePropsAreEqual)

// Icon path src:
/* <!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --> */