import _ from 'lodash';
import { Row, Col } from 'reactstrap';
import React, { Component } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from 'highcharts-react-official';

require("highcharts/modules/annotations")(Highcharts);

Highcharts.setOptions({
    colors: ['#ad0101', '#f2db0e', '#4eb273', '#f7a35c', '#8085e9', '#f15c80', '#64E572', '#FF9655', '#FFF263', '#6AF9C4'],
});

export default class ParameterPlot extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentSelectedParameter: "FIP"
        }

        this.updateSelectedParameter = this.updateSelectedParameter.bind(this);
        this.toggleSelectedParameter = this.toggleSelectedParameter.bind(this);
    }

    updateSelectedParameter(e) {
        this.setState({
            currentSelectedParameter: e.target.value
        })
    }

    toggleSelectedParameter() {
        if (this.state.currentSelectedParameter === "FIP") {
            this.setState({
                currentSelectedParameter: "ISIP"
            })
        } else {
            this.setState({
                currentSelectedParameter: "FIP"
            })
        }
    }

    render() {
        var callbackSetFIP = this.props.callbackSetFIP;
        var callbackSetISIP = this.props.callbackSetISIP;
        var callbackSetExtremes = this.props.callbackSetExtremes;
        var debouncedSetExtremes = _.debounce(callbackSetExtremes, 1000);

        var initialextremeMin = this.props.extremeMin ? parseFloat(this.props.extremeMin) : undefined;
        var initialextremeMax = this.props.extremeMax ? parseFloat(this.props.extremeMax) : undefined;
        var currentSelectedParameter = this.state.currentSelectedParameter;

        var toggleSelectedParameter = this.toggleSelectedParameter;

        var pSpline = [];
        var rSpline = []
        
        for (let idx in this.props.data["p_ts"]) {
            pSpline.push([this.props.data["p_ts"][idx], this.props.data["p"][idx]])
        }

        if (this.props.data["r"].length) {
            for (let idx in this.props.data["r_ts"]) {
                rSpline.push([this.props.data["r_ts"][idx], this.props.data["r"][idx]])
            }
        }

        var start_ts = Math.min(...this.props.data["p_ts"]);

        let series = [
            {
                name: "Pressure",
                data: pSpline,
                tooltip: { valueSuffix: " psi" },
                marker: { enabled: false },
                visible: true,
                type: "line",
                yAxis: 0,
                lineWidth: 3,
                events: {
                    click: function (e) {
                        let exists
                        if (currentSelectedParameter === "FIP") {
                            exists = false;
                            for (let annotation of e.point.series.chart.annotations) {
                                if (annotation.userOptions.id === "initiation") {
                                    exists = true;
                                }
                            }

                            if (exists) {
                                e.point.series.chart.removeAnnotation("initiation"); 
                            }

                            e.point.series.chart.addAnnotation({
                                id: 'initiation',
                                labels: [{
                                    point: {
                                        x: e.point.x,
                                        xAxis: 0,
                                        y: e.point.y,
                                        yAxis: 0
                                    },
                                    text: "Initiation"
                                }],
                                draggable: 'xy'
                            })

                            callbackSetFIP(start_ts, e.point.x);

                            if (!exists) {
                                toggleSelectedParameter();
                            }
                        } else if (currentSelectedParameter === "ISIP") {
                            exists = false;
                            for (let annotation of e.point.series.chart.annotations) {
                                if (annotation.userOptions.id === "shutin") {
                                    exists = true;
                                }
                            }

                            if (exists) {
                                e.point.series.chart.removeAnnotation("shutin"); 
                            } 

                            e.point.series.chart.addAnnotation({
                                id: 'shutin',
                                labels: [{
                                    point: {
                                        x: e.point.x,
                                        xAxis: 0,
                                        y: e.point.y,
                                        yAxis: 0
                                    },
                                    text: "Shut In"
                                }],
                                draggable: 'xy'
                            });

                            callbackSetISIP(start_ts, e.point.x);
                        }
                    }
                }
            },
            {
                name: "Rate",
                data: rSpline,
                tooltip: { valueSuffix: " bbl" },
                marker: { enabled: false },
                visible: true,
                type: "line",
                yAxis: 1,
                lineWidth: 1,
            }
        ];

        var options = {
            annotations: [],
            chart: {
                height: '580px',
                zoomType: 'xy',
                panning: true,
                panKey: 'shift'
            },
            yAxis: [
                {
                    title: { text: 'Pressure' },
                    opposite: false
                },
                {
                    title: { text: 'Rate' },
                    opposite: true
                },
            ],
            xAxis: {
                crosshair: true,
                events: {
                    setExtremes: function (e) {
                        debouncedSetExtremes(e.min, e.max);
                    }
                },
                ordinal: false,
                type: 'datetime',
                min: initialextremeMin,
                max: initialextremeMax
            },
            legend: { enabled: true },
            rangeSelector: {
                enabled: false,
                inputEnabled: false,
            },
            series: series,
        }

        return (
            <>
                <Row>
                    <Col md={12}>
                        <fieldset className="form-group" style={{ paddingBottom: "0", marginBottom: "0" }}>
                            <div className="row">
                                <legend className="col-form-label col-auto" style={{ paddingTop: "0" }}>Active Editable Value: </legend>
                                <div className="col-auto">
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="gridRadios" id="radioFIP" value="FIP" checked={this.state.currentSelectedParameter === "FIP"} onChange={this.updateSelectedParameter} />
                                        <label className="form-check-label" htmlFor="radioFIP">
                                            Fracture Initiation
                                        </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="gridRadios" id="radioISIP" value="ISIP" checked={this.state.currentSelectedParameter === "ISIP"} onChange={this.updateSelectedParameter} />
                                        <label className="form-check-label" htmlFor="radioISIP">
                                            Shut-In (ISIP)
                                        </label>

                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </Col>
                </Row>

                <HighchartsReact
                    id='highChart'
                    highcharts={Highcharts}
                    constructorType={'stockChart'}
                    options={options}
                    oneToOne={true}
                />
            </>
        )
    }
}