import React from 'react';
import {
    Row, Col, Button, FormGroup, Modal,
    ModalHeader, ModalBody, ModalFooter,
    TabContent, TabPane, Nav, NavLink, NavItem
} from 'reactstrap';
import Select from 'react-select';
import CreatableSelect from 'react-select/lib/Creatable';
import FormElement from "../Core/Elements/FormElement";

import * as restAPI from '../../../../common/utilities/ApiService';
import LoginStore from '../../../../common/modules/Auth/LoginStore';

import classnames from 'classnames';

import ReactTable from "react-table";
import "react-table/react-table.css";
import WellMeasureSelector from '../Elements/WellMeasureSelector';

var fieldList = [
    { value: "pressure", label: "Pressure" },
    { value: "rate", label: "Rate" },
    { value: "temperature", label: "Temperature" },
];

var conditionTypeList = [
    { value: 1, label: ">" },
    { value: 2, label: ">=" },
    { value: 3, label: "<" },
    { value: 4, label: "<=" },
    { value: 5, label: "Change > x in 1 minute" },
    { value: 6, label: "Change > x in 1 hour" },
]

//var conditionList = [" > ", " >= ", " < ", " <= ", " dX/dt (t=1m) > ", " dX/dt (t=1h) > "]

const components = {
    DropdownIndicator: null,
};

const createOption = (label) => ({
    label,
    value: label,
});

export default class Alerts extends React.Component {
    constructor(req) {
        super()

        this.state = {
            isLoaded: false,
            alerts: [],
            groups: [],

            alert: {
                id: null,
                job_id: null,
                subject: null,
                user_list: null,
                phone_list: null,
                email_list: null,
                field_name: null,
                condition: null,
                set_point_value: null,
                refractory_period: 60
            },

            group: {
                id: null,
                user_id: null,
                u_list: null
            },

            availableFields: [],
            availableConditionTypes: [],
            availableUsers: [],
            availableJobList: [],
            formReady: false,

            selectedUsers: [],

            inputEmail: "",
            emailList: [],

            inputPhone: "",
            phoneList: [],

            selected_condition: null,
            selected_field_name: null,
            state_count: 1,
            modal: false,
            modal2: false,
            activeTab: '1',

            alert_templates: [],
            alert_template: {
                id: null,
                job_id: null,
                subject: "",
                user_list: "",
                phone_list: "",
                email_list: "",
                field_name: null,
                condition: null,
                set_point_value: null,
                refractory_period: 60
            },
            showNewAlertModal: false,
            triggers: [],
            all_jobs: []
        }

        this.toggle = this.toggle.bind(this);
        this.newAlertModal = this.newAlertModal.bind(this);
        this.handleModalInputChange = this.handleModalInputChange.bind(this);
        this.handleAlertUsersChange = this.handleAlertUsersChange.bind(this);
        this.handleAlertEmailsChange = this.handleAlertEmailsChange.bind(this);
        this.handleAlertPhonesChange = this.handleAlertPhonesChange.bind(this);

        this.handleFieldChange = this.handleFieldChange.bind(this);
        this.handleConditionChange = this.handleConditionChange.bind(this);
        this.handleJobChange = this.handleJobChange.bind(this);

        this.submitAlert = this.submitAlert.bind(this);
        this.editRow = this.editRow.bind(this);

        //new code for alert group
        this.toggle2 = this.toggle2.bind(this);
        this.newGroupModal = this.newGroupModal.bind(this);
        this.handleGroupUsersChange = this.handleGroupUsersChange.bind(this);
        this.submitAlertGroup = this.submitAlertGroup.bind(this);
        this.editRow2 = this.editRow2.bind(this);

        // alert template
        this.toggleTemplate = this.toggleTemplate.bind(this);
        this.newAlertTemplateModal = this.newAlertTemplateModal.bind(this);
        this.submitTemplate = this.submitTemplate.bind(this);

        this.handleWellMeasureSelectionChange = this.handleWellMeasureSelectionChange.bind(this);
    }


    async componentDidMount() {
        await restAPI.get('/api/1/wells/alerts').then(res => {
            this.setState({ alerts: res.alerts });
        });

        await restAPI.get('/api/1/users?orgFilter=1').then(res => {
            this.setState({ availableUsers: res.users });
        });

        await restAPI.get('/api/1/wells/alertsGroup?userId=' + LoginStore.user.id).then(res => {
            this.setState({ groups: res });
        });

        await restAPI.get('/api/1/wells/alert_templates').then(res => {
            this.setState({ alert_templates: res.alerts });
        });

        await restAPI.get('/api/1/jobs').then(res => {
            var availableJobList = [];

            res.forEach(job => {
                availableJobList.push({ value: job.id, label: job.display_name, active: job.active });
            });

            this.setState({
                availableJobList: availableJobList

            });
        });

        await restAPI.get('/api/1/jobs?__v=2').then(res => {
            this.setState({
                all_jobs: res,
                formReady: true
            });
        });


        await restAPI.get('/api/1/wells/triggers').then(res => {
            this.setState({ triggers: res.triggers });
        });

        this.setState({
            availableFields: fieldList,
            availableConditionTypes: conditionTypeList
        })
    }

    toggler(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    handleModalInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        var alert = this.state.alert;
        var alert_template = this.state.alert_template;

        alert[name] = value;
        alert_template[name] = value;

        this.setState({
            alert: alert,
            alert_template: alert_template,
        });
    }

    handleFieldChange(field) {
        var alert = this.state.alert;
        var alert_template = this.state.alert_template;

        alert.field_name = field.value;
        alert_template.field_name = field.value;

        this.setState({
            alert: alert,
            selected_field_name: field,
            alert_template: alert_template,
        });
    }

    handleConditionChange(condition) {
        var alert = this.state.alert;
        var alert_template = this.state.alert_template;

        alert.condition = condition.value;
        alert_template.condition = condition.value;

        this.setState({
            alert: alert,
            selected_condition: condition,
            alert_template: alert_template
        });
    }

    handleJobChange(job) {
        var alert = this.state.alert;
        var alert_template = this.state.alert_template;

        alert.job_id = job.value;
        alert_template.job_id = job.value;

        this.setState({
            alert: alert,
            selected_job: job,
            alert_template: alert_template
        });
    }

    handleWellMeasureSelectionChange(dataset_id, measure_name) {
        var alert = this.state.alert;
        var alert_template = this.state.alert_template;

        alert.job_id = dataset_id;
        alert_template.job_id = measure_name;

        alert.field_name = dataset_id;
        alert_template.field_name = measure_name;

        console.log(dataset_id);
        console.log(measure_name);

        this.setState({
            alert: alert,
            alert_template: alert_template
        });
    }

    handleAlertUsersChange(selectedUserArray) {
        this.setState({
            selectedUsers: selectedUserArray,
        });
    }

    //for alert groups
    handleGroupUsersChange(selectedUserArray) {
        this.setState({
            selectedUsers: selectedUserArray,
        });
    }

    handleAlertEmailsChange = (emailList, actionMeta) => {
        this.setState({ emailList });
    };

    handleAlertEmailsInputChange = (inputEmail) => {
        this.setState({ inputEmail });
    };

    handleAlertEmailsKeyDown = (event) => {
        const { inputEmail, emailList } = this.state;

        if (!inputEmail) return;

        switch (event.key) {
            case 'Enter':
            case 'Tab':
                // if (isValidEmail(inputEmail))
                if (inputEmail) {
                    this.setState({
                        inputEmail: '',
                        emailList: [...emailList, createOption(inputEmail)],
                    });
                }

                event.preventDefault();

                break
            default: break
        }
    };

    handleAlertPhonesChange = (phoneList, actionMeta) => {
        this.setState({ phoneList });
    };

    handleAlertPhonesInputChange = (inputPhone) => {
        this.setState({ inputPhone });
    };

    handleAlertPhonesKeyDown = (event) => {
        const { inputPhone, phoneList } = this.state;

        if (!inputPhone) return;

        switch (event.key) {
            case 'Enter':
            case 'Tab':
                // if (isValidPhone(inputPhone))
                if (inputPhone) {
                    this.setState({
                        inputPhone: '',
                        phoneList: [...phoneList, createOption(inputPhone)],
                    });
                }

                event.preventDefault();
                break
            default: break
        }
    };

    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    newAlertModal() {
        this.setState({
            alert: {
                id: null,
                job_id: null,
                subject: null,
                user_list: null,
                phone_list: null,
                email_list: null,
                field_name: null,
                condition: null,
                set_point_value: null,
                refractory_period: 60
            },
            emailList: [],
            phoneList: [],
            selectedUsers: [],
            state_count: this.state.state_count + 1,
            selected_condition: null,
            selected_field_name: null
        });

        this.toggle();
    }

    // Begin For alert template 

    toggleTemplate() {
        this.setState(prevState => ({
            showNewAlertModal: !prevState.showNewAlertModal
        }));
    }

    newAlertTemplateModal() {
        this.setState({
            alert_template: {
                id: null,
                job_id: null,
                subject: null,
                user_list: null,
                phone_list: null,
                email_list: null,
                field_name: null,
                condition: null,
                set_point_value: null,
                refractory_period: 60
            },
            emailList: [],
            phoneList: [],
            selectedUsers: [],
            state_count: this.state.state_count + 1,
            selected_condition: null,
            selected_field_name: null
        });

        this.toggleTemplate();
    }

    submitTemplate(e) {
        e.preventDefault();

         var email_list_string = this.state.emailList.map(ed => ed.value).join("|");

         var phone_list_string = this.state.phoneList.map(ed => ed.value).join("|");

         var user_list_string = this.state.selectedUsers.map(ed => ed.value).join("|");

        this.setState({
            email_list : email_list_string,
            phone_list : phone_list_string,
            user_list : user_list_string
        });

        const alertPayload = {
            ...this.state.alert_template,
            email_list : email_list_string,
            phone_list: phone_list_string,
            user_list: user_list_string
        }


        restAPI.post('/api/1/wells/alert_templates', alertPayload).then(res => {
            restAPI.get('/api/1/wells/alert_templates').then(res => {
                this.setState({
                    alert_templates: res.alerts,
                    showNewAlertModal: false
                });
            }).catch(err => {
                console.log(err);
            });
        }).catch(err => {
            console.log(err);
        });
    }

    duplicateTemplate(al) {
        restAPI.post('/api/1/wells/alert_templates/' + al.id, {
            "operation": "DUPLICATE"
        }).then(res => {
            restAPI.get('/api/1/wells/alert_templates').then(res => {
                this.setState({
                    alert_templates: res.alerts
                });
            }).catch(err => {
                console.log(err);
            })
        }).catch(err => {
            console.log(err);
        });
    }

    editTemplate(a, isDelete = false) {
        if (isDelete) {
            restAPI.post('/api/1/wells/alert_templates/' + a.id, {
                "operation": "DELETE"
            }).then(res => {
                restAPI.get('/api/1/wells/alert_templates').then(res => {
                    this.setState({
                        alert_templates: res.alerts,
                        modal: false
                    });
                }).catch(err => {
                    console.log(err);
                })
            }).catch(err => {
                console.log(err);
            });
        } else {
            // Populate modal values
            var selectedUsers = [];
            var sUs = a.user_list.split("|").filter(u => u.length > 0);

            sUs.forEach(sU => {
                this.state.availableUsers.forEach(au => {
                    if (au.id === parseInt(sU)) {
                        selectedUsers.push({
                            key: au.id,
                            label: au.name,
                            value: au.id
                        })
                    }
                })
            });

            var selected_jobs = [];
            selected_jobs.push({
                key: a.job.id,
                label: a.job.display_name,
                value: a.job.id
            })

            var selected_condition = conditionTypeList.filter(ct => ct.value === a.condition);

            var selected_field = fieldList.filter(f => f.value === a.field_name)

            this.setState({
                alert_template: {
                    id: a.id,
                    job_id: a.job_id,
                    subject: a.subject,
                    user_list: a.user_list,
                    phone_list: a.phone_list,
                    email_list: a.email_list,
                    field_name: a.field_name,
                    condition: a.condition,
                    set_point_value: a.set_point_value,
                    refractory_period: a.refractory_period,
                },
                emailList: a.email_list.split("|").filter(e => e.length).map(e => createOption(e)),
                phoneList: a.phone_list.split("|").filter(p => p.length).map(p => createOption(p)),
                selectedUsers: selectedUsers,
                state_count: this.state.state_count + 1,
                selected_condition: selected_condition,
                selected_field_name: selected_field,
                selected_job: selected_jobs,
            })

            this.toggleTemplate();
        }
    }

    //End For Alert template

    editRow(a, isDelete = false) {
        if (isDelete) {
            restAPI.post('/api/1/wells/alerts/' + a.id, {
                "operation": "DELETE"
            }).then(res => {
                restAPI.get('/api/1/wells/alerts').then(res => {
                    this.setState({
                        alerts: res.alerts,
                        modal: false
                    });
                }).catch(err => {
                    console.log(err);
                })
            }).catch(err => {
                console.log(err);
            });
        } else {
            var selectedUsers = [];
            var sUs = a.user_list.split("|").filter(u => u.length > 0);

            sUs.forEach(sU => {
                this.state.availableUsers.forEach(au => {
                    if (au.id === parseInt(sU)) {
                        selectedUsers.push({
                            key: au.id,
                            label: au.name,
                            value: au.id
                        })
                    }
                })
            });

            var selected_jobs = [];
            selected_jobs.push({
                key: a.job.id,
                label: a.job.display_name,
                value: a.job.id
            })

            var selected_condition = conditionTypeList.filter(ct => ct.value === a.condition);

            var selected_field = fieldList.filter(f => f.value === a.field_name)

            this.setState({
                alert: {
                    id: a.id,
                    job_id: a.job_id,
                    subject: a.subject,
                    user_list: a.user_list,
                    phone_list: a.phone_list,
                    email_list: a.email_list,
                    field_name: a.field_name,
                    condition: a.condition,
                    set_point_value: a.set_point_value,
                    refractory_period: a.refractory_period,
                },
                emailList: a.email_list.split("|").filter(e => e.length).map(e => createOption(e)),
                phoneList: a.phone_list.split("|").filter(p => p.length).map(p => createOption(p)),
                selectedUsers: selectedUsers,
                state_count: this.state.state_count + 1,
                selected_condition: selected_condition,
                selected_field_name: selected_field,
                selected_job: selected_jobs,
            })

            this.toggle();
        }
    }

    duplicateRow(al) {
        restAPI.post('/api/1/wells/alerts/' + al.id, {
            "operation": "DUPLICATE"
        }).then(res => {
            restAPI.get('/api/1/wells/alerts').then(res => {
                this.setState({
                    alerts: res.alerts
                });
            }).catch(err => {
                console.log(err);
            })
        }).catch(err => {
            console.log(err);
        });
    }

    submitAlert(e) {
        e.preventDefault();
        var email_list_string = this.state.emailList.map(ed => ed.value).join("|");

        var phone_list_string = this.state.phoneList.map(ed => ed.value).join("|");

        var user_list_string = this.state.selectedUsers.map(ed => ed.value).join("|");

        this.setState({
            email_list : email_list_string,
            phone_list : phone_list_string,
            user_list : user_list_string
        });

        const alertPayload = {
            ...this.state.alert_template,
            email_list : email_list_string,
            phone_list: phone_list_string,
            user_list: user_list_string
        }

        restAPI.post('/api/1/wells/alerts', alertPayload).then(res => {
            restAPI.get('/api/1/wells/alerts').then(res => {
                this.setState({
                    alerts: res.alerts,
                    modal: false
                });
            }).catch(err => {
                console.log(err);
            })
        }).catch(err => {
            console.log(err);
        });
    }


    // alert group code starts from here

    toggle2() {
        this.setState(prevState => ({
            modal2: !prevState.modal2
        }));
    }

    newGroupModal() {
        this.setState({
            group: {
                id: null,
                user_id: LoginStore.user.id,
                u_list: null
            },
            selectedUsers: [],
            state_count: this.state.state_count + 1
        });

        this.toggle2();
    }

    editRow2(a, isDelete = false) {
        if (isDelete) {
            restAPI.post('/api/1/wells/alertsGroup/' + a.id, {
                "operation": "DELETE"
            }).then(res => {
                restAPI.get('/api/1/wells/alertsGroup?userId=' + LoginStore.user.id).then(res => {
                    this.setState({
                        groups: res,
                        modal2: false
                    });
                }).catch(err => {
                    console.log(err);
                })
            }).catch(err => {
                console.log(err);
            });
        } else {
            // Populate modal2 values

            var selectedUsers = [];
            var sUs = a.u_list.split("|").filter(u => u.length > 0);

            sUs.forEach(sU => {
                this.state.availableUsers.forEach(au => {
                    if (au.id === parseInt(sU)) {
                        selectedUsers.push({
                            key: au.id,
                            label: au.name,
                            value: au.id
                        })
                    }
                })
            });

            this.setState({
                group: {
                    id: a.id,
                    user_id: a.user_id,
                    u_list: a.u_list,
                },
                selectedUsers: selectedUsers,
                state_count: this.state.state_count + 1
            })

            this.toggle2();
        }
    }

    submitAlertGroup(e) {
        e.preventDefault();
        //var email_list_string = this.state.emailList.map(ed => {
        //    return ed.value;
        // }).join("|");
        // this.state.alert.email_list = email_list_string;

        // var phone_list_string = this.state.phoneList.map(ed => {
        // return ed.value;
        // }).join("|");
        //this.state.alert.phone_list = phone_list_string;

        var u_list_string = this.state.selectedUsers.map(ed => ed.value).join("|");

        this.setState({
            u_list : u_list_string
        });

        const uListPayload = {
            ...this.state.group,
            u_list : u_list_string
        }

        restAPI.post('/api/1/wells/alertsGroup', uListPayload).then(res => {
            restAPI.get('/api/1/wells/alertsGroup?userId=' + LoginStore.user.id).then(res => {
                this.setState({
                    groups: res,
                    modal2: false
                });
            }).catch(err => {
                console.log(err);
            })
        }).catch(err => {
            console.log(err);
        });
    }

    //#alert group code ends from here. 

    render() {
        if (this.state.formReady) {

            return (
                <>
                    <Row>
                        <Col md={12}>
                            <div className="card card-default">

                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: this.state.activeTab === '1' })}
                                            onClick={() => { this.toggler('1'); }}
                                        >
                                            Alerts
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: this.state.activeTab === '2' })}
                                            onClick={() => { this.toggler('2'); }}
                                        >
                                            Contact Groups
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: this.state.activeTab === '3' })}
                                            onClick={() => { this.toggler('3'); }}
                                        >
                                            Alert Templates
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: this.state.activeTab === '4' })}
                                            onClick={() => { this.toggler('4'); }}
                                        >
                                            Alert Triggers
                                        </NavLink>
                                    </NavItem>
                                </Nav>

                                <TabContent activeTab={this.state.activeTab} style={{ padding: 0, marginTop: 0 }}>
                                    <TabPane tabId="1">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th>Well</th>
                                                    <th>Alert Subject</th>
                                                    <th>Users</th>
                                                    <th>Phone Numbers</th>
                                                    <th>Email List</th>
                                                    <th>Criteria</th>
                                                    <th>
                                                        <button className="float-right btn btn-sm btn-success" onClick={this.newAlertModal}> Add New Alert </button>
                                                    </th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.alerts.map(function (alert) {
                                                    return (
                                                        <tr key={alert.id}>
                                                            <td>{alert.job.name}</td>
                                                            <td>{alert.subject}</td>
                                                            <td>
                                                                {alert.users.map(u => {
                                                                    return (
                                                                        <span key={u.id} style={{ marginRight: "5px" }}>{u.name}</span>
                                                                    )
                                                                })}
                                                            </td>
                                                            <td>{alert.phone_list}</td>
                                                            <td>{alert.email_list}</td>
                                                            <td>
                                                                {alert.field_name}
                                                                {alert.condition_display}
                                                                {alert.set_point_value}
                                                            </td>
                                                            <td style={{ textAlign: "center" }}>
                                                                <button onClick={() => this.duplicateRow(alert)} className="btn btn-sm btn-success" style={{ marginRight: 20 }}>Duplicate</button>
                                                                <button onClick={() => this.editRow(alert, false)} className="btn btn-sm btn-primary" style={{ marginRight: 20 }}>Edit</button>
                                                                <button onClick={() => this.editRow(alert, true)} className="btn btn-sm btn-danger">Delete</button>
                                                            </td>
                                                        </tr>
                                                    )
                                                }, this)}
                                            </tbody>
                                        </table>
                                    </TabPane>

                                    <TabPane tabId="2">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th>ID</th>
                                                    <th>Users</th>
                                                    <th>
                                                        <button className="float-right btn btn-sm btn-success" onClick={this.newGroupModal}> Add New Group </button>
                                                    </th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.groups.map(function (group) {
                                                    return (
                                                        <tr key={group.id}>
                                                            <td>{group.id}</td>
                                                            <td>
                                                                {group.users.map(u => {
                                                                    return (
                                                                        <span key={u.id} style={{ marginRight: "10px" }}>{u.name}</span>
                                                                    )
                                                                })}
                                                            </td>

                                                            <td style={{ textAlign: "center" }}>
                                                                <button onClick={() => this.editRow2(group, false)} className="btn btn-sm btn-primary" style={{ marginRight: 20 }}>Edit</button>
                                                                <button onClick={() => this.editRow2(group, true)} className="btn btn-sm btn-danger">Delete</button>
                                                            </td>
                                                        </tr>
                                                    )
                                                }, this)}
                                            </tbody>
                                        </table>
                                    </TabPane>

                                    <TabPane tabId="3">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th>Well</th>
                                                    <th>Alert Subject</th>
                                                    <th>Users</th>
                                                    <th>Phone Numbers</th>
                                                    <th>Email List</th>
                                                    <th>Criteria</th>
                                                    <th>
                                                        <button className="float-right btn btn-sm btn-success" onClick={this.newAlertTemplateModal}> Add New Alert Template </button>
                                                    </th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.alert_templates.map(function (alert) {
                                                    return (
                                                        <tr key={alert.id}>
                                                            <td>{alert.job.name}</td>
                                                            <td>{alert.subject}</td>
                                                            <td>
                                                                {alert.users.map(u => {
                                                                    return (
                                                                        <span key={u.id} style={{ marginRight: "5px" }}>{u.name}</span>
                                                                    )
                                                                })}
                                                            </td>
                                                            <td>{alert.phone_list}</td>
                                                            <td>{alert.email_list}</td>
                                                            <td>
                                                                {alert.field_name}
                                                                {alert.condition_display}
                                                                {alert.set_point_value}
                                                            </td>
                                                            <td style={{ textAlign: "center" }}>
                                                                <button onClick={() => this.duplicateTemplate(alert)} className="btn btn-sm btn-success" style={{ marginRight: 20 }}>Duplicate</button>
                                                                <button onClick={() => this.editTemplate(alert, false)} className="btn btn-sm btn-primary" style={{ marginRight: 20 }}>Edit</button>
                                                                <button onClick={() => this.editTemplate(alert, true)} className="btn btn-sm btn-danger">Delete</button>
                                                            </td>
                                                        </tr>
                                                    )
                                                }, this)}
                                            </tbody>
                                        </table>
                                    </TabPane>

                                    <TabPane tabId="4">
                                        <ReactTable
                                            data={this.state.triggers}
                                            className="-striped -highlight"
                                            defaultPageSize={10}
                                            filterable
                                            columns={[
                                                {
                                                    Header: "Trigger No",
                                                    accessor: "id",
                                                    width: 100
                                                },
                                                // {
                                                //     Header: "Subject",
                                                //     Cell: props => { return (<div>{props.original.alert.subject}</div>); },
                                                // }, 
                                                // {
                                                //     Header: "Field",
                                                //     Cell: props => { return (<div>
                                                //         {props.original.alert.field_name} 
                                                //     </div>); },
                                                // }, 
                                                // {
                                                //     Header: "Comparison",
                                                //     Cell: props => { return (<div>
                                                //         {conditionList[props.original.alert.condition]} 
                                                //     </div>); },
                                                // }, 
                                                // {
                                                //     Header: "Set Point",
                                                //     Cell: props => { return (<div>
                                                //         {props.original.alert.set_point_value} 
                                                //     </div>); },
                                                // }, 
                                                {
                                                    Header: "Email",
                                                    accessor: "email",
                                                },
                                                {
                                                    Header: "Phone",
                                                    accessor: "phone",
                                                },
                                                {
                                                    Header: "Triggered",
                                                    accessor: "trigger_ts",
                                                    width: 200,
                                                    className: "text-center",
                                                },
                                            ]}
                                        />
                                    </TabPane>

                                </TabContent>
                            </div>
                        </Col>
                    </Row>

                    <div>
                        <Modal isOpen={this.state.modal2} toggle={this.toggle2} className="modal-lg">
                            <ModalHeader toggle={this.toggle2}>Create Contact Group</ModalHeader>
                            <ModalBody>
                                <form className="form-horizontal" onSubmit={this.onSubmit}>

                                    <FormGroup row>
                                        <label className="col-xl-2 col-form-label">Users</label>
                                        <div className="col-xl-10">
                                            <Select
                                                isMulti
                                                className="form-control form-select-element"
                                                placeholder="Select users"
                                                value={this.state.selectedUsers}
                                                options={this.state.availableUsers.map(user => ({
                                                    key: user.id,
                                                    label: user.name,
                                                    value: user.id
                                                }))}
                                                onChange={this.handleGroupUsersChange} />
                                        </div>
                                    </FormGroup>
                                    <button className="btn btn-block btn-primary mt-3" onClick={this.submitAlertGroup}>Submit</button>
                                </form>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="secondary" onClick={this.toggle2}>Cancel</Button>
                            </ModalFooter>
                        </Modal>
                    </div>

                    <div>
                        <Modal isOpen={this.state.modal} toggle={this.toggle} className="modal-lg">
                            <ModalHeader toggle={this.toggle}>Create Alert</ModalHeader>
                            <ModalBody>
                                <form className="form-horizontal" onSubmit={this.onSubmit}>
                                    <WellMeasureSelector
                                        show_inactive={false}
                                        jobs={this.state.all_jobs}
                                        handleSelectionChange={this.handleWellMeasureSelectionChange}
                                    />

                                    <FormGroup row>
                                        <label className="col-xl-2 col-form-label">Comparison Type</label>
                                        <div className="col-xl-10">
                                            <Select
                                                className="form-control form-select-element"
                                                placeholder="Select field"
                                                value={this.state.selected_condition}
                                                options={conditionTypeList.map(element => ({
                                                    key: element.value,
                                                    label: element.label,
                                                    value: element.value
                                                }))}
                                                onChange={this.handleConditionChange} />
                                        </div>
                                    </FormGroup>

                                    <FormElement label="Set Point"
                                        placeholder="Set Point Value"
                                        value={this.state.alert.set_point_value}
                                        name="set_point_value"
                                        onChange={this.handleModalInputChange} />

                                    <hr />

                                    <FormElement label="Subject"
                                        placeholder="Alert Subject (included in alert messages)"
                                        value={this.state.alert.subject}
                                        name="subject"
                                        onChange={this.handleModalInputChange} />

                                    <FormElement label="Refractory Period (Mins)"
                                        placeholder="60"
                                        value={this.state.alert.refractory_period}
                                        name="refractory_period"
                                        onChange={this.handleModalInputChange} />

                                    <FormGroup row>
                                        <label className="col-xl-2 col-form-label">Users</label>
                                        <div className="col-xl-10">
                                            <Select
                                                isMulti
                                                className="form-control form-select-element"
                                                placeholder="Select users"
                                                value={this.state.selectedUsers}
                                                options={this.state.availableUsers.map(user => ({
                                                    key: user.id,
                                                    label: user.name,
                                                    value: user.id
                                                }))}
                                                onChange={this.handleAlertUsersChange} />
                                        </div>
                                    </FormGroup>

                                    <FormGroup row>
                                        <label className="col-xl-2 col-form-label">Emails</label>
                                        <div className="col-xl-10">

                                            <CreatableSelect
                                                components={components}
                                                inputValue={this.state.inputEmail}
                                                isClearable
                                                isMulti
                                                menuIsOpen={false}
                                                onChange={this.handleAlertEmailsChange}
                                                onInputChange={this.handleAlertEmailsInputChange}
                                                onKeyDown={this.handleAlertEmailsKeyDown}
                                                placeholder="Type an email and press enter"
                                                value={this.state.emailList}
                                            />
                                        </div>
                                    </FormGroup>

                                    <FormGroup row>
                                        <label className="col-xl-2 col-form-label">Phone Numbers</label>
                                        <div className="col-xl-10">
                                            <CreatableSelect
                                                components={components}
                                                inputValue={this.state.inputPhone}
                                                isClearable
                                                isMulti
                                                menuIsOpen={false}
                                                onChange={this.handleAlertPhonesChange}
                                                onInputChange={this.handleAlertPhonesInputChange}
                                                onKeyDown={this.handleAlertPhonesKeyDown}
                                                placeholder="Type a phone number and press enter..."
                                                value={this.state.phoneList}
                                            />
                                        </div>
                                    </FormGroup>


                                    <button className="btn btn-block btn-primary mt-3" onClick={this.submitAlert}>Submit</button>
                                </form>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="secondary" onClick={this.toggle}>Cancel</Button>
                            </ModalFooter>
                        </Modal>
                    </div>

                    <div>
                        <Modal isOpen={this.state.showNewAlertModal} toggle={this.toggleTemplateModal} className="modal-lg">
                            <ModalHeader toggle={this.toggleTemplateModal}>Create Alert Template</ModalHeader>
                            <ModalBody>
                                <form className="form-horizontal" >
                                    <WellMeasureSelector
                                        show_inactive={false}
                                        jobs={this.state.all_jobs}
                                    />

                                    <FormElement label="Subject"
                                        placeholder="Alert Subject (included in alert messages)"
                                        value={this.state.alert_template.subject}
                                        name="subject"
                                        onChange={this.handleModalInputChange} />

                                    <FormElement label="Refractory Period (Mins)"
                                        placeholder="60"
                                        value={this.state.alert_template.refractory_period}
                                        name="refractory_period"
                                        onChange={this.handleModalInputChange} />

                                    <FormGroup row>
                                        <label className="col-xl-2 col-form-label">Users</label>
                                        <div className="col-xl-10">
                                            <Select
                                                isMulti
                                                className="form-control form-select-element"
                                                placeholder="Select users"
                                                value={this.state.selectedUsers}
                                                options={this.state.availableUsers.map(user => ({
                                                    key: user.id,
                                                    label: user.name,
                                                    value: user.id
                                                }))}
                                                onChange={this.handleAlertUsersChange} />
                                        </div>
                                    </FormGroup>

                                    <FormGroup row>
                                        <label className="col-xl-2 col-form-label">Emails</label>
                                        <div className="col-xl-10">

                                            <CreatableSelect
                                                components={components}
                                                inputValue={this.state.inputEmail}
                                                isClearable
                                                isMulti
                                                menuIsOpen={false}
                                                onChange={this.handleAlertEmailsChange}
                                                onInputChange={this.handleAlertEmailsInputChange}
                                                onKeyDown={this.handleAlertEmailsKeyDown}
                                                placeholder="Type an email and press enter"
                                                value={this.state.emailList}
                                            />
                                        </div>
                                    </FormGroup>

                                    <FormGroup row>
                                        <label className="col-xl-2 col-form-label">Phone Numbers</label>
                                        <div className="col-xl-10">
                                            <CreatableSelect
                                                components={components}
                                                inputValue={this.state.inputPhone}
                                                isClearable
                                                isMulti
                                                menuIsOpen={false}
                                                onChange={this.handleAlertPhonesChange}
                                                onInputChange={this.handleAlertPhonesInputChange}
                                                onKeyDown={this.handleAlertPhonesKeyDown}
                                                placeholder="Type a phone number and press enter..."
                                                value={this.state.phoneList}
                                            />
                                        </div>
                                    </FormGroup>
                                    <hr />
                                    <FormGroup row>
                                        <label className="col-xl-2 col-form-label">Field</label>
                                        <div className="col-xl-10">
                                            <Select
                                                key={this.state.state_count}
                                                className="form-control form-select-element"
                                                placeholder="Select field"
                                                value={this.state.selected_field_name}
                                                options={fieldList.map(element => ({
                                                    key: element.value,
                                                    label: element.label,
                                                    value: element.value
                                                }))}
                                                onChange={this.handleFieldChange} />
                                        </div>
                                    </FormGroup>

                                    <FormGroup row>
                                        <label className="col-xl-2 col-form-label">Comparison Type</label>
                                        <div className="col-xl-10">
                                            <Select
                                                className="form-control form-select-element"
                                                placeholder="Select field"
                                                value={this.state.selected_condition}
                                                options={conditionTypeList.map(element => ({
                                                    key: element.value,
                                                    label: element.label,
                                                    value: element.value
                                                }))}
                                                onChange={this.handleConditionChange} />
                                        </div>
                                    </FormGroup>

                                    <FormElement label="Set Point"
                                        placeholder="Set Point Value"
                                        value={this.state.alert_template.set_point_value}
                                        name="set_point_value"
                                        onChange={this.handleModalInputChange} />

                                    <button className="btn btn-block btn-primary mt-3" onClick={this.submitTemplate}>Submit</button>
                                </form>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="secondary" onClick={this.toggleTemplate}>Cancel</Button>
                            </ModalFooter>
                        </Modal>
                    </div>
                </>
            )
        } else {
            return (
                <div>Loading...</div>
            )
        }
    }
}