import React, { Component } from "react";
import ReactTable from "react-table";

export default class AlertTriggerLogTab extends Component {

    constructor(props) {
        super(props);

        this.state = {
            triggers: []
        };
    }

    async componentDidMount() {

    }

    render() {
        return (
            <>
                <ReactTable
                    data={this.props.triggers}
                    className="-striped -highlight"
                    defaultPageSize={10}
                    filterable
                    columns={[
                        {
                            Header: "Trigger No",
                            accessor: "id",
                            width: 100
                        },
                        {
                            Header: "Email",
                            accessor: "email",
                        },
                        {
                            Header: "Phone",
                            accessor: "phone",
                        },
                        {
                            Header: "Triggered",
                            accessor: "trigger_ts",
                            width: 200,
                            className: "text-center",
                        }
                    ]}
                />
            </>
        )
    }
}

