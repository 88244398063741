import React, { useCallback, useEffect, useState } from 'react'
import {
    Row, Col, Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap'
import { SuccessButton, DangerButton, Button } from '../Core/Shared'
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import 'react-toastify/dist/ReactToastify.css'
import 'react-contexify/dist/ReactContexify.css'
import { toast } from 'react-toastify'
import * as restAPI from '../../../../common/utilities/ApiService'
import { createdBy, noCreatedBy } from './HomeColumnHelper'
import LoginStore from '../../../../common/modules/Auth/LoginStore'
import { FormCheckboxInput } from '../Core/FormInputs'
import Select from 'react-select'
import LoadingSpinner from '../Elements/LoadingSpinner'


const DEFAULT_MODAL_STATE = { isOpen: false, data: null }
const CAN_EDIT = (created_by_id) => { return created_by_id === LoginStore.user.id }

const DeleteConfirmationModal = ({ modalState, setModalState, onConfirm }) => {
    const dashboardId = modalState?.data?.dashboardId
    const dashboardTitle = modalState?.data?.title || '(No Dashboard Name)'

    return <Modal
        centered
        size='lg'
        fullscreen='md'
        isOpen={modalState.isOpen}
        toggle={() => setModalState(DEFAULT_MODAL_STATE)}
    >
        <ModalHeader
            toggle={() => setModalState(!modalState.isOpen)}
            close={<Button onClick={() => setModalState(DEFAULT_MODAL_STATE)} text='X' />}
        >
            <h1>Deletion Confirmation - Are you sure?</h1>
        </ModalHeader>
        <ModalBody>
            <p>Deleting a dashboard will
                <span style={{ fontStyle: 'italic' }}> permanently </span>
                erase all of the dashboard data
                <span style={{ fontWeight: 'bold' }}> AND </span>
                all encompassed graph and widget tiles from AVID.
            </p>
            <p style={{ fontWeight: 'bold' }}>Once confirmed, this CANNOT be undone. </p>
            <p>
                Are you sure you would like to proceed with deleting the dashboard:
                <span style={{ fontStyle: 'italic', fontWeight: 'bold' }}> {dashboardTitle} </span>
                ?
            </p>
        </ModalBody>
        <ModalFooter>
            <DangerButton
                text='Cancel'
                onClick={() => setModalState(DEFAULT_MODAL_STATE)}
            />
            <SuccessButton
                text='Permanently Delete'
                onClick={() => { if (dashboardId) onConfirm(dashboardId) }}
            />
        </ModalFooter>
    </Modal>
}

export default ({ history }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [myDashboards, setMyDashboards] = useState([]);
    const [sharedDashboards, setSharedDashboards] = useState([]);
    const [myJobOptions, setMyJobOptions] = useState([]);
    const [sharedJobOptions, setSharedJobsOptions] = useState([]);
    const [selectedOrg, setSelectedOrg] = useState({});
    const [filterOptions, setFilterOptions] = useState([])
    const [showSharedDashboards, setShowSharedDashboards] = useState(false)
    const [showInactiveDashboards, setShowInactiveDashboards] = useState(false)
    const [deletionModalState, setDeletionModalState] = useState(DEFAULT_MODAL_STATE)
    const [orgs, setOrgs] = useState([])
    const notifySuccess = () => toast('Dashboard Successfully Deleted!', { type: 'success' })
    const notifyError = (msg = 'Unexpected Error. Please Try Again.') => toast(msg, { type: 'error' })

    const handleLoginStoreChange = () => {
        setSelectedOrg(orgs.find(o => o.id === LoginStore.user.current_org_id))
    }

    useEffect(() => {
        LoginStore.addChangeListener(handleLoginStoreChange)
        return () => LoginStore.removeChangeListener(handleLoginStoreChange)
    }, [orgs])

    useEffect(() => {
        updateMyJobOptions()
    }, [myDashboards])

    useEffect(() => {
        updateSharedJobOptions()
    }, [sharedDashboards])

    useEffect(() => {
        selectedOrg?.id && fetchSharedDashboards(selectedOrg.id)
    }, [selectedOrg])

    useEffect(() => {
        (async () => {
            await fetchUsersOwnDashboards()

            const { orgs } = await restAPI.get('/api/1/users/me/orgs')
            setOrgs(orgs)
            setSelectedOrg(orgs.find(o => o.id === LoginStore.user.current_org_id) ?? {});

            //await fetchSharedDashboards(LoginStore.user.current_org_id)

            setIsLoading(false)
        })()
    }, [])

    const fetchUsersOwnDashboards = async () => {
        const myDashboards = await restAPI.get('/api/1/dashboarding')
        setMyDashboards(myDashboards)
    }
    const fetchSharedDashboards = useCallback(async (currentOrgId) => {
        const sharedDashboards = await restAPI.get(`/api/1/dashboarding/shared/${currentOrgId}`)
        setSharedDashboards(sharedDashboards)
    })
    const updateMyJobOptions = () => {
        const myJobNames = [...new Set(myDashboards.filter(d => d.job_options).flatMap(d => d.job_options.split('---')))].map(j => JSON.parse(j))
        setMyJobOptions(myJobNames)
    }
    const updateSharedJobOptions = () => {
        const sharedJobNames = [...new Set(sharedDashboards.filter(d => d.job_options).flatMap(d => d.job_options.split('---')))].map(j => JSON.parse(j));
        setSharedJobsOptions(sharedJobNames)
    }

    const tableData = showSharedDashboards && showInactiveDashboards
        ? sharedDashboards.concat(myDashboards) // shared inactive
        : showSharedDashboards && !showInactiveDashboards
            ? sharedDashboards.filter(sd => sd.is_active > 0).concat(myDashboards.filter(d => d.is_active > 0)) // shared active
            : !showSharedDashboards && !showInactiveDashboards
                ? myDashboards.filter(d => d.is_active > 0) // not shared active
                : myDashboards // not shared inactive

    const filterData = () => {
        let newData;
        let filterData = [...tableData]
        const filterValues = filterOptions.map(opt => opt.value)
        const filterContainsNumber = filterValues.map(e => typeof e === 'number').find(e => e)
        const filterContainsString = filterValues.map(e => typeof e === 'string').find(e => e)

        if (filterContainsNumber && !filterContainsString) {
            newData = filterData.filter(row => {
                let jobIds = [...new Set(row.graph_jobs?.split(',').concat(row.widget_jobs?.split(',')))].filter(e => e).map(e => Number(e))
                return jobIds.map(id => filterValues.includes(id)).find(e => e)
            })
        } else if (!filterContainsNumber && filterContainsString) {
            newData = filterData.filter(row => filterValues.includes(row.name))
        } else if (filterContainsNumber && filterContainsString) {
            newData = filterData.filter(row => {
                let jobIds = [...new Set(row.graph_jobs?.split(',').concat(row.widget_jobs?.split(',')))].filter(e => e).map(e => Number(e))
                return jobIds.map(id => filterValues.includes(id)).find(e => e)
            }).filter(row => filterValues.includes(row.name))
        }

        return newData;
    }

    const createdByOptions = [...new Set(tableData.map(td => td.name))].map(e => { return { value: e, label: e } })

    const uniqueSharedJobOptions = sharedJobOptions.concat(myJobOptions).filter((obj, idx, self) =>
        idx === self.findIndex((job) => (
            job.value === obj.value && job.label === obj.label
        ))
    )

    const jobOptions = showSharedDashboards ? uniqueSharedJobOptions : myJobOptions

    const deleteDashboard = async (id) => {
        await restAPI.post(`/api/1/dashboarding/${id}/${LoginStore.user.id}`, { 'operation': 'DELETE' })
            .then((res) => {
                const prunedDashboards = myDashboards.filter(h => h.id !== id)
                setMyDashboards(prunedDashboards)
                setDeletionModalState(DEFAULT_MODAL_STATE)
                notifySuccess()
            })
            .catch((err) => {
                console.log(err)
                notifyError()
            })
    }

    return isLoading
        ? <LoadingSpinner />
        : <>
            <DeleteConfirmationModal
                modalState={deletionModalState} setModalState={setDeletionModalState} onConfirm={deleteDashboard}
            />
            <Row style={{ marginBottom: '2em' }}>
                <Col xs sm md lg xl={{ size: 6, offset: 3 }} style={{ textAlign: 'center' }}>
                    <h2 style={{ margin: 'auto' }}>
                        {selectedOrg.name} Dashboards
                    </h2>
                </Col>
                <Col xs sm md lg xl={3} style={{ textAlign: 'center' }}>
                    <Button leftIcon='fa-plus' text={`New ${selectedOrg.name} Dashboard`} onClick={() => history.push('/dashboarding/new')} />
                </Col>
            </Row>
            <Row>
                <Col xs sm md lg xl={{ size: 3, offset: 5 }} style={{ textAlign: 'start', display: 'flex', gap: '50px' }}>
                    <FormCheckboxInput
                        label='Show Dashboards Shared with Me'
                        value={showSharedDashboards}
                        onChange={() => setShowSharedDashboards(!showSharedDashboards)}
                    />
                    <FormCheckboxInput
                        label='Show Inactive Dashboards'
                        value={showInactiveDashboards}
                        onChange={() => setShowInactiveDashboards(!showInactiveDashboards)}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs sm md lg xl={{ size: 8, offset: 2 }}>
                    <div className='card card-default'>
                        <Select
                            isMulti
                            onChange={setFilterOptions}
                            defaultValue={filterOptions}
                            options={showSharedDashboards
                                ? [{ label: 'Created By', options: createdByOptions }, { label: 'Job Names', options: jobOptions }]
                                : [{ label: 'Job Names', options: jobOptions }]
                            }
                        />
                        <ReactTable
                            getTdProps={(state, rowInfo, col, instance) => {
                                return {
                                    style: { cursor: 'pointer' },
                                    onClick: () => {
                                        if (rowInfo && col.Header === undefined) {
                                            const { expanded } = state;
                                            const path = rowInfo.nestingPath[0];
                                            const diff = { [path]: expanded[path] ? false : true };
                                            instance.setState({
                                                expanded: {
                                                    ...expanded,
                                                    ...diff
                                                }
                                            });
                                        }
                                        else if (rowInfo && col.action === 'Edit') {
                                            history.push(`${rowInfo.original.id}/edit`)
                                        }
                                        else if (rowInfo && col.action === 'Delete') {
                                            CAN_EDIT(rowInfo.original.created_by_id) &&
                                                setDeletionModalState({ isOpen: true, data: { title: rowInfo.original.title, dashboardId: rowInfo.original.id } })
                                        }
                                    },
                                }
                            }}
                            SubComponent={(row) => {
                                return (
                                    <div style={{ padding: "20px" }}>
                                        <ul>
                                            <li><b>Wells on Dashboard: </b>{row.original.uniqueWells || 'No Wells On Dashboard'}</li>
                                            <li><b>Number of Graphs: </b>{row.original.graph_count}</li>
                                            <li><b>Number of Widgets: </b>{row.original.widget_count}</li>
                                        </ul>
                                    </div>
                                );
                            }}
                            filterable
                            data={filterOptions.length > 0 ? filterData() : tableData}
                            defaultPageSize={25}
                            className='-striped -highlight'
                            columns={showSharedDashboards ? createdBy : noCreatedBy}
                        />
                    </div>
                </Col>
            </Row>
        </>
}